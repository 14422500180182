import React from "react";
import { Col, Row } from "react-bootstrap";
import { Assets } from "../../constant/images";

const Search = () => {
  return (
    <Row>
      <Col md={12}>
        <div className="search-bg d-flex justify-content-center align-items-center">
          <div>
            <img src={Assets.SearchImg} />
            <div className="search-text">There are no search results yet.</div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Search;
