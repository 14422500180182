import { apiSlice } from "../../api/ApiSlice"

export const CategoryApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getallcategory:builder.query({
            query: () => ({
                url: `/category/all?per_page=20&page=1&order_by=created_at&order=-1&status=true&paginate=false`,
                providesTags: ['Category'],
            }),
        }),
    })
})

export const {
    useGetallcategoryQuery
} = CategoryApiSlice