import { React, useState } from "react";
import { Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { PasswordEye } from "../../constant/svg";
import "react-phone-number-input/style.css";
import { useForm } from "react-hook-form";
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";
import {
  SPECIAL_CHARACTER_ARRAY,
  SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
  VALIDATIONS,
  VALIDATIONS_TEXT,
  EXCLUDE_NUMBER_INPUT,
  EXCLUDE_ALPHABETS_SPECIAL_CHAR,
  SPECIAL_CHARACTER_NUMBER_ARRAY,
} from "../../constant/app-constants";
const ContactUs = () => {
  const [value, setValue] = useState();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {};
  return (
    <div className="contactus-page">
      <div className="query">Let us know about your query..</div>
      <Row>
        <Col md={6}>
          <div className="signup-form">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={12}>
                  <FloatingLabel controlId="floatingInput" label="Name">
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      onKeyDown={(e) =>
                        EXCLUDE_NUMBER_INPUT.includes(e.key) &&
                        e.preventDefault()
                      }
                      maxLength={VALIDATIONS.NAME}
                      {...register("name", {
                        maxLength: {
                          value: VALIDATIONS.NAME,
                          message: VALIDATIONS_TEXT.NAME_MAX,
                        },
                        pattern: {
                          value: /^[a-zA-Z\s]*$/,
                          message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS,
                        },
                        required: {
                          value: true,
                          message: VALIDATIONS_TEXT.NAME_REQUIRED,
                        },
                      })}
                    />
                    {errors.name && (
                      <small className="text-red">{errors.name.message}</small>
                    )}
                  </FloatingLabel>
                </Col>
                <Col md={12}>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Email Address"
                  >
                    <Form.Control
                      type="email"
                      placeholder="Email Address"
                      maxLength={VALIDATIONS.EMAIL}
                      {...register("email", {
                        maxLength: {
                          value: VALIDATIONS.EMAIL,
                          message: VALIDATIONS_TEXT.EMAIL_MAX,
                        },
                        required: {
                          value: true,
                          message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                        },
                        pattern: {
                          value:
                            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                          message: VALIDATIONS_TEXT.EMAIL_FORMAT,
                        },
                      })}
                    />
                    {errors.email && (
                      <small className="text-red error_from">
                        {errors.email.message}
                      </small>
                    )}
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="phone-input position-realtive">
                    <PhoneInput
                      international
                      placeholder="Enter phone numberr"
                      value={value}
                      onChange={setValue}
                      // rules={{ required: true }} 
                      // error={value ? (isValidPhoneNumber(value) ? undefined : 'Invalid phone number') : 'Phone number required'}
                      maxLength={VALIDATIONS.PHONE_MAX}
                      {...register("phone", {
                        maxLength: {
                          value: VALIDATIONS.PHONE_MAX,
                          message: VALIDATIONS_TEXT.PHONE_MAX,
                        },
                        required: {
                          value: true,
                          message: VALIDATIONS_TEXT.PHONE_REQUIRED,
                        },
                      })}
                    />
                    {errors.phone && (
                      <small className="text-red error_from">
                        {errors.phone.message}
                      </small>
                    )}
                  </div>
                </Col>
                <Col md={12}>
                  <FloatingLabel controlId="floatingInput" label="Subject">
                    <Form.Control
                      type="text"
                      placeholder="John Doe"
                      maxLength={VALIDATIONS.SUBJECT_LENGTH_MAX}
                      {...register("subject", {
                        maxLength: {
                          value: VALIDATIONS.SUBJECT_LENGTH_MAX,
                          message: VALIDATIONS_TEXT.SUBJECT_MAX,
                        },
                        required: {
                          value: true,
                          message: VALIDATIONS_TEXT.SUBJECT_REQUIRED,
                        },
                      })}
                    />
                    {errors.subject && (
                      <small className="text-red error_from">
                        {errors.subject.message}
                      </small>
                    )}
                  </FloatingLabel>
                </Col>
                <Col md={12}>
                  <div class="form-floating">
                    <textarea
                      class="form-control mt-1"
                      placeholder="Leave a comment here"
                      id="floatingTextarea"
                    ></textarea>
                    <label for="floatingTextarea">Message</label>
                  </div>
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                <Button className="contact-update-btn" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ContactUs;
