import { React, useState,useEffect } from "react";
import { Row, Col, Container, Button, Nav, Modal } from "react-bootstrap";
import DonutChart from "../../components/DonutChart/DonutChart";
import LineGraph from "../../components/LineGraph/LineGraph";
import DashboardSidebar from "../../components/Sidebar/DashboardSidebar";
import TopBar from "../../components/TopBar/TopBar";
import { Dropdown } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import { Form } from "react-bootstrap";
import { Assets } from "../../constant/images";
import Dispatched from "../../components/ProjectStatus/Dispatched";
import InProcess from "../../components/ProjectStatus/InProcess";
import Completed from "../../components/ProjectStatus/Completed";
import {
  ArrowRight,
  DeleteIcon,
  InStockIcon,
  NegativeIcon,
  PlusIcon,
  PositiveIcon,
  ProductRatingStar,
  SeemoreArrow,
  StarReviewIcon,
  WishlistIcon,
} from "../../constant/svg";
import ProductSlider from "../../components/ProductSlider/ProductSlider";
import InStock from "../../components/ProjectStatus/InStock";
import RatingReview from "../../components/RatingReview/RatingReview";
import DeleteModal from "../../components/Modals/DeleteModal";
import EditProductModal from "../../components/Modals/EditProductModal";
import Accordion from "react-bootstrap/Accordion";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ProductCard from "../../components/ProductCard/ProductCard";
import ReviewCard from "../../components/ReviewCard/ReviewCard";
import { NavLink,useLocation } from "react-router-dom";
import ThumbSlider from "../../components/ThumbSlider/ThumbSlider";
import BuyerProductSlider from "../../components/ProductSlider/BuyerProductSlider";
import AddtoCartModal from "../../components/Modals/AddtoCartModal";
import { useGetproductdetailsQuery } from "../../redux/reducers/ProductSlice/ProductApiSlice";

const BuyerProductDetails = (props) => {
  const location = useLocation();
  const [deletemodalShow, setDeleteModalShow] = useState(false);
  const [editproductmodalshow, setEditProductModalShow] = useState(false);
  const [size, setSize] = useState("S");
  const [color, setColor] = useState("");
  const [wishlist, setWishlist] = useState(false);
  const [count, setCount] = useState(1);
  const [addcart, setAddcart] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const[productDetail,setProductDetail] = useState({});
  const pid = location.state?.id;
  const { data: product, isLoading: productLoading } = useGetproductdetailsQuery(pid);

  const getProductDetails= async()=>{
    try{
        if (!productLoading) {
            setProductDetail(product?.data || {});
          }

    }catch(e){
        console.log(e);
    }
}

useEffect(()=>{
  getProductDetails();

},[productLoading])


  const incrementCount = () => {
    setCount(count + 1);
  };
  const decrementCount = () => {
    if (count > 0) setCount(count - 1);
  };

  const LabelComponent = (props) => {
    return (
      <div className="color-btn" style={{ backgroundColor: props.color }}></div>
    );
  };

  // console.log(productDetail);

  return (
    <>
      <div className="buyer-product-details">
        <Container>
          <div className="product-bg">
            <Row>
              <Col xl={5} lg={6} md={6} sm={12}>
                <div className="d-none d-lg-block">
                  <BuyerProductSlider
                    mainimg1={Assets.wallclock}
                    mainimg2={Assets.wallclock}
                    mainimg3={Assets.wallclock}
                    mainimg4={Assets.wallclock}
                    mainimg5={Assets.wallclock}
                    mainimg6={Assets.wallclock}
                    mainimg7={Assets.wallclock}
                    mainimg8={Assets.wallclock}
                    mainimg9={Assets.wallclock}
                    mainimg10={Assets.wallclock}
                    subimg1={Assets.clockone}
                    subimg2={Assets.clocktwo}
                    subimg3={Assets.clockthree}
                    subimg4={Assets.clockfour}
                    subimg5={Assets.clockfive}
                    subimg6={Assets.clocktwo}
                    subimg7={Assets.clockthree}
                    subimg8={Assets.clockfour}
                    subimg9={Assets.clocktwo}
                    subimg10={Assets.clockthree}
                  />
                </div>
                <div className="d-lg-none">
                  <ProductSlider
                    mainimg1={Assets.wallclock}
                    mainimg2={Assets.wallclock}
                    mainimg3={Assets.wallclock}
                    mainimg4={Assets.wallclock}
                    mainimg5={Assets.wallclock}
                    mainimg6={Assets.wallclock}
                    mainimg7={Assets.wallclock}
                    mainimg8={Assets.wallclock}
                    mainimg9={Assets.wallclock}
                    mainimg10={Assets.wallclock}
                    subimg1={Assets.clockone}
                    subimg2={Assets.clocktwo}
                    subimg3={Assets.clockthree}
                    subimg4={Assets.clockfour}
                    subimg5={Assets.clockfive}
                    subimg6={Assets.clocktwo}
                    subimg7={Assets.clockthree}
                    subimg8={Assets.clockfour}
                    subimg9={Assets.clocktwo}
                    subimg10={Assets.clockthree}
                  />
                </div>
              </Col>
              <Col xl={4} lg={6} md={6} sm={12}>
                <div className="product-data side-border">
                  <div className="buyer-product-title">
                    {productDetail?.title }
                  </div>
                  <div className="size">
                    Size: <b style={{ color: "black" }}>{size}</b>
                  </div>
                  <div className="sizes-btns">
                    <Form>
                      {productDetail?.product_variations_data?.map((item)=>{
                        
                        if(item.attribute.name === 'Size'){
                          return item.attribute.variations.map((e,index)=>(
                            
                              <Form.Check
                          onClick={() => {
                            setSize(e.name);
                          }}
                          type="radio"
                          id={e._id}
                          label={e.name.charAt(0)}
                          name="size"
                        />
                            
                          ))
                        } 
                      })}
                    </Form>
                  </div>
                  

                  <div className="size">
                    Color: <b style={{ color: "black" }}>{color}</b>
                  </div>
                  <div className="color-btns">
                    <Form>
                    {productDetail?.product_variations_data?.map((item)=>{
                        
                        if(item.attribute.name === 'Color'){
                          return item.attribute.variations.map((e,index)=>(
                            <Form.Check
                            onClick={() => {
                              setColor(e.name);
                            }}
                            type="radio"
                            id={e._id}
                            label={<LabelComponent color={e.name} />}
                            name="color"
                          />
                            
                          ))
                        } 
                      })}
                      
                    </Form>
                  </div>
                  

                  {/* product price section starts here */}
                  <div className="d-flex mt_20">
                    <div className="product-discount-price">${productDetail?.discounted_price}</div>
                    <div className="product-original-price">
                      <strike>${productDetail?.price}</strike>
                    </div>
                  </div>
                  {/* product price section ends here */}

                  {/* In stock section starts here */}
                  <div className="d-flex mt_20 align-items-center">
                    <div>
                      <Button className="stock-btn">
                      {productDetail?.quantity > 0 ?
                        <>
                        <InStockIcon />
                        In Stock </> :
                        'Out Stock'
                        }
                      </Button>
                    </div>

                    <div>
                      <Button
                        onClick={() => {
                          setWishlist(!wishlist);
                        }}
                        className={`wishlist-btn ps_20 ${
                          wishlist ? "added" : ""
                        }`}
                      >
                        <div className="wishlist-icon-container">
                          <WishlistIcon />
                        </div>
                        {!wishlist ? <span>Wishlist</span> : <span>Added</span>}
                      </Button>
                    </div>
                  </div>
                  {/* In stock section ends here */}

                  {/* made in section starts here */}
                  <div className="made-in-section d-flex align-items-center justify-content-between">
                    <div>
                      <div className="heading">Origin</div>
                      <div>{productDetail?.product_orogin?.name}</div>
                    </div>
                    <div className="seperator-line"></div>
                    <div>
                      <div className="heading">Ratings</div>
                      <div className="d-flex align-items-center">
                        <ProductRatingStar />
                        <div className="buyer-product-rating">
                          4.0<span className="reviews-num">(225)</span>
                        </div>
                      </div>
                    </div>
                    <div className="seperator-line"></div>
                    <div>
                      <div className="heading">No. of Orders</div>
                      <div>360</div>
                    </div>
                  </div>
                  {/* made in section ends here */}

                  {/* Size chart start */}
                  {/* <div className="main-size-chart">
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                          >
                            Size Chart
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <table class="table border">
                              <thead>
                                <tr>
                                  <th class="border-bottom"></th>
                                  <th class="border-bottom border">Chest</th>
                                  <th class="border-bottom border">Waist</th>
                                  <th class="border-bottom border">Hip</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td class="border-end fw-bold">XS</td>
                                  <td class="border-end border">86 cm</td>
                                  <td class="border-end border">67 cm</td>
                                  <td class="border-end border">91 cm</td>
                                </tr>
                                <tr>
                                  <td class="border-end fw-bold">S</td>
                                  <td class="border-end border">92 cm</td>
                                  <td class="border-end border">71 cm</td>
                                  <td class="border-end border">95 cm</td>
                                </tr>
                                <tr>
                                  <td class="border-end fw-bold">M</td>
                                  <td class="border-end border">98 cm</td>
                                  <td class="border-end border">75 cm</td>
                                  <td class="border-end border">99 cm</td>
                                </tr>
                                <tr>
                                  <td class="border-end fw-bold">L</td>
                                  <td class="border-end border">104 cm</td>
                                  <td class="border-end border">79 cm</td>
                                  <td class="border-end border">103 cm</td>
                                </tr>
                                <tr>
                                  <td class="border-end fw-bold">XL</td>
                                  <td class="border-end border">110 cm</td>
                                  <td class="border-end border">83 cm</td>
                                  <td class="border-end border">107 cm</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="main-size-chart">
                    <h5>Size Chart</h5>
                    <div className="scale-img-div">
                      <img
                        src={Assets.SizeSCale}
                        className="img-fluid cursor-pointer"
                        onClick={() => setShowModal(true)}
                      />
                    </div>
                    <Modal
                      show={showModal}
                      onHide={() => setShowModal(false)}
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                          Size Chart
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="text-center">
                        <img
                          src={productDetail?.size_chart}
                          className="img-fluid"
                        />
                      </Modal.Body>
                    </Modal>
                  </div>

                  {/* Size chart end */}

                  {/* Quantity Section start here */}
                  <div className="quantity-section">
                    <div className="quantity-heading">Quantity:</div>
                    <div>
                      <Row>
                        <Col md={4} lg={4}>
                          <div className="quantity-btn mt-0">
                            <div
                              className="cursor-link"
                              onClick={decrementCount}
                            >
                              <NegativeIcon />
                            </div>
                            <div className="count-text">{count}</div>
                            <div
                              className="cursor-link"
                              onClick={incrementCount}
                            >
                              <PositiveIcon />
                            </div>
                          </div>
                        </Col>
                        <Col md={6} lg={6}>
                          <div>
                            <Button
                              onClick={() => setAddcart(true)}
                              className="add-to-cart-btn"
                            >
                              Add to Cart
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  {/* QUantity Section End here */}
                </div>
              </Col>
              <Col xl={3} lg={12} sm={12}>
                <div className="product-details-accordion">
                  <Accordion defaultActiveKey={["0"]} alwaysOpen>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="acc-heading">Shipping</div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <span className="conditions">Shipping Charges:</span>
                          <span
                            className="conditions"
                            style={{ color: "black" }}
                          >
                            $10 worldwide
                          </span>
                        </div>
                        <div className="mt_10">
                          <span className="conditions">Shipping From:</span>
                          <span
                            className="conditions"
                            style={{ color: "black" }}
                          >
                            {productDetail?.product_shipping_from?.name}
                          </span>
                        </div>
                        <div className="mt_10">
                          <span className="conditions">Delivery:</span>
                          <span
                            className="conditions"
                            style={{ color: "black" }}
                          >
                            4-5 Business days
                          </span>
                        </div>
                        <div>
                          <hr />
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <div className="acc-heading">Product Details</div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <span className="conditions">Material:</span>
                          <span
                            className="conditions"
                            style={{ color: "black" }}
                          >
                            {productDetail?.material}
                          </span>
                        </div>
                        <div>
                          <span className="conditions">SKU:</span>
                          <span
                            className="conditions"
                            style={{ color: "black" }}
                          >
                            {productDetail?.sku}
                          </span>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        <div className="acc-heading">Exchange Return</div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <span className="conditions">
                            {productDetail?.delivery_return_policy}
                          </span>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="message-seller">
                  <NavLink to="/buyer-product-listing" state={{seller_id:productDetail?.product_user?._id}} >
                    <div className="d-flex align-items-center">
                      <div className="img-box">
                        <img src={Assets.msgseller} className="img-fluid" />
                      </div>
                      <div className="ps-2">
                        <div className="halfmoon-title">{productDetail?.product_user?.name}</div>
                        <div className="halfmoon-title">
                          owner of <span>{productDetail?.product_user?.store_name}</span>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                  <div>
                    <Button className="seller-btn">Message Seller</Button>
                  </div>
                </div>
                <div className="report-item">
                  <NavLink to="/report-center">Report Item</NavLink>
                </div>
              </Col>
            </Row>

            <Row>
              <div className="mt_30">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Row>
                    <Col md={3}>
                      <div className="tab-btn-container">
                        <Nav variant="pills" className="flex-column">
                          <Nav.Item>
                            <Nav.Link eventKey="first">Description</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="second">Reviews (19)</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                    </Col>
                    <Col md={9}>
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <div>
                            <div className="tabs-heading">Description</div>
                            <div className="tabs-para">
                            {productDetail?.description}
                            </div>
                            <div className="tabs-heading mt_30">
                              Preparation and Usage
                            </div>
                            <div className="tabs-para">
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged. It was
                              popularised in the 1960s with the release of
                              Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software
                              like Aldus PageMaker including versions of Lorem
                              Ipsum.g software like Aldus PageMaker including
                              versions of Lorem Ipsum.
                            </div>
                            <div className="tabs-heading  mt_30">
                              Recycling Information
                            </div>
                            <div className="tabs-para">
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged. It was
                              popularised in the 1960s with the release of
                              Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software
                              like Aldus PageMaker including versions of Lorem
                              Ipsum.g software like Aldus PageMaker including
                              versions of Lorem Ipsum.
                            </div>
                            <div className="tabs-heading  mt_30">
                              Other Information
                            </div>
                            <div className="tabs-para">
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged. It was
                              popularised in the 1960s with the release of
                              Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software
                              like Aldus PageMaker including versions of Lorem
                              Ipsum.g software like Aldus PageMaker including
                              versions of Lorem Ipsum.{" "}
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          <Col md={12}>
                            {/* Rating And Reviews Section Starts Here */}
                            <div className="rating-and-review-section p-0 m-0">
                              <div className="tabs-heading ">
                                Rating & Reviews
                              </div>
                              <div className="d-flex align-items-center">
                                <div>
                                  <StarReviewIcon />
                                </div>
                                <div className="star-reviews">
                                  4.9 (2130 reviews)
                                </div>
                              </div>
                              {/* Card 1 Starts Here */}
                              <ReviewCard
                                image={Assets.Kate}
                                Author="Kate Carter"
                                date="Sep 20, 2022"
                                rating="4.0"
                                review="
        This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text."
                              />
                              {/* Card 1 Ends Here */}

                              {/* Card 2 Starts Here */}
                              <ReviewCard
                                image={Assets.Kelly}
                                Author="Kelly Jones"
                                date="Sep 20, 2022"
                                rating="4.0"
                                review="
              This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text."
                              />
                              {/* Card 2 Ends Here */}

                              {/* Card 3 Starts Here */}
                              <ReviewCard
                                image={Assets.Carly}
                                Author="Carly West"
                                date="Sep 20, 2022"
                                rating="4.0"
                                review="
              This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text."
                              />
                              {/* Card 3 Ends Here */}
                              {/* Card 3 Starts Here */}
                              <ReviewCard
                                image={Assets.Carly}
                                Author="Carly West"
                                date="Sep 20, 2022"
                                rating="4.0"
                                review="
              This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text."
                              />
                              {/* Card 3 Ends Here */}
                              {/* Card 3 Starts Here */}
                              <ReviewCard
                                image={Assets.Carly}
                                Author="Carly West"
                                date="Sep 20, 2022"
                                rating="4.0"
                                review="
              This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text."
                              />
                              {/* Card 3 Ends Here */}
                              {/* Card 3 Starts Here */}
                              <ReviewCard
                                image={Assets.Carly}
                                Author="Carly West"
                                date="Sep 20, 2022"
                                rating="4.0"
                                review="
              This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text."
                              />
                              {/* Card 3 Ends Here */}
                            </div>{" "}
                            {/* Rating And Reviews Section Ends Here */}
                          </Col>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </Row>

            <div className="more-product-section">
              <div className="d-block d-sm-flex align-items-center justify-content-between">
                <div className="more-heading">More from this shop</div>
                <NavLink to="/product-listing">
                  <div className="see-more">
                    See more
                    <span className="ps-2">
                      <SeemoreArrow />
                    </span>
                  </div>
                </NavLink>
              </div>
              <Row>
                <Col xs={12} md={6} sm={6} lg={3} xl={3}>
                  <ProductCard
                    productimg={Assets.Bag}
                    price="$122"
                    originalprice="$154"
                    desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
                  />
                </Col>
                <Col xs={12} md={6} sm={6} lg={3} xl={3}>
                  <ProductCard
                    productimg={Assets.Dog}
                    price="$122"
                    originalprice="$154"
                    desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
                  />{" "}
                </Col>
                <Col xs={12} md={6} sm={6} lg={3} xl={3}>
                  <ProductCard
                    productimg={Assets.Tree}
                    price="$122"
                    originalprice="$154"
                    desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
                  />{" "}
                </Col>
                <Col xs={12} md={6} sm={6} lg={3} xl={3}>
                  <ProductCard
                    productimg={Assets.Ball}
                    price="$122"
                    originalprice="$154"
                    desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
                  />{" "}
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <ProductCard
                    className="mt_30"
                    productimg={Assets.one}
                    price="$122"
                    originalprice="$154"
                    desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
                  />
                </Col>
                <Col md={6}>
                  <ProductCard
                    productimg={Assets.two}
                    price="$122"
                    originalprice="$154"
                    desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} sm={6} lg={3} xl={3}>
                  <ProductCard
                    productimg={Assets.Bag}
                    price="$122"
                    originalprice="$154"
                    desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
                  />
                </Col>
                <Col xs={12} md={6} sm={6} lg={3} xl={3}>
                  <ProductCard
                    productimg={Assets.Dog}
                    price="$122"
                    originalprice="$154"
                    desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
                  />{" "}
                </Col>
                <Col xs={12} md={6} sm={6} lg={3} xl={3}>
                  <ProductCard
                    productimg={Assets.Tree}
                    price="$122"
                    originalprice="$154"
                    desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
                  />{" "}
                </Col>
                <Col xs={12} md={6} sm={6} lg={3} xl={3}>
                  <ProductCard
                    productimg={Assets.Ball}
                    price="$122"
                    originalprice="$154"
                    desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
                  />{" "}
                </Col>
              </Row>
            </div>
          </div>
          <DeleteModal
            heading="Delete Product"
            type="delete this product"
            btntext="Delete"
            link="/products/product-details"
            display="onClick={()=>{props.onHide}}"
            show={deletemodalShow}
            onHide={() => setDeleteModalShow(false)}
          />
          <AddtoCartModal show={addcart} onHide={() => setAddcart(false)} />
        </Container>
      </div>
    </>
  );
};

export default BuyerProductDetails;
