import { apiSlice } from "../../api/ApiSlice"

export const CountryApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getcountry:builder.query({
            query: () => ({
                url: 'country/all?order_by=id&order=-1&status=true',
                providesTags: ['Country'],
            }),
        }),
    })
})

export const {
    useGetcountryQuery
} = CountryApiSlice