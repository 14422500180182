import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import DonutChart from "../../components/DonutChart/DonutChart";
import LineGraph from "../../components/LineGraph/LineGraph";
import DashboardSidebar from "../../components/Sidebar/DashboardSidebar";
import TopBar from "../../components/TopBar/TopBar";
import { Dropdown } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import { Form } from "react-bootstrap";
import { Assets } from "../../constant/images";
import Dispatched from "../../components/ProjectStatus/Dispatched";
import InProcess from "../../components/ProjectStatus/InProcess";
import Completed from "../../components/ProjectStatus/Completed";
import { ArrowRight } from "../../constant/svg";
import OffcanvasHeader from "../../components/OffCanvas/Offcanvas";

const DashboardLayout = (props) => {
  return (
    <>
      <div className="dashboard-layout">
        <Container>
          <div className="dashboard-body ">
            <Row>
              <OffcanvasHeader/>
            </Row>
            <Row>
              <Col xl={2} lg={2} md={0}>
                <DashboardSidebar />
              </Col>
              <Col xl={10} lg={10} md={12}>
                <TopBar />
                <div className="scroll-div">
                {props.children}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default DashboardLayout;
