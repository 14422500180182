

const UserAction = {
    LogIn:(state,action)=>{
        const {data} = action.payload;
        state.User = data;
        state.Token = data.token;
    },
    LogOut:(state,action)=>{
        state.User = null;
        state.Token = null;
    },
    SignUp:(state,action)=>{
        state.User = action.payload;
        state.Token = null;
    },
    OtpVerify:(state,action)=>{
        state.User = null;
        state.Token = null;
    },
    ResendOtp:(state,action)=>{
        state.Token = null;
    },
    ForgotPassword:(state,action)=>{
        state.Token = null;
    },
    ResetPassword:(state,action)=>{
        state.Token = null;
    },
    UpdateProfile:(state,action)=>{
        const {data} = action.payload;
        state.User = data.data;
    },
    ChangePassword:(state,action)=>{
        state.User = null;
        state.Token = null;
    },

}

export default UserAction;