import React, { useEffect } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useNavigate,
    Routes,
    Redirect,
} from "react-router-dom";
import ForgotPassword from "../../Auth/ForgotPassword";
import AuthLayout from "../../Auth/AuthLayout";
import BackAuthLayout from "../../Auth/BackAuthLayout";
import GetStarted from "../../Auth/GetStarted";
import OtpVerification from "../../Auth/OtpVerification";
import Signin from "../../Auth/Signin";
import SignUp from "../../Auth/SignUp";
import Home from "../../pages/Home";
import DashboardLayout from "../../pages/DashboardLayout/DashboardLayout";
import Dashboard from "../../pages/Dashboard/Dashboard";
import ProductDetails from "../../pages/Products/ProductDetails";
import Order from "../../pages/Order/Order";
import Inventory from "../../pages/Inventory/Inventory";
import OrderDetails from "../../pages/Order/OrderDetails";
import ChatEmpty from "../../pages/Chat/ChatEmpty";
import Settings from "../../pages/Settings/Settings";
import PushNotification from "../Settings/PushNotification";
import ChangePassword from "../Settings/ChangePassword";
import TermsandCondition from "../Settings/TermsandCondition";
import PrivacyPolicy from "../Settings/PrivacyPolicy";
import MyProfile from "../../pages/MyProfile/MyProfile";
import ContactUs from "../Settings/ContactUs";
import DeleteAccount from "../Settings/DeleteAccount";
import Search from "../../pages/Search/Search";
import AddProduct from "../../pages/Products/AddProduct";
import ProductManagement from "../../pages/Products/ProductManagement";
import AddCategory from "../../pages/Category/AddCategory";
import BuyerPagesLayout from "../../pages/BuyerPagesLayout/BuyerPagesLayout";
import ProductListing from "../../pages/ProductListing/ProductListing";
import BuyerProductDetails from "../../pages/BuyerProductPages/BuyerProductDetail";
import BuyerProductListing from "../../pages/BuyerProductPages/BuyerProductListing";
import CartEmpty from "../../pages/Cart/CartEmpty";
import CartScreen from "../../pages/Cart/CartScreen";
import CheckoutScreenOne from "../../pages/Checkout/CheckoutScreenOne";
import CheckoutLayout from "../../pages/Checkout/CheckoutLayout";
import CheckoutScreenTwo from "../../pages/Checkout/CheckoutScreenTwo";
import OrderSummary from "../../pages/Checkout/OrderSummary";
import Wishlist from "../../pages/Wishlist/Wishlist";
import BuyerAccountDetails from "../../pages/BuyerProfile/BuyerAccountDetails";
import BuyerProfileSideMenu from "../BuyerProfile/BuyerProfileSideMenu";
import BuyerProfileLayout from "../../pages/BuyerProfile/BuyerProfileLayout";
import BuyerAddress from "../../pages/BuyerProfile/BuyerAddress";
import BuyerPrivacy from "../../pages/BuyerProfile/BuyerPrivacy";
import BuyerTerms from "../../pages/BuyerProfile/BuyerTerms";
import BuyerContactUs from "../../pages/BuyerProfile/BuyerContactUs";
import BuyerChangePassword from "../../pages/BuyerProfile/BuyerChangePassword";
import BuyerAccountDetailsEdit from "../../pages/BuyerProfile/BuyerEditPages/BuyerAccountDetailsEdit";
import BuyerAddressEdit from "../../pages/BuyerProfile/BuyerEditPages/BuyerAddressEdit";
import BuyerOrders from "../../pages/BuyerProfile/BuyerOrders";
import BuyerOrderDetail from "../../pages/BuyerProfile/BuyerOrderDetail";
import BuyerTransactionHistory from "../../pages/BuyerProfile/BuyerTransactionHistory";
import BuyerTransactionDetail from "../../pages/BuyerProfile/BuyerTransactionDetail";
import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import CategoryTable from "../../pages/Category/CategoryTable";
import ResetPassword from "../../Auth/ResetPassword";
import DeleteConfirmation from "../Settings/DeleteConfirmation";
import EditMyProfile from "../../pages/MyProfile/EditMyProfile";
import OtpVerificationSignup from "../../Auth/OtpVerificationSignup";
import ReturnOrder from "../../pages/ReturnOrder/ReturnOrder";
import ReturnOrderDEtail from "../../pages/ReturnOrder/ReturnOrderDEtail";
import ReturnOrderPayment from "../../pages/ReturnOrder/ReturnOrderPayment";
import ReportCenter from "../Report Center/ReportCenter";
import ReportCenterForm from "../Report Center/ReportCenterForm";
import RequireAuth from "../../Auth/RequireAuth";


const Navigation = () => {
    const location = useLocation();
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <Routes>
                <Route
                    path="/"
                    element={
                        <AuthLayout
                            heading="Sign In"
                            subheading="Welcome Yo, We fafaf to see you again"
                            description="Enter your email and password"
                            children={<Signin link="/dashboard" />}
                        />
                    }
                />
                <Route
                    path="/get-started"
                    element={<AuthLayout link="/signup" children={<GetStarted />} />}
                />
                <Route
                    path="/signup"
                    element={
                        <AuthLayout
                            heading="Sign Up as Seller"
                            subheading="Select Account Type"
                            description="Is your Afrotierre account Buyer or Seller?              "
                            children={<SignUp role="10" />}
                        />
                    }
                />
                <Route
                    path="/reset-password"
                    element={
                        <BackAuthLayout
                            link="/otpverify"
                            heading="Reset Password"
                            children={<ResetPassword />}
                        />
                    }
                />

                <Route
                    path="/forgot-password"
                    element={
                        <BackAuthLayout
                            link="/"
                            heading="Forgot Password"
                            subheading="Enter the email associated with your account and
                we'll send verification code to reset your password"
                            description=""
                            children={<ForgotPassword />}
                        />
                    }
                />
                <Route
                    path="/otpverify"
                    element={
                        <BackAuthLayout
                            isShow={true}
                            link="/forgot-password"
                            heading="OTP Verification"
                            subheading="Create your account, It takes less than a minute."
                            description="Enter your personal info"
                            children={<OtpVerification />}
                        />
                    }
                />
                <Route
                    path="/otpverify-account"
                    element={
                        <BackAuthLayout
                            link="/forgot-password"
                            heading="OTP Verification"
                            subheading="Create your account, It takes less than a minute."
                            description="Enter your personal info"
                            children={<OtpVerificationSignup />}
                        />
                    }
                />

                <Route
                    path="/get-started"
                    element={<AuthLayout link="/signup" children={<GetStarted />} />}
                />
                <Route
                    path="/signup"
                    element={
                        <AuthLayout
                            heading="Sign Up as Seller"
                            subheading="Select Account Type"
                            description="Is your Afrotierre account Buyer or Seller?              "
                            children={<SignUp role="10" />}
                        />
                    }
                />
                <Route
                    path="/reset-password"
                    element={
                        <BackAuthLayout
                            link="/otpverify"
                            heading="Reset Password"
                            children={<ResetPassword />}
                        />
                    }
                />

                <Route
                    path="/forgot-password"
                    element={
                        <BackAuthLayout
                            link="/"
                            heading="Forgot Password"
                            subheading="Enter the email associated with your account and
                we'll send verification code to reset your password"
                            description=""
                            children={<ForgotPassword />}
                        />
                    }
                />
                <Route
                    path="/otpverify"
                    element={
                        <BackAuthLayout
                            isShow={true}
                            link="/forgot-password"
                            heading="OTP Verification"
                            subheading="Create your account, It takes less than a minute."
                            description="Enter your personal info"
                            children={<OtpVerification />}
                        />
                    }
                />
                <Route
                    path="/otpverify-account"
                    element={
                        <BackAuthLayout
                            link="/forgot-password"
                            heading="OTP Verification"
                            subheading="Create your account, It takes less than a minute."
                            description="Enter your personal info"
                            children={<OtpVerificationSignup />}
                        />
                    }
                />
                <Route
                path="/guest-home"
                element={
                    <BuyerPagesLayout
                        children={<Home />}
                        isShow={true}
                        isDontShow={false}
                    />
                }
            />

        <Route element={<RequireAuth />} >
            

            {/*Dashboard */}
            <Route
                path="/dashboard"
                element={<DashboardLayout children={<Dashboard />} />}
            />
            <Route
                path="/products/list"
                element={<DashboardLayout children={<ProductManagement />} />}
            />
            <Route
                path="/orders"
                element={<DashboardLayout children={<Order />} />}
            />
            <Route
                path="/inventory"
                element={<DashboardLayout children={<Inventory />} />}
            />
            <Route
                path="/orders/order-detail"
                element={<DashboardLayout children={<OrderDetails />} />}
            />
            <Route
                path="/chat"
                element={<DashboardLayout children={<ChatEmpty />} />}
            />
            <Route
                path="/settings/notifications"
                element={
                    <DashboardLayout
                        children={
                            <Settings
                                heading="Push Notification"
                                children={<PushNotification />}
                            />
                        }
                    />
                }
            />
            <Route
                path="/settings/change-password"
                element={
                    <DashboardLayout
                        children={
                            <Settings
                                heading="Change Password"
                                children={<ChangePassword />}
                            />
                        }
                    />
                }
            />
            <Route
                path="/settings/terms-and-conditions"
                element={
                    <DashboardLayout
                        children={
                            <Settings
                                heading="Terms & Conditions"
                                children={<TermsandCondition />}
                            />
                        }
                    />
                }
            />
            <Route
                path="/settings/privacy-policy"
                element={
                    <DashboardLayout
                        children={
                            <Settings
                                heading="Privacy Policy"
                                children={<PrivacyPolicy />}
                            />
                        }
                    />
                }
            />
            <Route
                path="/settings/contact-us"
                element={
                    <DashboardLayout
                        children={
                            <Settings heading="Contact Us" children={<ContactUs />} />
                        }
                    />
                }
            />
            <Route
                path="/settings/delete-account"
                element={
                    <DashboardLayout
                        children={
                            <Settings
                                heading="Delete Account"
                                children={<DeleteAccount />}
                            />
                        }
                    />
                }
            />
            <Route
                path="/settings/delete-account/confirm-password"
                element={
                    <DashboardLayout
                        children={
                            <Settings
                                heading="Delete Account"
                                children={<DeleteConfirmation />}
                            />
                        }
                    />
                }
            />
            <Route
                path="/edit-my-profile"
                element={<DashboardLayout children={<MyProfile />} />}
            />
            <Route
                path="/my-profile"
                element={<DashboardLayout children={<EditMyProfile />} />}
            />
            <Route
                path="/search"
                element={<DashboardLayout children={<Search />} />}
            />
            <Route
                path="/products"
                element={<DashboardLayout children={<AddProduct />} />}
            />
            <Route
                path="/products/product-details"
                element={<DashboardLayout children={<ProductDetails />} />}
            />
            <Route
                path="/category"
                element={<DashboardLayout children={<AddCategory />} />}
            />
            {/* Buyer Page navigation Starts Here */}
            <Route
                path="/home"
                element={<BuyerPagesLayout children={<Home />} isDontShow={true} />}
            />
            <Route
                path="/product-listing"
                element={<BuyerPagesLayout children={<ProductListing />} />}
            />
            <Route
                path="/buyer-product-details"
                element={<BuyerPagesLayout children={<BuyerProductDetails />} />}
            />
            <Route
                path="/buyer-product-listing"
                element={<BuyerPagesLayout children={<BuyerProductListing />} />}
            />
            <Route
                path="/cart-empty"
                element={<BuyerPagesLayout children={<CartEmpty />} />}
            />
            <Route
                path="/cart-screen"
                element={<BuyerPagesLayout children={<CartScreen />} />}
            />
            <Route
                path="/checkout-screen1"
                element={
                    <BuyerPagesLayout
                        children={
                            <CheckoutScreenOne
                                linkk="/checkout-screen2"
                                btn1txt="Continue"
                                link2="/home"
                                btn2txt="Continue Shopping"
                                mainheading="Checkout"
                            // children={<CheckoutScreenOne />}
                            />
                        }
                    />
                }
            />
            <Route
                path="/checkout-screen2"
                element={
                    <BuyerPagesLayout
                        children={
                            <CheckoutScreenTwo
                                btn1txt="Confirm Purchase"
                                link2="/home"
                                btn2txt="Continue Shopping"
                                mainheading="Checkout"
                            />
                        }
                    />
                }
            />
            <Route
                path="/order-summary"
                element={
                    <BuyerPagesLayout
                        children={
                            <CheckoutLayout
                                linkk="/home"
                                btn1txt="Continue Shopping"
                                btn2txt="Cancel Order"
                                mainheading="Order Summary"
                                children={<OrderSummary />}
                            />
                        }
                    />
                }
            />
            <Route
                path="/wishlist"
                element={<BuyerPagesLayout children={<Wishlist />} />}
            />
            {/* Buyer Profile Routes */}
            <Route
                path="/buyer-profile-sideMenu"
                element={<BuyerProfileSideMenu />}
            />
            <Route
                path="/buyer-account-details"
                element={<BuyerProfileLayout children={<BuyerAccountDetails />} />}
            />
            <Route
                path="/buyer-address"
                element={<BuyerProfileLayout children={<BuyerAddress />} />}
            />
            <Route
                path="/buyer-privacy"
                element={<BuyerProfileLayout children={<BuyerPrivacy />} />}
            />
            <Route
                path="/buyer-terms"
                element={<BuyerProfileLayout children={<BuyerTerms />} />}
            />
            <Route
                path="/buyer-contact-us"
                element={<BuyerProfileLayout children={<BuyerContactUs />} />}
            />
            <Route
                path="/buyer-change-password"
                element={<BuyerProfileLayout children={<BuyerChangePassword />} />}
            />
            <Route
                path="/account-details/buyer-account-details-edit"
                element={
                    <BuyerProfileLayout children={<BuyerAccountDetailsEdit />} />
                }
            />
            <Route
                path="/address/buyer-address-edit"
                element={<BuyerProfileLayout children={<BuyerAddressEdit />} />}
            />
            <Route
                path="/my-orders"
                element={<BuyerProfileLayout children={<BuyerOrders />} />}
            />
            <Route
                path="/my-orders/:slug"
                element={<BuyerProfileLayout children={<BuyerOrderDetail />} />}
            />
            <Route
                path="/transaction-history"
                element={
                    <BuyerProfileLayout children={<BuyerTransactionHistory />} />
                }
            />
            <Route
                path="/transaction-history/:slug"
                element={
                    <BuyerProfileLayout children={<BuyerTransactionDetail />} />
                }
            />
            <Route
                path="/category/category-table"
                element={<DashboardLayout children={<CategoryTable />} />}
            />
            <Route
                path="/guest-home"
                element={
                    <BuyerPagesLayout
                        children={<Home />}
                        isShow={true}
                        isDontShow={false}
                    />
                }
            />
            <Route
                path="/my-orders/return-orders"
                element={<BuyerProfileLayout children={<ReturnOrder />} />}
            />
            <Route
                path="/my-orders/return-orders/details"
                element={<BuyerProfileLayout children={<ReturnOrderDEtail />} />}
            />
            <Route
                path="/my-orders/return-orders/payment"
                element={<BuyerProfileLayout children={<ReturnOrderPayment />} />}
            />
            <Route path="/report-center" element={<ReportCenter />} />
            <Route path="/report-center-form" element={<ReportCenterForm />} />
            </Route>
        </Routes>

    );
};

export default Navigation;
