import React,{useState,useEffect} from "react";
import { Assets } from "../../constant/images";
import { NavLink } from "react-router-dom";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import Footer from "../Footer/Footer";
import NavbarHeader from "../Navbar/Navbar";
import { useGetallreportQuery } from "../../redux/reducers/ReportSlice/ReportApiSlice";

const ReportCenter = () => {

  const { data: reports, isLoading }  = useGetallreportQuery();
  const[allReport,setAllReport] = useState([]);

    const getAllReports= async()=>{
        try{
            if (!isLoading) {
              setAllReport(reports?.data || []);
              }

        }catch(e){
            console.log(e);
        }
    }
    useEffect(()=>{
      getAllReports();

    },[isLoading])



  return (
    <>
      <NavbarHeader />
      <section className="main-report-center main-section">
        <Container>
          <Row>
            <Col xs={12}>
              <h3>Report Center</h3>
              <h4>Submit a Report</h4>
              <h5>Report Type</h5>
              <p>
                Please describe the situation as clearly and objectively as
                possible. All reports will remain anonymous and will be
                processed by Afrotierre as soon as possible. Please do not
                re-submit your report(s).
              </p>
            </Col>
            <Col xs={12}>
              <div className="main-report-type-div">
                {allReport.length !== 0 ? allReport.data.map((item,index)=>{
                  return(
                    <div key={index} className="report-type-div">
                  <h4>{item.name}</h4>
                  <p>
                    If you are intellectual property rights holders, including
                    copyright (pictures, artworks, etc.), portrait rights,
                    trademark rights, and believe that the product infringes on
                    your rights, please submit complaints on IPP Platform.
                  </p>
                  <div className="main-submit-div">
                    <NavLink to="/report-center-form">Submit</NavLink>
                  </div>
                </div>
                  )

                }):null}
                
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default ReportCenter;
