import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { NavLink } from "react-router-dom";
import { Assets } from "../../constant/images";
import { CrossIcon, ProfileRightArrow } from "../../constant/svg";

function ReportModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="report-chat"
    >
      <div className="d-flex align-items-center justify-content-between">
        <div className="modal-title">{props.title}</div>
        <div className="cross-bg" onClick={props.onHide}>
          <CrossIcon />
        </div>
      </div>
      <div className="signup-form mt_30">
        <div>
          <div className="">
            <input id="rad1" type="radio" name="rad" />
            <label className="report-conditions" for="rad1">Pretending to be someone else</label>
          </div>

          <div className="">
            <input id="rad2" type="radio" name="rad" />
            <label className="report-conditions" for="rad2">Nudity</label>
          </div>

          <div className="">
            <input id="rad3" type="radio" name="rad" />
            <label className="report-conditions" for="rad3">Violence</label>
          </div>

          <div className="">
            <input id="rad4" type="radio" name="rad" />
            <label className="report-conditions" for="rad4">Harrassment</label>
          </div>

          <div className="">
            <input id="rad5" type="radio" name="rad" />
            <label className="report-conditions" for="rad5">Spam or Fraud</label>
          </div>

        </div>
      </div>
      <div className="mt_24 d-flex justify-content-end">
        <Button onClick={props.onHide} className="sub-btn d-none">
          Cancel
        </Button>
        <Button onClick={props.onHide} className="main-btn">
          Submit
        </Button>
      </div>
    </Modal>
  );
}

export default ReportModal;
