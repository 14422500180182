import { apiSlice } from "../../api/ApiSlice"

export const ReportApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getallreport:builder.query({
            query: () => ({
                url: '/report-type/all',
                providesTags: ['Report'],
            }),
        }),
    })
})

export const {
    useGetallreportQuery
} = ReportApiSlice