import React from "react";
import { Col, Row } from "react-bootstrap";
import { Assets } from "../../constant/images";

const CompletedTrackingDetails = () => {
  return (
    <>
      <div className="main-completed-td">
        <Row>
          <div className="orderno-bg">
            <h2>Tracking Details</h2>

            <div className="order-data">
              <Row>
                <Col md={6}>
                  <div className="order-ques">CN Number</div>
                </Col>
                <Col md={6}>
                  <div className="order-ans">123456</div>
                </Col>
              </Row>
            </div>
            <div className="order-data">
              <Row>
                <Col xs={12}>
                  <div className="order-ques mb-2">Receipt</div>
                </Col>
                <Col xs={12}>
                  <div className="order-ans">
                    <img src={Assets.DummyReceipt} alt="receipt" className="img-fluid"/>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Row>
      </div>
    </>
  );
};

export default CompletedTrackingDetails;
