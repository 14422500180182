import { createSlice } from "@reduxjs/toolkit";
import CategoryAction from "../../actions/CategoryAction";


const initialState={
    Category:[],
}

export const CategorySlice = createSlice({
    name:'Category',
    initialState,
    reducers:CategoryAction,
})

export const CategoryActionCalls = CategorySlice.actions;

export default CategorySlice.reducer; 

export const selectCurrentCategory = (state)=>state.Category.Category;