import React,{useEffect,useState,useMemo} from "react";
import { Assets } from "../../constant/images";
import { NavLink, useLocation } from "react-router-dom";
import { PlusIcon } from "../../utils/svg";
import { useGetshippingQuery } from "../../redux/reducers/ShippingSlice/ShippingApiSlice";
import { useSelector} from "react-redux";
import { TailSpin } from "react-loader-spinner";
import { selectCurrentUser } from "../../redux/reducers/UserSlice/UserSlice";

const BuyerAddress = () => {
  const location = useLocation();
  const user = useSelector(selectCurrentUser)
  const[AllShipping,setAllShipping] = useState([]);
  const { data: shipping,isLoading,refetch} = useGetshippingQuery(user._id);
  const path = location.pathname.split("/")[1];


  useEffect(() => {
    refetch();  
    setAllShipping(shipping);
  }, [shipping]);

  if (isLoading) return <div className="loader">
  <TailSpin
      height="30"
      width="30"
      color="#dbbb51"
      ariaLabel="tail-spin-loading"
      radius="1"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
  />
</div>
  if (!shipping && !AllShipping) return <div className="loader">
  <TailSpin
      height="30"
      width="30"
      color="#dbbb51"
      ariaLabel="tail-spin-loading"
      radius="1"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
  />
</div>

  
  return (
    <>
      <div className="main-buyer-address">
        <div className="top-heading-edit-div">
          <div className="heading-div">
            <h4>Address</h4>
          </div>
          {/* <div className="edit-div">
            <NavLink to="/address/buyer-address-edit" className={path === "address" && "active"}>Edit</NavLink>
          </div> */}
        </div>

        <div className="buyer-account-content">
          <div className="buyer-avatar">
            <p>
              The following addresses will be used on the checkout page by
              default.
            </p>
          </div>
          <div className="shipping-addnew-heading">
            <div className="shipping-heading">
              <h2>Shipping Address</h2>
            </div>
            <div className="add-new-div">
              <div className="svg-div">
                <PlusIcon />
              </div>
              <div className="add-new-heading">
                <NavLink
                  to="/address/buyer-address-edit"
                  className={path === "address" && "active"}
                >
                  Add New
                </NavLink>
              </div>
            </div>
          </div>
          <div className="buyer-cards">
            {AllShipping && AllShipping.length !==0 ? AllShipping.data.data.map((item,index)=>{
              return(
                <div key={index} className="buyer-info">
            <div className="address-info">
              <ul>
                <li>
                  <span>Street Name:</span>
                  <span>{item.address}</span>
                </li>
                <li>
                  <span>City:</span> <span>{item.city?.name}</span>
                </li>
                <li>
                  <span>ZIP Code</span> <span>{item.zip_code}</span>
                </li>
              </ul>
            </div>
            <div className="edit-button-div">
              <NavLink
                to="/address/buyer-address-edit"
                state={{sid:item._id}}
                className={path === "address" && "active"}
              >
                Edit
              </NavLink>
            </div>
          </div>
              )

            }):null}
          
          
        
          </div>

        </div>
      </div>
    </>
  );
};

export default BuyerAddress;
