import { React, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Assets } from "../../constant/images";
import { DeleteBin } from "../../constant/svg";
import Table from "react-bootstrap/Table";
import DeleteModal from "../../components/Modals/DeleteModal";

const CartItems = (props) => {
  return (
    <>
      <div>
        <div className="cartitemscard d-flex align-items-center">
          <div className="cartimg-box">
            <img src={props.cartimg} />
          </div>
          <div className="product-name">Wood Ornament</div>
        </div>
      </div>
    </>
  );
};
const Wishlist = () => {
  const [deletemodalShow,setDeleteModalShow]=useState(false)
  const [itemswishlist, setItemsWishlist] = useState("3");
//   var name = prompt("Enter no of products in wishlist");
  const wishlistproducts = 1;
  return (
    <div className="main-section">
      <Container>
        <div className="wishlist">
          <div className=" d-block d-sm-flex align-items-center justify-content-between">
            <div className="wishlist-heading">
              Items in your wishlist ({itemswishlist})
            </div>
            <div className="d-flex align-items-center mt-3 mt-sm-0" onClick={() => setDeleteModalShow(true)}>
              <DeleteBin />
              <div className="delete-txt cursor-pointer">Delete</div>
            </div>
          </div>
          <Form className="mt_20 w-100">
            <div className="grid-col">
            <Row>
                <Col xs={6}>
                    <div className="text-center">Product Name</div>
                </Col>
                <Col xs={6}>
                    <div className="text-end text-sm-start">Unit Price</div>
                </Col>
            </Row>
            </div>
            <div className="wishlist-grid">
            {wishlistproducts==='0' ? <span>
                <div className="no-products-in-wishlist">
                    No products added to the wishlist
                </div>
            </span> : 
            <span>
                                <Row>
                <Col xs={10} md={6}>
                <Form.Check
                type="checkbox"
                id="capital"
                label={<CartItems cartimg={Assets.CartProduct1} />}
                name="capital"
              />
                </Col>
                <Col xs={2} md={6}>
                    <div className="unit-price">$348</div>
                </Col>
            </Row>
            <Row>
                <Col xs={10} md={6}>
                <Form.Check
                type="checkbox"
                id="product2"
                label={<CartItems cartimg={Assets.CartProduct2} />}
                name="capital"
              />
                </Col>
                <Col xs={2} md={6}>
                    <div className="unit-price">$348</div>
                </Col>
            </Row>
            <Row>
                <Col xs={10} md={6}>
                <Form.Check
                type="checkbox"
                id="product3"
                label={<CartItems cartimg={Assets.Wishlist2} />}
                name="capital"
              />
                </Col>
                <Col xs={2} md={6}>
                    <div className="unit-price">$348</div>
                </Col>
            </Row>
            <Row>
                <Col xs={10} md={6}>
                <Form.Check
                type="checkbox"
                id="producta"
                label={<CartItems cartimg={Assets.CartProduct1} />}
                name="capital"
              />
                </Col>
                <Col xs={2} md={6}>
                    <div className="unit-price">$348</div>
                </Col>
            </Row>
            <Row>
                <Col xs={10} md={6}>
                <Form.Check
                type="checkbox"
                id="productb"
                label={<CartItems cartimg={Assets.CartProduct2} />}
                name="capital"
              />
                </Col>
                <Col xs={2} md={6}>
                    <div className="unit-price">$348</div>
                </Col>
            </Row>
            <Row>
                <Col xs={10} md={6}>
                <Form.Check
                type="checkbox"
                id="productc"
                label={<CartItems cartimg={Assets.Wishlist2} />}
                name="capital"
              />
                </Col>
                <Col xs={2} md={6}>
                    <div className="unit-price">$348</div>
                </Col>
            </Row>    
            </span>}
            </div>
          </Form>
        </div>
      </Container>
      <DeleteModal
            heading="Delete Product"
            type="delete this product"
            btntext="Delete"
            link="/products/product-details"
            display="onClick={()=>{props.onHide}}"
            show={deletemodalShow}
            onHide={() => setDeleteModalShow(false)}
          />
    </div>
  );
};

export default Wishlist;
