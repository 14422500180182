import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import {
  SPECIAL_CHARACTER_ARRAY,
  SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
  VALIDATIONS,
  VALIDATIONS_TEXT,
  EXCLUDE_NUMBER_INPUT,
  EXCLUDE_ALPHABETS_SPECIAL_CHAR,
  SPECIAL_CHARACTER_NUMBER_ARRAY,
} from "../../constant/app-constants";
import { Assets } from "../../constant/images";

const DispatchedTrackingDetails = () => {
  const [value, setValue] = useState();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {};
  const [image, setImage] = useState(null);
  const fileInputRef = useRef(null);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setImage(event.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };
  return (
    <>
      <div className="main-dispatched-td">
        <Row>
          <div className="orderno-bg">
            <h2>Tracking Details</h2>
            <div className="form-div">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col xs={12}>
                    <FloatingLabel controlId="floatingInput" label="Name">
                      <Form.Control
                        placeholder="Category Name"
                        type="text"
                        onKeyDown={(e) =>
                          EXCLUDE_NUMBER_INPUT.includes(e.key) &&
                          e.preventDefault()
                        }
                        maxLength={VALIDATIONS.NAME}
                        {...register("name", {
                          maxLength: {
                            value: VALIDATIONS.NAME,
                            message: VALIDATIONS_TEXT.NAME_MAX,
                          },
                          pattern: {
                            value: /^[a-zA-Z\s]*$/,
                            message:
                              VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS,
                          },
                          required: {
                            value: true,
                            message: VALIDATIONS_TEXT.NAME_REQUIRED,
                          },
                        })}
                      />
                      {errors.name && (
                        <small className="text-red">
                          {errors.name.message}
                        </small>
                      )}
                    </FloatingLabel>
                  </Col>
                  <Col xs={12}>
                    <h3>Upload Receipt</h3>
                    <label htmlFor="image-upload" style={{ display: "none" }}>
                      Upload an Image
                    </label>
                    <input
                      id="image-upload"
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      ref={fileInputRef}
                      style={{ display: "none" }}
                    />
                    {image ? (
                      <div style={{ position: "relative" }}>
                        <div className="img-upload-div">
                          <img src={image} alt="Uploaded Image" />
                          <img
                            src={Assets.OverlayUpload}
                            alt="Placeholder Imassge"
                            style={{
                              position: "absolute",
                              top: "25%",
                              left: "7%",
                              width: "38px",
                              height: "48px",
                              opacity: 1,
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <label htmlFor="image-upload">
                        <img
                          src={Assets.OverlayIcon}
                          alt="Placeholders Image"
                        />
                        <input
                          id="image-upload"
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                          ref={fileInputRef}
                          style={{ display: "none" }}
                        />
                      </label>
                    )}
                  </Col>
                </Row>

                <div className="button-div">
                  <Button type="submit">Submit</Button>
                </div>
              </Form>
            </div>
          </div>
        </Row>
      </div>
    </>
  );
};

export default DispatchedTrackingDetails;
