import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import DonutChart from "../../components/DonutChart/DonutChart";
import LineGraph from "../../components/LineGraph/LineGraph";
import DashboardSidebar from "../../components/Sidebar/DashboardSidebar";
import TopBar from "../../components/TopBar/TopBar";
import { Dropdown } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import { Form } from "react-bootstrap";
import { Assets } from "../../constant/images";
import Dispatched from "../../components/ProjectStatus/Dispatched";
import InProcess from "../../components/ProjectStatus/InProcess";
import Completed from "../../components/ProjectStatus/Completed";
import { AddProductBtn, ArrowRight } from "../../constant/svg";
import { NavLink } from "react-router-dom";

const AddProduct = () => {
  const columns = [
    {
      field: "UserId",
      headerName: "Product Title",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center mt-2">
            <img className="img-fluid" src={params.row.image} />
            <div className="product-table-title">{params.row.producttitle}</div>
          </div>
        );
      },
    },
    {
      field: "startdate",
      headerName: "Start Date",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "projectdate",
      headerName: "End Date",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Project Status",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return <div>{params.row.status}</div>;
      },
    },
    {
      field: "price",
      headerName: "Project Price",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
  ];

  const rows = [
    // {
    //   id: 1,
    //   image: Assets.Tableavatarone,
    //   producttitle: "RW5562",
    //   startdate: "Mar 22, 2022",
    //   projectdate: "Mar 29, 2022",
    //   status: <Dispatched />,
    //   price: "$120.00",
    // },

    // {
    //   id: 2,
    //   image: Assets.Tableavatartwo,
    //   producttitle: "RW5562",
    //   UserId: "02",
    //   startdate: "Mar 22, 2022",
    //   projectdate: "Mar 29, 2022",
    //   status: <InProcess />,
    //   price: "$120.00",
    // },

    // {
    //   id: 3,
    //   image: Assets.Tableavatarthree,
    //   producttitle: "RW5562",
    //   UserId: "03",
    //   startdate: "Mar 22, 2022",
    //   projectdate: "Mar 29, 2022",
    //   status: <Completed />,
    //   price: "$120.00",
    // },
    // {F
    //   id: 4,
    //   image: Assets.Tableavatarfour,
    //   producttitle: "RW5562",
    //   UserId: "04",
    //   startdate: "Mar 22, 2022",
    //   projectdate: "Mar 29, 2022",
    //   status: <Dispatched />,
    //   price: "$120.00",
    // },
    // {
    //   id: 5,
    //   image: Assets.Tableavatarone,
    //   producttitle: "RW5562",
    //   UserId: "05",
    //   startdate: "Mar 22, 2022",
    //   projectdate: "Mar 29, 2022",
    //   status: <InProcess />,
    //   price: "$120.00",
    // },
    // {
    //   id: 6,
    //   image: Assets.Tableavatartwo,
    //   producttitle: "RW5562",
    //   UserId: "06",
    //   startdate: "Mar 22, 2022",
    //   projectdate: "Mar 29, 2022",
    //   status: <Completed />,
    //   price: "$120.00",
    // },
    // {
    //   id: 7,
    //   image: Assets.Tableavatarthree,
    //   producttitle: "RW5562",
    //   UserId: "07",
    //   startdate: "Mar 22, 2022",
    //   projectdate: "Mar 29, 2022",
    //   status: <Dispatched />,
    //   price: "$120.00",
    // },
    // {
    //   id: 8,
    //   image: Assets.Tableavatarfour,
    //   producttitle: "RW5562",
    //   UserId: "08",
    //   startdate: "Mar 22, 2022",
    //   projectdate: "Mar 29, 2022",
    //   status: <InProcess />,
    //   price: "$120.00",
    // },
    // {
    //   id: 9,
    //   image: Assets.Tableavatarone,
    //   producttitle: "RW5562",
    //   UserId: "09",
    //   startdate: "Mar 22, 2022",
    //   projectdate: "Mar 29, 2022",
    //   status: <Completed />,
    //   price: "$120.00",
    // },
  ];

  return (
    <>
    <div className="main-title-heading">Product Management</div>
      <div className="order-table-container table-content-center">
        <div style={{ height: 100, width: "100%" }}>
          <DataGrid
            className="datagrid-table"
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[7]}
            checkboxSelection
          />
        </div>
        <div className="d-flex justify-content-center align-items-center" style={{height:"100%", margin:"auto"}}>
            <div>
                <img className="img-fluid" src={Assets.NoProduct}/>
                <div className="noproduct-text">There are no products here yet.</div>
                <div className="text-center"><NavLink to="/products/list"><Button className="add-product-btn"><span><AddProductBtn/></span> Add Product</Button></NavLink></div>
            </div>
        </div>
      </div>
    </>
  );
};

export default AddProduct;
