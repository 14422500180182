import React from "react";
import { Container } from "react-bootstrap";
import { Row, Col, Button, Form } from "react-bootstrap";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { NavLink, useNavigate } from "react-router-dom";
import {
  SPECIAL_CHARACTER_ARRAY,
  SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
  VALIDATIONS,
  VALIDATIONS_TEXT,
  EXCLUDE_NUMBER_INPUT,
  EXCLUDE_ALPHABETS_SPECIAL_CHAR,
  SPECIAL_CHARACTER_NUMBER_ARRAY,
} from "../constant/app-constants";
import { useForm } from "react-hook-form";
import { TailSpin } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { useForgotpasswordMutation } from '../redux/reducers/UserSlice/UserApiSlice'
import { UserActionCalls } from "../redux/reducers/UserSlice/UserSlice";
import {toast} from "react-toastify";

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [forgotpassword, { isLoading }] = useForgotpasswordMutation();
  const onSubmit = async (data) => {
    const userData = {
      email: data.email,
    }

    try {
      const ForgotUser = await forgotpassword(userData).unwrap()
      dispatch(UserActionCalls.ForgotPassword({ ...ForgotUser }))
      navigate('/otpverify', { state: { email: data.email } })


    } catch (e) {
      toast.error(`${e.data.error}`);

    }
  };
  return (
    <>
      <Row>
        <Col md={9}>
          <h6>Enter the email associated with your account and
            we'll send verification code to reset your password</h6>
        </Col>
      </Row>
      <div className="new-hr">
        <hr />
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="signup-form">
          <Row>
            <Col md={6}>
              <FloatingLabel
                controlId="floatingInput"
                label="Email Address"
              >
                <Form.Control
                  className="mb-0"

                  type="email"
                  placeholder="Email Address"
                  maxLength={VALIDATIONS.EMAIL}
                  {...register("email", {
                    maxLength: {
                      value: VALIDATIONS.EMAIL,
                      message: VALIDATIONS_TEXT.EMAIL_MAX,
                    },
                    required: {
                      value: true,
                      message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                    },
                    pattern: {
                      value:
                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                      message: VALIDATIONS_TEXT.EMAIL_FORMAT,
                    },
                  })}
                />
                {errors.email && (
                  <small className="text-red error_from">
                    {errors.email.message}
                  </small>
                )}
              </FloatingLabel>
            </Col>

          </Row>
          <div>
            {!isLoading ?
            <Button type="submit" className="auth-btn">Submit</Button>:
            <div className="loader">
                                    <TailSpin
                                        height="30"
                                        width="30"
                                        color="#dbbb51"
                                        ariaLabel="tail-spin-loading"
                                        radius="1"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                    />
                                </div>
}
          </div>
        </div>
      </Form>
    </>
  )
}

export default ForgotPassword