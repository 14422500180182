import { apiSlice } from "../../api/ApiSlice"

export const StateApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getstate:builder.query({
            query: (id) => ({
                url: `state?country_id=${id}`,
                providesTags: ['Country'],
            }),
        }),
    })
})

export const {
    useGetstateQuery
} = StateApiSlice