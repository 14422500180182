export const Assets = {
    Logo: require("../assets/images/navbar/logo.png"),
    Dog: require("../assets/images/recentlyuploaded/dog.png"),
    Bag :require("../assets/images/recentlyuploaded/bag.png"),
    Ball :require("../assets/images/recentlyuploaded/ball.png"),
    Tree :require("../assets/images/recentlyuploaded/tree.png"),
    one :require("../assets/images/recentlyuploaded/one.png"),
    two :require("../assets/images/recentlyuploaded/two.png"),
    three :require("../assets/images/recentlyuploaded/three.png"),
    four :require("../assets/images/recentlyuploaded/four.png"),
    five :require("../assets/images/recentlyuploaded/five.png"),
    six :require("../assets/images/recentlyuploaded/six.png"),
    seven :require("../assets/images/recentlyuploaded/seven.png"),
    eight :require("../assets/images/recentlyuploaded/eight.png"),
    nine :require("../assets/images/recentlyuploaded/nine.png"),
    ten :require("../assets/images/recentlyuploaded/ten.png"),
    eleven :require("../assets/images/recentlyuploaded/eleven.png"),
    twelve :require("../assets/images/recentlyuploaded/twelve.png"),
    thirteen :require("../assets/images/recentlyuploaded/thirteen.png"),
    fourteen :require("../assets/images/recentlyuploaded/fourteen.png"),
    fifteen :require("../assets/images/recentlyuploaded/fifteen.png"),
    sixteen :require("../assets/images/recentlyuploaded/sixteen.png"),
    seventeen :require("../assets/images/recentlyuploaded/seventeen.png"),
    eighteen :require("../assets/images/recentlyuploaded/eighteen.png"),
    nineteen :require("../assets/images/recentlyuploaded/nineteen.png"),
    twenty :require("../assets/images/recentlyuploaded/twenty.png"),
    twentyone :require("../assets/images/recentlyuploaded/twentyone.png"),
    twentytwo :require("../assets/images/recentlyuploaded/twentytwo.png"),
    twentythree :require("../assets/images/recentlyuploaded/twentythree.png"),
    twentyfour :require("../assets/images/recentlyuploaded/twentyfour.png"),
    twentyfive :require("../assets/images/recentlyuploaded/twentyfive.png"),
    twentysix :require("../assets/images/recentlyuploaded/twentysix.png"),
    twentyseven :require("../assets/images/recentlyuploaded/twentyseven.png"),
    MainLogo: require("../assets/images/Auth/MainLogo.png"),
    Avatar: require("../assets/images/Dashboard/avatar.png"),
    Tableavatarone: require("../assets/images/Dashboard/tableone.png"),
    Tableavatartwo: require("../assets/images/Dashboard/tabletwo.png"),
    Tableavatarthree: require("../assets/images/Dashboard/tablethree.png"),
    Tableavatarfour: require("../assets/images/Dashboard/tablefour.png"),
    Tableavatarfive: require("../assets/images/Dashboard/tablefive.png"),
    ProductMain:require("../assets/images/Product/productmain.png"),
    productone: require("../assets/images/Product/productone.png"),
    producttwo: require("../assets/images/Product/producttwo.png"),
    productthree: require("../assets/images/Product/productthree.png"),
    productfour: require("../assets/images/Product/produvtfour.png"),
    Kate: require("../assets/images/Product/kate.png"),
    Carly: require("../assets/images/Product/carly.png"),
    Kelly: require("../assets/images/Product/kelly.png"),
    Nomsg: require("../assets/images/Chat/nomessage.png"),
    ProfileImg: require("../assets/images/Profile/myprofile.png"),
    ImageUploader: require("../assets/images/Profile/imguploader.png"),
    SearchImg: require("../assets/images/Search/searchimg.png"),
    NoProduct: require("../assets/images/Product/noproduct.png"),
    BuyerAvatar: require("../assets/images/BuyerProfile/buyer-avatar.png"),
    Order1: require("../assets/images/BuyerProfile/myorder-1.png"),
    Order2: require("../assets/images/BuyerProfile/myorder-2.png"),
    OrderDetail: require("../assets/images/BuyerProfile/order-detail.png"),
    List1: require("../assets/images/ProductListing/list1.png"),
    List2: require("../assets/images/ProductListing/list2.png"),
    List3: require("../assets/images/ProductListing/list3.png"),
    List4: require("../assets/images/ProductListing/list4.png"),
    List5: require("../assets/images/ProductListing/list5.png"),
    List6: require("../assets/images/ProductListing/list6.png"),
    List7: require("../assets/images/ProductListing/list7.png"),
    List8: require("../assets/images/ProductListing/list8.png"),
    List9: require("../assets/images/ProductListing/list9.png"),
    List10: require("../assets/images/ProductListing/list10.png"),
    List11: require("../assets/images/ProductListing/list11.png"),
    List12: require("../assets/images/ProductListing/list12.png"),
    List13: require("../assets/images/ProductListing/list13.png"),
    List14: require("../assets/images/ProductListing/list14.png"),
    List15: require("../assets/images/ProductListing/list15.png"),
    List16: require("../assets/images/ProductListing/list16.png"),
    List17: require("../assets/images/ProductListing/list17.png"),
    List18: require("../assets/images/ProductListing/list18.png"),
    List19: require("../assets/images/ProductListing/list19.png"),
    List20: require("../assets/images/ProductListing/list20.png"),
    List21: require("../assets/images/ProductListing/list21.png"),
    List22: require("../assets/images/ProductListing/list22.png"),
    List23: require("../assets/images/ProductListing/list23.png"),
    List24: require("../assets/images/ProductListing/list24.png"),
    wallclock: require("../assets/images/BuyerProductDetails/wallclock.png"),
    clockone: require("../assets/images/BuyerProductDetails/clockone.png"),
    clocktwo: require("../assets/images/BuyerProductDetails/clocktwo.png"),
    clockthree: require("../assets/images/BuyerProductDetails/clockthree.png"),
    clockfour: require("../assets/images/BuyerProductDetails/clockfour.png"),
    clockfive: require("../assets/images/BuyerProductDetails/clockfive.png"),
    msgseller: require("../assets/images/BuyerProductDetails/msgseller.png"),
    SellerImg: require("../assets/images/BuyerProductDetails/sellerimg.png"),
    Cartempty: require("../assets/images/Cart/cartempty.png"),
    EmptyBag: require("../assets/images/Cart/emptybag.png"),
    CartProduct1: require("../assets/images/Cart/productone.png"),
    CartProduct2: require("../assets/images/Cart/producttwo.png"),
    Stripe: require("../assets/images/Checkout/stripe.png"),
    Applepay: require("../assets/images/Checkout/applepay.png"),
    Mastercard: require("../assets/images/Checkout/mastercard.png"),
    BuyerProfilePic: require("../assets/images/navbar/shopavatar.png"),
    Wishlist1: require("../assets/images/Wishlist/wishlist1.png"),
    Wishlist2: require("../assets/images/Wishlist/whislist2.png"),
    Wishlist3: require("../assets/images/Wishlist/wishlist3.png"),
    SellerUploader: require("../assets/images/Auth/selleruploader.png"),
    ToastCheck: require("../assets/images/Toast/check.png"),
    SizeSCale: require("../assets/images/scale.png"),
    SizeSCaleSample: require("../assets/images/sizechart.png"),
    UploadImage: require("../assets/images/uploadimage.png"),
    SelectDropDown: require("../assets/images/select-down-arrow.png"),
    OverlayUpload: require("../assets/images/overlay.png"),
    OverlayIcon: require("../assets/images/uploadicon.png"),
    DummyReceipt: require("../assets/images/dummy-recipet.png")
}


