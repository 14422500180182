import React, { useState } from "react";
import { BackIcon } from "../../utils/svg";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Assets } from "../../constant/images";
import { NavLink, useLocation } from "react-router-dom";
import { STATUS } from "../../constant/app-constants";
import CancelOrderModal from "../../components/Modals/CancelOrderModal";
import ReviewModal from "../../components/Modals/ReviewModal";
import ReturnOrderModal from "../../components/Modals/ReturnOrderModal";

const DispatchedComponent = () => {
  return (
    <>
      <div className="main-buttons-div">
        <Row>
          <Col>
            <div className="buttons-div">
              <div className="right-div">
                <Button>View Recipt</Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
const PendingComponent = () => {
  const [cancelModalShow, setCancelModalShow] = useState(false);

  return (
    <>
      <div className="main-buttons-div">
        <Row>
          <Col>
            <div className="buttons-div">
              <div className="right-div">
                <Button onClick={() => setCancelModalShow(true)}>
                  Cancel Order
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <CancelOrderModal
          show={cancelModalShow}
          onHide={() => setCancelModalShow(false)}
        />
      </div>
    </>
  );
};
const CompletedComponent = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [reviewModalShow, setReviewModalShow] = useState(false);
  const location = useLocation();
  console.log(location);
  return (
    <>
    
      <div className="main-buttons-div">
        <ReturnOrderModal show={modalShow} onHide={() => setModalShow(false)} />
        <Row>
          <Col>
            <div className="buttons-div d-block d-sm-flex ">
              <div className="left-div mb-3 mb-sm-0">
                {location.state.data.expire ? (
                  <Button onClick={() => setModalShow(true)}>
                    Want to Return?
                  </Button>
                ) : (
                  <NavLink to="/my-orders/return-orders">
                    Want to Return?
                  </NavLink>
                )}
              </div>
              <div className="left-div mb-3 mb-sm-0">
                <Button onClick={() => setReviewModalShow(true)}>
                  Leave a Review
                </Button>
              </div>
              <div className="right-div">
                <NavLink to="/cart-screen">Buy Again</NavLink>
              </div>
            </div>
          </Col>
        </Row>
        <ReviewModal
          show={reviewModalShow}
          onHide={() => setReviewModalShow(false)}
        />
      </div>
    </>
  );
};
const CancelledComponent = () => {
  return (
    <>
      <div className="main-cancel-reason">
        <Row>
          <Col>
            <div className="cancel-reason-div">
              <h5>Reason for cancellation:</h5>
              <p>
                This is dummy. It is not meant to be read. It is placed here
                solely to demonstrate the look and feel of finished typeset
                text. Only for show.
              </p>
            </div>
          </Col>
        </Row>
      </div>

      <div className="main-buttons-div">
        <Row>
          <Col>
            <div className="buttons-div">
              <div className="right-div">
                <NavLink to="/cart-screen">
                  <Button>Reorder</Button>
                </NavLink>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
const BuyerOrderDetail = (props) => {
  const location = useLocation();
  const [orderDetails, setOrdersDetails] = useState(location.state.data);

  const returnStatusColor = (status) => {
    if (status == "in process") {
      return "confirmed";
    } else if (status == "pending") {
      return "pending";
    } else if (status == "completed") {
      return "completedd";
    } else if (status == "dispatched") {
      return "dispatched";
    } else {
      return "cancelled";
    }
  };

  const returnComponentStatus = (status) => {
    // match the status and return component for that status
    if (status == STATUS.confirmed) {
      return <></>;
    } else if (status == STATUS.pending) {
      return <PendingComponent />;
    } else if (status == STATUS.dispatched) {
      return <DispatchedComponent />;
    } else if (status == STATUS.completed) {
      return <CompletedComponent />;
    } else {
      return <CancelledComponent />;
    }
  };

  return (
    <>
      <div className="main-order-details">
        <Container>
          <Row>
            <div className="orderid-status-div">
              <div className="orderid-div">
                <div className="back-icon-div">
                  <NavLink to="/my-orders">
                    <BackIcon />
                  </NavLink>
                </div>
                <div className="order-id">
                  <h5>
                    Order ID <span>{orderDetails.orderId}</span>
                  </h5>
                </div>
              </div>
              <div className="status-div">
                <p className={returnStatusColor(orderDetails.status)}>
                  {orderDetails.status}
                </p>
              </div>
            </div>
          </Row>
          <div className="product-total-div">
            <Row>
              <Col lg={6}>
                <p>Product</p>
              </Col>
              <Col lg={6}>
                <p>Total</p>
              </Col>
            </Row>
          </div>
          <div className="product-detail-amount">
            {orderDetails.products.map((orderinfo) => (
              <Row>
                <Col lg={6}>
                  <div className="order-card-wrapper">
                    <div className="order-img-div">
                      <img className="img-fluid" src={orderinfo.img} />
                    </div>
                    <div className="order-info">
                      <p>
                        {orderinfo.name} <span>x{orderinfo.quantity}</span>
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} className="my-auto">
                  <div className="amount-div">
                    <p>{orderinfo.price}</p>
                  </div>
                </Col>
              </Row>
            ))}
          </div>
          <div className="product-sub-total">
            <Row>
              <Col lg={6}>
                <div className="sub-total-heading">
                  <h5>Subtotal</h5>
                </div>
              </Col>
              <Col lg={6} className="my-auto">
                <div className="amount-div">
                  <p>{orderDetails.subtotal}</p>
                </div>
              </Col>
            </Row>
          </div>

          <div className="product-vat">
            <Row>
              <Col lg={6}>
                <div className="vat-heading">
                  <h5>Tax</h5>
                </div>
              </Col>
              <Col lg={6} className="my-auto">
                <div className="amount-div">
                  <p>{orderDetails.vat}</p>
                </div>
              </Col>
            </Row>
          </div>

          <div className="product-shipping-rate">
            <Row>
              <Col lg={6}>
                <div className="shipping-rate-heading">
                  <h5>Shipping rate</h5>
                </div>
              </Col>
              <Col lg={6} className="my-auto">
                <div className="amount-div">
                  <p>{orderDetails.shipping_rate}</p>
                  <h5>(Est time. {orderDetails.est_time})</h5>
                </div>
              </Col>
            </Row>
          </div>

          <div className="product-total-amount">
            <Row>
              <Col lg={6}>
                <div className="total-amount-heading">
                  <h5>Total Amount</h5>
                </div>
              </Col>
              <Col lg={6} className="my-auto">
                <div className="amount-div">
                  <p>{orderDetails.total_amount}</p>
                </div>
              </Col>
            </Row>
          </div>
          {returnComponentStatus(orderDetails.status)}

          {/* <div className="main-cancel-reason">
              <Row>
                <Col>
                  <div className="cancel-reason-div">
                    <h5>Reason for cancellation:</h5>
                    <p>
                      This is dummy. It is not meant to be read. It is placed
                      here solely to demonstrate the look and feel of finished
                      typeset text. Only for show.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="main-buttons-div">
              <Row>
                <Col>
                  <div className="buttons-div">
                    <div className="left-div">
                      <NavLink>Leave a Review</NavLink>
                    </div>
                    <div className="right-div">
                      <NavLink>Buy Again</NavLink>
                    </div>
                  </div>
                </Col>
              </Row>
            </div> */}
        </Container>
      </div>
    </>
  );
};

export default BuyerOrderDetail;
