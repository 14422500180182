import {React, useState} from "react";
import Nav from "react-bootstrap/Nav";
import {NavLink, useLocation} from "react-router-dom";
import {
    AccountDetailsIcon,
    AddressIcon,
    ChangePasswordIcon,
    ContactIcon,
    LogOutIcon,
    MyOrdersIcon,
    PrivacyIcon,
    TermsIcon,
    TransactionHistoryIcon,
} from "../../utils/svg";
import LogoutModal from "../Modals/LogoutModal";


const BuyerProfileSideMenu = () => {
    const [showlogoutmodal, setShowLogoutModal] = useState(false);
    const location = useLocation();
    const path = location.pathname.split("/")[1];
    return (
        <>
            <div className="main-setting-menu">
                {/* <h2>Options / My Profile</h2> */}
                <div className="setting-tab">
                    <Nav
                        defaultActiveKey="/buyer-account-details"
                        className="flex-column"
                    >
                        <NavLink
                            to="/buyer-account-details"
                            className={path === "account-details" && "active"}
                        >
                            <div className="svg-div">
                                <AccountDetailsIcon/>
                            </div>
                            <div className="side-menu-link">
                                <h4>Account Details</h4>
                            </div>
                        </NavLink>
                        <NavLink
                            to="/buyer-address"
                            className={path === "address" && "active"}
                        >
                            <div className="svg-div">
                                <AddressIcon/>
                            </div>
                            <div className="side-menu-link">
                                <h4>Address</h4>
                            </div>
                        </NavLink>
                        <NavLink to="/my-orders" className={path === "orders" && "active"}>
                            <div className="svg-div">
                                <MyOrdersIcon/>
                            </div>
                            <div className="side-menu-link">
                                <h4>My Orders</h4>
                            </div>
                        </NavLink>
                        <NavLink to="/transaction-history" className={path === "transaction" && "active"}>
                            <div className="svg-div">
                                <TransactionHistoryIcon/>
                            </div>
                            <div className="side-menu-link">
                                <h4>Transaction History</h4>
                            </div>
                        </NavLink>
                        <NavLink to="/buyer-privacy">
                            <div className="svg-div">
                                <PrivacyIcon/>
                            </div>
                            <div className="side-menu-link">
                                <h4>Privacy Policy</h4>
                            </div>
                        </NavLink>
                        <NavLink to="/buyer-terms">
                            <div className="svg-div">
                                <TermsIcon/>
                            </div>
                            <div className="side-menu-link">
                                <h4>Terms & Conditions</h4>
                            </div>
                        </NavLink>
                        <NavLink to="/buyer-contact-us">
                            <div className="svg-div">
                                <ContactIcon/>
                            </div>
                            <div className="side-menu-link">
                                <h4>Contact Us</h4>
                            </div>
                        </NavLink>
                        <NavLink to="/buyer-change-password">
                            <div className="svg-div">
                                <ChangePasswordIcon/>
                            </div>
                            <div className="side-menu-link">
                                <h4>Change Password</h4>
                            </div>
                        </NavLink>
                        <div className="logout-profile" onClick={() => setShowLogoutModal(true)}>
                            <div className="svg-div">
                                <LogOutIcon/>
                            </div>
                            <div className="side-menu-link">
                                <h4>Logout</h4>
                            </div>
                        </div>
                    </Nav>
                </div>
            </div>
            <LogoutModal heading="Logout" link="/" type="logout" btntext="Logout" show={showlogoutmodal}
                         setShowLogoutModal onHide={() => setShowLogoutModal(false)}/>

        </>
    );
};

export default BuyerProfileSideMenu;
