import { React, useState } from "react";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { NavLink } from "react-router-dom";
import { Assets } from "../../constant/images";
import { CrossIcon, ProfileRightArrow } from "../../constant/svg";
import { MultiSelect } from "react-multi-select-component";
import { Swiper, SwiperSlide } from "swiper/react";

function EditProductModal(props) {
  const options = [
    { label: "XXS", value: "1" },
    { label: "SM", value: "2" },
    { label: "MD", value: "3" },
    { label: "LG", value: "4" },
    { label: "XL", value: "5" },
  ];

  const [uploadedImage, setUploadedImage] = useState([]);

  const [selected, setSelected] = useState([]);

  const handleImageUpload = (e) => {
    setUploadedImage([...uploadedImage, ...e.target.files]);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="edit-product-modal"
    >
      <div className="d-flex align-items-center justify-content-between">
        <div className="main-heading">{props.title}</div>
        <div className="cross-bg" onClick={props.onHide}>
          <CrossIcon />
        </div>
      </div>
      <div className="modal-form">
        <div className="signup-form">
          <Row>
            <Col md={6}>
              <FloatingLabel controlId="floatingInput" label="Product Title">
                <Form.Control type="text" placeholder="name@example.com" />
              </FloatingLabel>
            </Col>
            <Col md={6}>
              <FloatingLabel controlId="floatingInput" label="Price">
                <Form.Control type="text" placeholder="name@example.com" />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="">
                <Form.Control
                  placeholder="Description"
                  as="textarea"
                  rows={5}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <FloatingLabel controlId="floatingInput" label="Discounted Price">
                <Form.Control type="text" placeholder="name@example.com" />
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="Discounted Price">
                <Form.Control type="text" placeholder="name@example.com" />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Select aria-label="Default select example">
                <option value="1">Category</option>
                <option value="2">Shirt</option>
                <option value="3">Pants</option>
                <option value="4">Jacket</option>
              </Form.Select>
            </Col>
            <Col md={6}>
              <Form.Select aria-label="Default select example">
                <option value="1">color(s)</option>
                <option value="2">Red</option>
                <option value="3">Green</option>
                <option value="4">Blue</option>
              </Form.Select>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div>
                <MultiSelect
                  options={options}
                  value={selected}
                  onChange={setSelected}
                  labelledBy="Select"
                  className="mt_20"
                />
              </div>
              <div className="size-chart-text">Size Chart</div>
              <div className="mb-3 multi-image-uploader d-flex mt-3">

                <label className={`${uploadedImage.length >=2 ? "d-none" : "d-block me-3"}`} htmlFor="img">
                  <img src={Assets.SellerUploader}/>
                  <input
                    onChange={(e) => handleImageUpload(e)}
                    className={`d-none form-control custom-file-input ${
                      uploadedImage.length > 0 && "mt-15"
                    } me-3`}
                    name="image"
                    id="img"
                    type="file"
                    multiple
                    accept=".png, .jpeg, .jpg"
                  />
                </label>

                <Swiper
                  // navigation
                  // modules={[Navigation]}
                  spaceBetween={10}
                  breakpoints={{
                    200: {
                      slidesPerView: 2,
                    },
                    300: {
                      slidesPerView: 3,
                    },
                    640: {
                      slidesPerView: 5,
                    },
                    768: {
                      slidesPerView: 6,
                    },
                    992: {
                      slidesPerView: 8,
                    },
                    1200: {
                      slidesPerView: 10,
                    },
                    1300: {
                      slidesPerView: 10,
                    },
                  }}
                >
                  {uploadedImage.length > 0 &&
                    uploadedImage.map((v, i) => {
                      let url = URL.createObjectURL(v);
                      return (
                        <SwiperSlide>
                          <div className="box">
                            <div className="icon">
                              {/* <CrossIcon onClick={() => deleteImage(i)} /> */}
                            </div>
                            <img src={url} alt="PostImage1" />
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </div>

              <div className="size-chart-text">Upload Images</div>
              <div className="mb-3 multi-image-uploader d-flex mt-3">

                <label className={`${uploadedImage.length >=2 ? "d-none" : "d-block me-3"}`} htmlFor="img">
                  <img src={Assets.SellerUploader}/>
                  <input
                    onChange={(e) => handleImageUpload(e)}
                    className={`d-none form-control custom-file-input ${
                      uploadedImage.length > 0 && "mt-15"
                    } me-3`}
                    name="image"
                    id="img"
                    type="file"
                    multiple
                    accept=".png, .jpeg, .jpg"
                  />
                </label>
              </div>
            </Col>
            <Col md={6}>
              <div>
                <MultiSelect
                  options={options}
                  value={selected}
                  onChange={setSelected}
                  labelledBy="Select"
                  className="mt_20"
                />
              </div>
              <div>
                <MultiSelect
                  options={options}
                  value={selected}
                  onChange={setSelected}
                  labelledBy="Select"
                  className="mt_20"
                />
              </div>
              <div>
                <MultiSelect
                  options={options}
                  value={selected}
                  onChange={setSelected}
                  labelledBy="Select"
                  className="mt_20"
                />
              </div>
              <div>
                <MultiSelect
                  options={options}
                  value={selected}
                  onChange={setSelected}
                  labelledBy="Select"
                  className="mt_20"
                />
              </div>
              <Form.Group className="">
                <Form.Control
                  placeholder="Description"
                  as="textarea"
                  className="mt_20"
                  rows={5}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
      </div>
      <div className="text-end">
        <Button onClick={props.onHide} className="add-product-modal">
          Add Product
        </Button>
      </div>
    </Modal>
  );
}

export default EditProductModal;
