import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import {
  SPECIAL_CHARACTER_ARRAY,
  SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
  VALIDATIONS,
  VALIDATIONS_TEXT,
  EXCLUDE_NUMBER_INPUT,
  EXCLUDE_ALPHABETS_SPECIAL_CHAR,
  SPECIAL_CHARACTER_NUMBER_ARRAY,
} from "../../constant/app-constants";
import PhoneInput from "react-phone-number-input";
const BuyerContactUs = () => {
  const [value, setValue] = useState();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    
  };
  return (
    <>
      <div className="main-buyer-contact">
        <div className="top-heading-edit-div">
          <div className="heading-div">
            <h4>Contact Us</h4>
            <p>Let us know about your query</p>
          </div>
        </div>

        <div className="buyer-contact-fields">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={6}>
                <FloatingLabel controlId="floatingInput" label="Name">
                  <Form.Control
                    placeholder="Name"
                    type="text"
                    onKeyDown={(e) =>
                      EXCLUDE_NUMBER_INPUT.includes(e.key) && e.preventDefault()
                    }
                    maxLength={VALIDATIONS.NAME}
                    {...register("name", {
                      maxLength: {
                        value: VALIDATIONS.NAME,
                        message: VALIDATIONS_TEXT.NAME_MAX,
                      },
                      pattern: {
                        value: /^[a-zA-Z\s]*$/,
                        message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS,
                      },
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.NAME_REQUIRED,
                      },
                    })}
                  />
                  {errors.name && (
                    <small className="text-red">{errors.name.message}</small>
                  )}
                </FloatingLabel>
              </Col>
              {/* <Form.Group
                as={Col}
                controlId="formGridEmail"
                className="form-group"
              >
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Sidney Wilams"
                  onKeyDown={(e) =>
                    EXCLUDE_NUMBER_INPUT.includes(e.key) && e.preventDefault()
                  }
                  maxLength={VALIDATIONS.NAME}
                  {...register("name", {
                    maxLength: {
                      value: VALIDATIONS.NAME,
                      message: VALIDATIONS_TEXT.NAME_MAX,
                    },
                    pattern: {
                      value: /^[a-zA-Z\s]*$/,
                      message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS,
                    },
                    required: {
                      value: true,
                      message: VALIDATIONS_TEXT.NAME_REQUIRED,
                    },
                  })}
                />

                {errors.name && (
                  <small className="text-red">{errors.name.message}</small>
                )}
              </Form.Group> */}
              {/* 
              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="johnsmith@gmail.com"
                  maxLength={VALIDATIONS.EMAIL}
                  {...register("email", {
                    maxLength: {
                      value: VALIDATIONS.EMAIL,
                      message: VALIDATIONS_TEXT.EMAIL_MAX,
                    },
                    required: {
                      value: true,
                      message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                    },
                    pattern: {
                      value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                      message: VALIDATIONS_TEXT.EMAIL_FORMAT,
                    },
                  })}
                />
                {errors.email && (
                  <small className="text-red error_from">
                    {errors.email.message}
                  </small>
                )}
              </Form.Group> */}
              <Col md={6}>
                <FloatingLabel controlId="floatingInput" label="Email Address">
                  <Form.Control
                    type="email"
                    placeholder="Email Address"
                    maxLength={VALIDATIONS.EMAIL}
                    {...register("email", {
                      maxLength: {
                        value: VALIDATIONS.EMAIL,
                        message: VALIDATIONS_TEXT.EMAIL_MAX,
                      },
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                      },
                      pattern: {
                        value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                        message: VALIDATIONS_TEXT.EMAIL_FORMAT,
                      },
                    })}
                  />
                  {errors.email && (
                    <small className="text-red error_from">
                      {errors.email.message}
                    </small>
                  )}
                </FloatingLabel>
              </Col>
            </Row>

            <Row>
            <Col md={6}>
              <div className="phone-input position-realtive">
                <PhoneInput
                  international
                  placeholder="Enter phone number *"
                  value={value}
                  onChange={setValue}
                  maxLength={VALIDATIONS.PHONE_MAX}
                  // countrySelectProps={{
                  //   showName: false,
                  // }}
                  // onlyCountries={allowedPhoneRegions}
                  {...register("phone", {
                    maxLength: {
                      value: VALIDATIONS.PHONE_MAX,
                      message: VALIDATIONS_TEXT.PHONE_MAX,
                    },
                    required: {
                      value: true,
                      message: VALIDATIONS_TEXT.PHONE_REQUIRED,
                    },
                  })}
                />
                {errors.phone && (
                  <small className="text-red error_from">
                    {errors.phone.message}
                  </small>
                )}
              </div>
            </Col>
              {/* <Form.Group
                as={Col}
                controlId="formGridEmail"
                className="form-group"
              >
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="+01-143553464"
                  onKeyDown={(e) => {
                    EXCLUDE_ALPHABETS_SPECIAL_CHAR.includes(
                      e.key.toUpperCase()
                    ) && e.preventDefault();
                  }}
                  maxLength={VALIDATIONS.PHONE_MAX}
                  {...register("phone", {
                    pattern: {
                      value:
                        /^[\+]?[(]?[0-9]{2}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                      message: VALIDATIONS_TEXT.INVALID_PHONE_FORMAT,
                    },
                    minLength: {
                      value: VALIDATIONS.PHONE_MIN,
                      message: VALIDATIONS_TEXT.PHONE_MIN,
                    },
                    maxLength: {
                      value: VALIDATIONS.PHONE_MAX,
                      message: VALIDATIONS_TEXT.PHONE_MAX,
                    },
                    required: {
                      value: true,
                      message: VALIDATIONS_TEXT.PHONE_REQUIRED,
                    },
                  })}
                />
                {errors.phone && (
                  <small className="text-red">{errors.phone.message}</small>
                )}
              </Form.Group> */}

              {/* <Form.Group
                as={Col}
                controlId="formGridState"
                className="form-group"
              >
                <Form.Label>Subject</Form.Label>
                <Form.Select defaultValue="Order Tracking">
                  <option>Order Tracking</option>
                  <option>Payment Details</option>
                </Form.Select>
              </Form.Group> */}
              <Col md={6}>
                <div class="form-floating">
                  <select
                    class="form-select"
                    id="floatingSelect"
                    aria-label="Floating label select example"
                  >
                    <option selected>Order Tracking</option>
                    <option value="1">Order Tracking</option>
                    <option value="2">Order Tracking</option>
          
                  </select>
                  <label for="floatingSelect">Subject</label>
                </div>
              </Col>
            </Row>

            {/* <Form.Group
              className="form-group"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={3} />
            </Form.Group> */}
            <Col md={12}>
              <div class="form-floating">
                <textarea
                  class="form-control"
                  placeholder="Leave a comment here"
                  id="floatingTextarea"
                ></textarea>
                <label for="floatingTextarea">Message</label>
              </div>
            </Col>

            <div className="button-div">
              <Button type="submit">Submit</Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default BuyerContactUs;
