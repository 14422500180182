import { React, useState } from "react";
import { Assets } from "../../constant/images";
import { NavLink } from "react-router-dom";
import { InputGroup, Form, Row, Col } from "react-bootstrap";
import {
  DropdownIcon,
  NotificationIcon,
  SearchIcon,
  SearchIconTopBar,
} from "../../constant/svg";
import MyProfileModal from "../Modals/MyProfileModal";
import Modal from "react-bootstrap/Modal";
import { selectCurrentUser } from "../../redux/reducers/UserSlice/UserSlice";
import ProfileModal from "../Modals/ProfileModal";
import NotificationModal from "../Modals/NotificationModal";
import { useSelector } from "react-redux";

const TopBar = () => {
  const [modalShow, setModalShow] = useState(false);
  const [notifmodalShow,SetNotifModalShow] = useState(false);
  const user = useSelector(selectCurrentUser);

  // console.log(user);

  return (
    <>
    <div className="top-bar">
      <div className="search-box">
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">
            <NavLink to="/search">
              <SearchIconTopBar />
            </NavLink>
          </InputGroup.Text>
          <Form.Control
            placeholder="Search"
            aria-label="Search"
            aria-describedby="basic-addon1"
          />
        </InputGroup>
      </div>

      <div className="d-flex justify-content-end mb-3 mb-md-0">
        <div className="d-flex ">
          <div className="notification-box"   onClick={() => SetNotifModalShow(true)}>
            <NotificationIcon />
          </div>
          <div
            className="profile-dropdown d-flex align-items-center justify-content-center  ms-3"
            onClick={() => setModalShow(true)}
          >
            <div>
              <img className="img-fluid" src={Assets.Avatar} />
            </div>
            <div className="ps_12">
              <div className="profile-title">{user.first_name} {user.last_name}</div>
              <div className="manage-profile">Manage Profile</div>
            </div>
            <div className="ps_12">
              <DropdownIcon />
            </div>
          </div>
        </div>
      </div>

      <ProfileModal user={user} show={modalShow} modalShow={modalShow} onHide={() => setModalShow(false)} setModalShow={setModalShow}/>
    </div>
          <NotificationModal show={notifmodalShow} onHide={() => SetNotifModalShow(false)} />
</>
  );
};

export default TopBar;
