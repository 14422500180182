import { apiSlice } from "../../api/ApiSlice"

export const ProductApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getallproduct:builder.query({
            query: (id) => ({
                url: `/product/all?per_page=20&page=1&order_by=created_at&order=-1&sub_category_id=${id}`,
                providesTags: ['Product'],
            }),
        }),
        allproduct:builder.query({
            query: () => ({
                url: `/product/all?per_page=20&page=1&order_by=created_at&order=-1`,
                providesTags: ['Product'],
            }),
        }),
        getproductdetails:builder.query({
            query: (id) => ({
                url: `/product/show/${id}`,
            }),
        }),
    })
})

export const {
    useGetallproductQuery,
    useGetproductdetailsQuery,
    useAllproductQuery
} = ProductApiSlice