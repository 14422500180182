import { configureStore } from "@reduxjs/toolkit";
import UserReducer from '../reducers/UserSlice/UserSlice'
import { apiSlice } from "../api/ApiSlice";
import CountryReducer from '../reducers/CountrySlice/CountrySlice'
import StateReducer from '../reducers/StateSlice/StateSlice'
import CityReducer from '../reducers/CitySlice/CitySlice'
import CategoryReducer from '../reducers/CategorySlice/CategorySlice'
import ProductReducer from '../reducers/ProductSlice/ProductSlice'
import ShippingReducer from '../reducers/ShippingSlice/ShippingSlice'
import ReportReducer from '../reducers/ReportSlice/ReportSlice'


export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]:apiSlice.reducer,
        User:UserReducer,
        Country:CountryReducer,
        State:StateReducer,
        City:CityReducer,
        Category:CategoryReducer,
        Product:ProductReducer,
        Shipping:ShippingReducer,
        Report:ReportReducer
    },
    middleware:getDefaultMiddleware=> getDefaultMiddleware().concat(apiSlice.middleware),
    devTools:true
})