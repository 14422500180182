import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import { ToastContainer, toast } from 'react-toastify';

const BuyerPagesLayout = (props) => {
  return (
    <>
                  <ToastContainer
              hideProgressBar={true}
              />
      <Navbar isShow={props.isShow} isDontShow={props.isDontShow}/>
      <div className="main-section">{props.children}</div>
      <Footer />
    </>
  );
};

export default BuyerPagesLayout;
