import React, { useState, useEffect } from "react";
import { Assets } from "../../../constant/images";
import { NavLink, useNavigate,useLocation } from "react-router-dom";
import { CameraIcon } from "../../../utils/svg";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import {
  SPECIAL_CHARACTER_ARRAY,
  SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
  VALIDATIONS,
  VALIDATIONS_TEXT,
  EXCLUDE_NUMBER_INPUT,
  EXCLUDE_ALPHABETS_SPECIAL_CHAR,
  SPECIAL_CHARACTER_NUMBER_ARRAY,
} from "../../../constant/app-constants";
import {useDispatch,useSelector } from "react-redux";
import { useGetcountryQuery } from "../../../redux/reducers/CountrySlice/CountryApiSlice";
import { CountryActionCalls } from "../../../redux/reducers/CountrySlice/CountrySlice";
import { useGetcityQuery } from "../../../redux/reducers/CitySlice/CityApiSlice";
import { useGetstateQuery } from "../../../redux/reducers/StateSlice/StateApiSlice";
import { selectCurrentUser } from "../../../redux/reducers/UserSlice/UserSlice";
import { useAddshippingMutation,useUpdateshippingMutation } from "../../../redux/reducers/ShippingSlice/ShippingApiSlice";
import {toast} from "react-toastify";


const BuyerAddressEdit = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const dispatch = useDispatch();
  const location = useLocation();
  let shipping_id;
  if(location.state){
    shipping_id = location.state.sid;
  }
  const [addshipping] = useAddshippingMutation();
  const [updateshipping] = useUpdateshippingMutation();
  const user = useSelector(selectCurrentUser)
  const[countryId,setCountryId] = useState(null);
  const[stateId,setStateId] = useState(null);
  const { data: countries, isLoading: countryLoading } = useGetcountryQuery();
  const { data: states, isLoading: stateLoading, isFetching: stateFetching } = useGetstateQuery(countryId);
  const { data: cities, isLoading: cityLoading, isFetching: cityFetching } = useGetcityQuery(stateId);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);

  const getCountry = async () => {
    try {
      if (!countryLoading) {
        dispatch(CountryActionCalls.getCountry(countries));
        setCountry(countries?.data || []);
      }
    } catch (e) {
      console.log(e);
    }
  };
  
  const handleCountrySelect = (id) => {
    setCountryId(id);
  };
  
  const handleStateSelect = (id) => {
    setStateId(id);
  };
  
  useEffect(() => {
    if (states?.data) {
      setState(states?.data || []);
    }
  }, [states?.data, stateFetching]);
  
  useEffect(() => {
    if (cities?.data) {
      setCity(cities?.data || []);
    }
  }, [cities?.data, cityFetching]);
  
  useEffect(() => {
    getCountry();
  }, [countryLoading]);

  const navigate = useNavigate();
  const onSubmit = async (data) => {
    const userData = {
      name:data.name,
      email_address:data.email,
      phone: data.phone,
      country_id: data.country,
      state_id:data.state,
      city_id: data.city,
      zip_code: data.zip_code,
      address: data.address,
  };
  try{
      if(shipping_id){
        await updateshipping({id: shipping_id, credentials:userData}  )
        toast.success('Shipping address updated!');
      } else{
        await addshipping( userData ).unwrap()
        toast.success('Shipping address added!');
        
      }
      
      navigate("/buyer-address");

  }catch(e){
      console.log(e);
      toast.error(`${e.data.error}`);
  }
    
  };

  useEffect(() => {
    reset({
      name: user.first_name +" "+ user.last_name,
      email: user.email,
      phone: user.phone,
      address: user.address,
      country: user.country?._id,
      city: user.city?._id,
      zip_code: user.zip_code,
      state: user.state?._id
    });
  }, []);

  return (
    <>
      <div className="main-buyer-address-edit">
        <div className="top-heading-edit-div">
          <div className="heading-div">
            <h4>Shipping Address</h4>
          </div>
        </div>

        <div className="buyer-account-content">
          {/* <div className="buyer-avatar">
            <img
              src={
                profilePic.file_url ? profilePic.file_url : Assets.BuyerAvatar
              }
              alt=""
              className="w-100 h-100 img-upload"
            />
          </div>
          <div className="camera-div">
            <label htmlFor="img-upload" className="file-upload">
              <input
                type="file"
                id="img-upload"
                name="avatar"
                className="d-none"
                onChange={(e) =>
                  setProfilePic({
                    file: e.target.files[0],
                    file_url: URL.createObjectURL(e.target.files[0]),
                  })
                }
              />

              <CameraIcon />
            </label>
          </div> */}
          {/* <div className="buyer-name">
            <h2>Sidney Wilams</h2>
          </div> */}

          <div className="buyer-contact-fields">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={6}>
                  <FloatingLabel controlId="floatingInput" label="Your Name">
                    <Form.Control
                      placeholder="Your Name"
                      type="text"
                      onKeyDown={(e) =>
                        EXCLUDE_NUMBER_INPUT.includes(e.key) &&
                        e.preventDefault()
                      }
                      maxLength={VALIDATIONS.NAME}
                      {...register("name", {
                        maxLength: {
                          value: VALIDATIONS.NAME,
                          message: VALIDATIONS_TEXT.NAME_MAX,
                        },
                        pattern: {
                          value: /^[a-zA-Z\s]*$/,
                          message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS,
                        },
                        required: {
                          value: true,
                          message: VALIDATIONS_TEXT.NAME_REQUIRED,
                        },
                      })}
                    />
                    {errors.name && (
                      <small className="text-red">{errors.name.message}</small>
                    )}
                  </FloatingLabel>
                </Col>
                {/* <Form.Group
                  as={Col}
                  controlId="formGridEmail"
                  className="form-group"
                >
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Sidney Wilams"
                    onKeyDown={(e) =>
                      EXCLUDE_NUMBER_INPUT.includes(e.key) && e.preventDefault()
                    }
                    maxLength={VALIDATIONS.NAME}
                    {...register("name", {
                      maxLength: {
                        value: VALIDATIONS.NAME,
                        message: VALIDATIONS_TEXT.NAME_MAX,
                      },
                      pattern: {
                        value: /^[a-zA-Z\s]*$/,
                        message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS,
                      },
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.NAME_REQUIRED,
                      },
                    })}
                  />
                  {errors.name && (
                    <small className="text-red">{errors.name.message}</small>
                  )}
                </Form.Group> */}

                <Col md={6}>
                  <FloatingLabel controlId="floatingInput" label="Phone Number">
                    <Form.Control
                      type="tel"
                      placeholder="+01-143553464"
                      onKeyDown={(e) => {
                        EXCLUDE_ALPHABETS_SPECIAL_CHAR.includes(
                          e.key.toUpperCase()
                        ) && e.preventDefault();
                      }}
                      maxLength={VALIDATIONS.PHONE_MAX}
                      {...register("phone", {
                        pattern: {
                          value:
                            /^[\+]?[(]?[0-9]{2}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                          message: VALIDATIONS_TEXT.INVALID_PHONE_FORMAT,
                        },
                        minLength: {
                          value: VALIDATIONS.PHONE_MIN,
                          message: VALIDATIONS_TEXT.PHONE_MIN,
                        },
                        maxLength: {
                          value: VALIDATIONS.PHONE_MAX,
                          message: VALIDATIONS_TEXT.PHONE_MAX,
                        },
                        required: {
                          value: true,
                          message: VALIDATIONS_TEXT.PHONE_REQUIRED,
                        },
                      })}
                    />
                    {errors.phone && (
                      <small className="text-red">{errors.phone.message}</small>
                    )}
                  </FloatingLabel>
                </Col>
                {/* <Form.Group
                  as={Col}
                  controlId="formGridEmail"
                  className="form-group"
                >
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="+01-143553464"
                    onKeyDown={(e) => {
                      EXCLUDE_ALPHABETS_SPECIAL_CHAR.includes(
                        e.key.toUpperCase()
                      ) && e.preventDefault();
                    }}
                    maxLength={VALIDATIONS.PHONE_MAX}
                    {...register("phone", {
                      pattern: {
                        value:
                          /^[\+]?[(]?[0-9]{2}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                        message: VALIDATIONS_TEXT.INVALID_PHONE_FORMAT,
                      },
                      minLength: {
                        value: VALIDATIONS.PHONE_MIN,
                        message: VALIDATIONS_TEXT.PHONE_MIN,
                      },
                      maxLength: {
                        value: VALIDATIONS.PHONE_MAX,
                        message: VALIDATIONS_TEXT.PHONE_MAX,
                      },
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.PHONE_REQUIRED,
                      },
                    })}
                  />
                  {errors.phone && (
                    <small className="text-red">{errors.phone.message}</small>
                  )}
                </Form.Group> */}
              </Row>

              <Row>
                <Col md={6}>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Address Line 1"
                  >
                    <Form.Control
                      type="text"
                      placeholder="217 E Bondo Street, New York"
                      maxLength={VALIDATIONS.ADDRESS_MAX}
                      {...register("address", {
                        maxLength: {
                          value: VALIDATIONS.ADDRESS_MAX,
                          message: VALIDATIONS_TEXT.ADDRESS_MAX,
                        },
                        required: {
                          value: true,
                          message: VALIDATIONS_TEXT.ADDRESS_REQUIRED,
                        },
                      })}
                    />
                    {errors.address && (
                      <small className="text-red">
                        {errors.address.message}
                      </small>
                    )}
                  </FloatingLabel>
                </Col>
                {/* <Form.Group
                  as={Col}
                  controlId="formGridEmail"
                  className="form-group"
                >
                  <Form.Label>Address Line 1</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="217 E Bondo Street, New York"
                    maxLength={VALIDATIONS.ADDRESS_MAX}
                    {...register("address", {
                      maxLength: {
                        value: VALIDATIONS.ADDRESS_MAX,
                        message: VALIDATIONS_TEXT.ADDRESS_MAX,
                      },
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.ADDRESS_REQUIRED,
                      },
                    })}
                  />
                  {errors.address && (
                    <small className="text-red">{errors.address.message}</small>
                  )}
                </Form.Group> */}
                

                {/* <Form.Group
                  as={Col}
                  controlId="formGridEmail"
                  className="form-group"
                >
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Los Angeles"
                    maxLength={VALIDATIONS.STATE}
                    onKeyDown={(e) =>
                      SPECIAL_CHARACTER_NUMBER_ARRAY.includes(e.key) &&
                      e.preventDefault()
                    }
                    {...register("state", {
                      maxLength: {
                        value: VALIDATIONS.STATE,
                        message: VALIDATIONS_TEXT.STATE_MAX,
                      },
                      pattern: {
                        value: /^[a-zA-Z\s]*$/,
                        message:
                          VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS_STATE,
                      },
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.STATE_REQUIRED,
                      },
                    })}
                  />
                  {errors.state && (
                    <small className="text-red">{errors.state.message}</small>
                  )}
                </Form.Group> */}
                <Col md={6}>
                <div class="form-floating">
                  <select
                    class="form-select"
                    id="floatingSelect"
                    aria-label="Floating label select example"
                    {...register("country", {
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.COUNTRY_NAME_REQUIRED,
                      },
                      onChange: (e) => {
                        handleCountrySelect(e.target.value);
                      },
                    })}
                  >
                    {!countryLoading && country.map((data) => (
                      <option key={data.id} value={data._id}>
                      {data.name}
                    </option>
                    ))}
          
                  </select>
                  <label for="floatingSelect">Country</label>
                </div>
              </Col>
              </Row>

              <Row>

              
              <Col md={6}>
                <div class="form-floating">
                                <select
                                    class="form-select mb-3"
                                    id="floatingSelect"
                                    aria-label="Floating label select example"
                                    {...register("state", {
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.STATE_REQUIRED,
                                        },
                                        onChange: (e) => {
                                            handleStateSelect(e.target.value);
                                          },
                                    })}
                                >
                                    { state.length !== 0 && state.map((data) => (
                                        <option key={data.id} value={data._id}>
                                            {data.name}
                                        </option>
                                    ))}
                                </select>
                                <label for="floatingSelect">State *</label>
                            </div>
                </Col>
                {/* <Form.Group
                  as={Col}
                  controlId="formGridState"
                  className="form-group"
                >
                  <Form.Label>Country</Form.Label>
                  <Form.Select
                    // defaultValue={options[0]}
                    {...register("country", {
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.COUNTRY_NAME_REQUIRED,
                      },
                    })}
                  >
                    {options.map((data) => (
                      <option key={data.value} value={data.value}>
                        {data.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group> */}

                {/* <Form.Group
                  as={Col}
                  controlId="formGridEmail"
                  className="form-group"
                >
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="California"
                    maxLength={VALIDATIONS.CITY_NAME}
                    onKeyDown={(e) =>
                      SPECIAL_CHARACTER_NUMBER_ARRAY.includes(e.key) &&
                      e.preventDefault()
                    }
                    {...register("city", {
                      maxLength: {
                        value: VALIDATIONS.CITY_NAME,
                        message: VALIDATIONS_TEXT.CITY_MAX,
                      },
                      pattern: {
                        value: /^[a-zA-Z\s]*$/,
                        message:
                          VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS_CITY,
                      },
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.CITY_NAME_REQUIRED,
                      },
                    })}
                  />
                  {errors.city && (
                    <small className="text-red">{errors.city.message}</small>
                  )}
                </Form.Group> */}
                <Col md={6}>
                <div class="form-floating">
                                <select
                                    class="form-select mb-3"
                                    id="floatingSelect"
                                    aria-label="Floating label select example"
                                    {...register("city", {
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.CITY_REQUIRED,
                                        },
                                    })}
                                >
                                    {city.length !== 0 && city.map((data) => (
                                        <option key={data.id} value={data._id}>
                                            {data.name}
                                        </option>
                                    ))}
                                </select>
                                <label for="floatingSelect">City *</label>
                            </div>
                </Col>
              </Row>

              {/* <Form.Group
                as={Col}
                controlId="formGridEmail"
                className="form-group"
              >
                <Form.Label>Zip Code</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="12345"
                  onKeyDown={(e) => {
                    EXCLUDE_ALPHABETS_SPECIAL_CHAR.includes(
                      e.key.toUpperCase()
                    ) && e.preventDefault();
                  }}
                  maxLength={VALIDATIONS.ZIP_CODE}
                  {...register("zip_code", {
                    pattern: {
                      value: /^\d+$/,
                      message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS_ZIP,
                    },
                    maxLength: {
                      value: VALIDATIONS.ZIP_CODE,
                      message: VALIDATIONS_TEXT.ZIP_MAX,
                    },
                    required: {
                      value: true,
                      message: VALIDATIONS_TEXT.ZIP_CODE_REQUIRED,
                    },
                  })}
                />
                {errors.zip_code && (
                  <small className="text-red">{errors.zip_code.message}</small>
                )}
              </Form.Group> */}
              <Col>
                <FloatingLabel controlId="floatingInput" label="Zip Code">
                  <Form.Control
                    type="tel"
                    placeholder="12345"
                    onKeyDown={(e) => {
                      EXCLUDE_ALPHABETS_SPECIAL_CHAR.includes(
                        e.key.toUpperCase()
                      ) && e.preventDefault();
                    }}
                    maxLength={VALIDATIONS.ZIP_CODE}
                    {...register("zip_code", {
                      pattern: {
                        value: /^\d+$/,
                        message:
                          VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS_ZIP,
                      },
                      maxLength: {
                        value: VALIDATIONS.ZIP_CODE,
                        message: VALIDATIONS_TEXT.ZIP_MAX,
                      },
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.ZIP_CODE_REQUIRED,
                      },
                    })}
                  />
                  {errors.zip_code && (
                    <small className="text-red">
                      {errors.zip_code.message}
                    </small>
                  )}
                </FloatingLabel>
              </Col>

              <div className="button-div">
                <Button type="submit">Save</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyerAddressEdit;
