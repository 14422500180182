import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { SmallStar, StarReviewIcon } from "../../constant/svg";
import { Assets } from "../../constant/images";
import ReviewCard from "../ReviewCard/ReviewCard";

const RatingReview = () => {
  return (
    <div className="rating-and-review-section">
      <div className="rating-heading">Ratings & Reviews</div>
      <div className="d-flex align-items-center">
        <div>
          <StarReviewIcon />
        </div>
        <div className="star-reviews">4.9 (2130 reviews)</div>
      </div>
      {/* Card 1 Starts Here */}
      <ReviewCard
        image={Assets.Kate}
        Author="Kate Carter"
        date="Sep 20, 2022"
        rating="4.0"
        review="
        This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text."
      />
      {/* Card 1 Ends Here */}

      {/* Card 2 Starts Here */}
      <ReviewCard
        image={Assets.Kelly}
        Author="Kelly Jones"
        date="Sep 20, 2022"
        rating="4.0"
        review="
              This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text."
      />
      {/* Card 2 Ends Here */}

      {/* Card 3 Starts Here */}
      <ReviewCard
        image={Assets.Carly}
        Author="Carly West"
        date="Sep 20, 2022"
        rating="4.0"
        review="
              This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text."
      />
      {/* Card 3 Ends Here */}
    </div>
  );
};

export default RatingReview;
