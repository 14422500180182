import React, { useState ,useEffect} from "react";
import { Col, FloatingLabel, Row } from "react-bootstrap";
import { Assets } from "../../constant/images";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { SignupUpload } from "../../utils/svg";
import signupupload from "../../../src/assets/images/Auth/signupupload.png";
import { useForm } from "react-hook-form";
import { useGetprofileQuery } from "../../redux/reducers/UserSlice/UserApiSlice";
import { selectCurrentUser } from "../../redux/reducers/UserSlice/UserSlice";
import {TailSpin} from "react-loader-spinner";
import { useSelector } from "react-redux";
import {
  SPECIAL_CHARACTER_ARRAY,
  SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
  VALIDATIONS,
  VALIDATIONS_TEXT,
  EXCLUDE_NUMBER_INPUT,
  EXCLUDE_ALPHABETS_SPECIAL_CHAR,
  SPECIAL_CHARACTER_NUMBER_ARRAY,
} from "../../constant/app-constants";
import { OpenIcon, CloseIcon } from "../../utils/svg";
import PhoneInput from "react-phone-number-input";
const EditMyProfile = () => {
  const [options, setOptions] = useState([
    { value: "0", label: "United States" },
    { value: "1", label: "England" },
    { value: "2", label: "Canada" },
  ]);
  const [value, setValue] = useState();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const password = watch("password");
  const navigate = useNavigate();
  const onSubmit = (data) => {
    navigate("/otpverify-account");
  };
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [profilePic, setProfilePic] = useState({
    file: null,
    file_url: null,
  });
  const user = useSelector(selectCurrentUser)
  
  const { data: profile,isLoading,refetch} = useGetprofileQuery(user._id);
  useEffect(()=>{
    refetch();
    
  },[user])

  if (isLoading) return <div className="loader">
  <TailSpin
      height="30"
      width="30"
      color="#dbbb51"
      ariaLabel="tail-spin-loading"
      radius="1"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
  />
</div>
  if (!profile) return <div className="loader">
  <TailSpin
      height="30"
      width="30"
      color="#dbbb51"
      ariaLabel="tail-spin-loading"
      radius="1"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
  />
</div>

  const {data} = profile;
  return (
    <>
    <div className="all-profile-heading">My Profile</div>
    <div className="my-profile">
        <div className="profile-data">
        <div className="d-flex align-items-center justify-content-between">
            <div>
                <img className="img-fluid" src={Assets.ProfileImg}/>
            </div>
            <div>
                <NavLink to="/edit-my-profile" state={{profile:profile}} ><Button className="edit-profile-btn">Edit Profile</Button></NavLink>
            </div>
        </div>
        <div className="edit-profile-heading">The {data.store_name}</div>
        <div className="edit-profile-both-sides">
            <div className="edit-profile-left-side-text">Your Name</div><div className="edit-profile-right-side-text ">{data.first_name} {data.last_name}</div>
        </div>
        <div className="edit-profile-both-sides">
            <div className="edit-profile-left-side-text">Email Address</div><div className="edit-profile-right-side-text ">{data.email}</div>
        </div>
        <div className="edit-profile-both-sides">
            <div className="edit-profile-left-side-text">Phone Number</div><div className="edit-profile-right-side-text ">{data.phone}</div>
        </div>
        <div className="edit-profile-both-sides">
            <div className="edit-profile-left-side-text">Address</div><div className="edit-profile-right-side-text ">{data.address}</div>
        </div>
        <div className="edit-profile-both-sides">
            <div className="edit-profile-left-side-text">Country</div><div className="edit-profile-right-side-text ">{data.country?.name}</div>
        </div>
        <div className="edit-profile-both-sides">
            <div className="edit-profile-left-side-text">City</div><div className="edit-profile-right-side-text ">{data.city?.name}</div>
        </div>
        <div className="edit-profile-both-sides">
            <div className="edit-profile-left-side-text">Zipcode</div><div className="edit-profile-right-side-text ">{data.zip_code}</div>
        </div>
        </div>
    </div>
    </>
  );
};

export default EditMyProfile;
