import React from "react";
import { BackIcon } from "../../utils/svg";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Assets } from "../../constant/images";
import { NavLink } from "react-router-dom";

const ReturnOrder = () => {
  return (
    <>
      <div className="main-return-order">
        <Container>
          <Row>
            <div className="orderid-status-div">
              <div className="orderid-div">
                <div className="back-icon-div">
                  <NavLink to="/my-orders">
                    <BackIcon />
                  </NavLink>
                </div>
                <div className="order-id">
                  <h5>Return Order</h5>
                </div>
              </div>
            </div>
          </Row>
          <div className="product-total-div">
            <Row>
              <Col lg={6}>
                <p>Product</p>
              </Col>
              <Col lg={2}>
                <p>Unit Price</p>
              </Col>
              <Col lg={2} className="offset-lg-2">
                <p>Total</p>
              </Col>
            </Row>
          </div>
          <div className="product-detail-amount">
            <Row>
              <Col lg={6}>
                <div className="order-card-wrapper">
                    <div className="">
                    <Form.Check type="checkbox" aria-label="option 1" className="radio-button" />
                    </div>
           
                  <div className="order-img-div">
                    <img className="img-fluid" src={Assets.Order1} />
                  </div>
                  <div className="order-info">
                    <p>
                      Engraved Gucci Watch <span>x2</span>
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={2} className="my-auto">
                <div className="amount-div">
                  <p>$120.20</p>
                </div>
              </Col>
              <Col lg={2} className="my-auto offset-lg-2">
                <div className="amount-div">
                  <p>$240.40</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="order-card-wrapper">
                    <div className="">
                    <Form.Check type="checkbox" aria-label="option 1" className="radio-button" />
                    </div>
           
                  <div className="order-img-div">
                    <img className="img-fluid" src={Assets.Order1} />
                  </div>
                  <div className="order-info">
                    <p>
                      Engraved Gucci Watch <span>x2</span>
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={2} className="my-auto">
                <div className="amount-div">
                  <p>$120.20</p>
                </div>
              </Col>
              <Col lg={2} className="my-auto offset-lg-2">
                <div className="amount-div">
                  <p>$240.40</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="order-card-wrapper">
                    <div className="">
                    <Form.Check type="checkbox" aria-label="option 1" className="radio-button" />
                    </div>
           
                  <div className="order-img-div">
                    <img className="img-fluid" src={Assets.Order1} />
                  </div>
                  <div className="order-info">
                    <p>
                      Engraved Gucci Watch <span>x2</span>
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={2} className="my-auto">
                <div className="amount-div">
                  <p>$120.20</p>
                </div>
              </Col>
              <Col lg={2} className="my-auto offset-lg-2">
                <div className="amount-div">
                  <p>$240.40</p>
                </div>
              </Col>
            </Row>
          </div>
          
          <div className="main-buttons-div">
            <div className=" buttons-div">
                <div className="right-div">
                <NavLink to="/my-orders/return-orders/details">Proceed</NavLink>
                </div>
              
            </div>
        
          </div>
        </Container>
      </div>
    </>
  );
};

export default ReturnOrder;
