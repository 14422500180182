import { apiSlice } from "../../api/ApiSlice"

export const CityApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getcity:builder.query({
            query: (id) => ({
                url: `city?state_id=${id}`,
                providesTags: ['State'],
            }),
        }),
    })
})

export const {
    useGetcityQuery
} = CityApiSlice