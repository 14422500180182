import {React, useState} from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import {Assets} from "../../constant/images";
import {Col, InputGroup, Row} from "react-bootstrap";
import {TailSpin} from "react-loader-spinner";
import {
    AllHome,
    Basket,
    BuyerDropdown,
    BuyerDropdownProfile,
    CategoryCrossIcon,
    ChatMessageIcon,
    DropdownIcon,
    Heart,
    MegamenuRightIcon,
    MenuIcon,
    NotificationBuyerIcon,
    SearchIcon,
} from "../../constant/svg";
import {NavLink} from "react-router-dom";
import Dropdown from "react-multilevel-dropdown";
import NotificationModal from "../Modals/NotificationModal";
import DropdownMenu from "./DropdownMenu";
import {useGetallcategoryQuery} from '../../redux/reducers/CategorySlice/CategoryApiSlice'
import { CategoryActionCalls } from "../../redux/reducers/CategorySlice/CategorySlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";


const NavbarHeader = ({isShow, isDontShow}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [notifmodalShow, SetNotifModalShow] = useState(false);
    const dispatch = useDispatch();
    const { data: categories, isLoading: categoryLoading } = useGetallcategoryQuery();
    const[allCategory,setAllCategory] = useState([]);

    const getAllCategories= async()=>{
        try{
            if (!categoryLoading) {
                dispatch(CategoryActionCalls.GetAllCategory({...categories}));
                setAllCategory(categories?.data || []);
              }

        }catch(e){
            console.log(e);
        }
    }

    useEffect(()=>{
        getAllCategories();

    },[categoryLoading])

    // console.log(allCategory);

    return (
        <>
            <div className="main-section navbar-bg">
                <Container>
                    {["md"].map((expand) => (
                        <Navbar key={expand} expand={expand} className="navbarheader">
                            <Container fluid>
                                <NavLink to="/home">
                                    <Navbar.Brand>
                                        <img src={Assets.Logo}/>
                                    </Navbar.Brand>
                                </NavLink>
                                <Navbar.Toggle
                                    aria-controls={`offcanvasNavbar-expand-${expand}`}
                                />
                                <Navbar.Offcanvas
                                    id={`offcanvasNavbar-expand-${expand}`}
                                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                    placement="end"

                                >
                                    <Offcanvas.Header closeButton>
                                        <Offcanvas.Title
                                            id={`offcanvasNavbarLabel-expand-${expand}`}
                                        >
                                            <NavLink to="/home">
                                                <Navbar.Brand>
                                                    <img src={Assets.Logo}/>
                                                </Navbar.Brand>
                                            </NavLink>
                                        </Offcanvas.Title>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                        <Nav className="justify-content-end flex-grow-1 pe-3">
                                            <InputGroup className="search-bar">
                                                <Form.Control
                                                    placeholder="I'm searching for..."
                                                    aria-label="Recipient's username"
                                                    aria-describedby="basic-addon2"
                                                />
                                                <InputGroup.Text id="basic-addon2">
                                                    <span className="cursor-pointer"> <SearchIcon/> </span>
                                                </InputGroup.Text>
                                            </InputGroup>
                                            {/* <div>
                        <NavLink to="/getstarted">
                          <Button className="signin-btn ms_16">
                            Sign in
                          </Button>
                        </NavLink>
                      </div> */}
                                            <div>
                                                {isShow && (
                                                    <NavLink to="/">
                                                        <Button className="signin-btn ms_16">
                                                            Sign in
                                                        </Button>
                                                    </NavLink>
                                                )}
                                            </div>
                                            <NavLink to="/wishlist">
                                                <div className="heart-container ms_16">
                                                    <Heart/>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/cart-screen">
                                                <div className="heart-container ms_16">
                                                    <Basket/>
                                                </div>
                                            </NavLink>
                                            {/* <div className="heart-container ms_16">
                        <ChatMessageIcon />
                      </div> */}
                                            {isDontShow && (
                                                <div className="heart-container ms_16">
                                                    <ChatMessageIcon/>
                                                </div>
                                            )}
                                            <div
                                                className="heart-container ms_16 cursor-link"
                                                onClick={() => SetNotifModalShow(true)}
                                            >
                                                <NotificationBuyerIcon/>
                                            </div>
                                            {isDontShow && (
                                                <NavLink to="/buyer-account-details">
                                                    <div
                                                        className="buyer-navbar-profile ms_16 d-flex align-items-center justify-content-between">
                                                        <div>
                                                            <BuyerDropdown/>
                                                        </div>
                                                        <div className="img-box">
                                                            <img src={Assets.BuyerProfilePic}/>
                                                        </div>
                                                    </div>
                                                </NavLink>
                                            )}
                                        </Nav>
                                    </Offcanvas.Body>
                                </Navbar.Offcanvas>
                            </Container>
                        </Navbar>
                    ))}
                    <div className="divider">
                        <hr/>
                    </div>
                    <div className="header-bottombar">
                        <div className="d-flex align-items-center">
                            <div>
                                <Button className="category-btn" onClick={handleShow}>
                                    <MenuIcon/> Category
                                </Button>
                            </div>
                            <div className="menu-options d-none d-lg-block">
                                <ul>
                                {allCategory.length !== 0 ? allCategory.map((item,index)=>{
                                return(
                                        <li className="nav-multidropdown">
                                        {item.name}   
                                        {item.sub_categories.length !== 0 ?
                                        <>
                                        <DropdownIcon  />
                                        <DropdownMenu sub={item.sub_categories} category_id={item._id} name={item.name} />
                                        </>
                                        :
                                        null}
                                        
                                    </li>
                                )})
                                :<div className="loader">
                                <TailSpin
                                    height="30"
                                    width="30"
                                    color="#dbbb51"
                                    ariaLabel="tail-spin-loading"
                                    radius="1"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                            </div>}
                                    
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            <div>
                <Offcanvas
                    show={show}
                    onHide={handleClose}
                    className="category-btn-offcanvas"
                >
                    <Offcanvas.Body>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="category-heading">All Categories</div>
                            <div onClick={handleClose}>
                                <CategoryCrossIcon/>
                            </div>
                        </div>
                        <div className="boxes-section">
                            {allCategory.length !== 0 ? allCategory.map((item,index)=>{
                                return(
                                    <div key={index} className="category-boxes">{item.name}</div>
                                )
                            }) : <div className="loader">
                            <TailSpin
                                height="30"
                                width="30"
                                color="#dbbb51"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            />
                        </div> }
                           
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
            <NotificationModal
                show={notifmodalShow}
                onHide={() => SetNotifModalShow(false)}
            />
        </>
    );
};

export default NavbarHeader;
