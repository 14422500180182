import { createSlice } from "@reduxjs/toolkit";

const initialState={
    Shipping:[],
}

export const ShippingSlice = createSlice({
    name:'Shipping',
    initialState,
    reducers:{},
})

export default ShippingSlice.reducer; 