import React from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { NavLink } from "react-router-dom";
const ReturnOrderModal = (props) => {
  return (
    <>
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="main-return-modal"
    >
        <div className="main-heading">Return Not Available</div>
        <p>It has exceeded the time period of our returns policy.</p>
        <div className="mt_30 d-flex justify-content-end">
            <Button onClick={props.onHide} className="main-btn">Okay</Button>
        
        </div>

    </Modal>
    </>
  );
};

export default ReturnOrderModal;
