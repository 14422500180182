import React, { useState } from "react";
import { BackIcon, DownloadIcon } from "../../utils/svg";
import { Container, Row, Col } from "react-bootstrap";
import { Assets } from "../../constant/images";
import { NavLink, useLocation } from "react-router-dom";
import Pdf from "react-to-pdf";

const ref = React.createRef();
const BuyerTransactionDetail = () => {
  const location = useLocation();
  const [transactionDetails, setTransactionDetails] = useState(
    location.state.data
  );

  const returnStatusColor = (status) => {
    if (status == "completed") {
      return "transaction-green-color";
    } else {
      return "transaction-red-color";
    }
  };

  const returnAmountStatusColor = (status) => {
    if (status == "completed") {
      return "transaction-red-color";
    } else {
      return "transaction-green-color";
    }
  };

  return (
    <>
      <div className="main-transaction-detail">
        <Container>
          <Row>
            <div className="orderid-download-div">
              <div className="orderid-div">
                <div className="back-icon-div">
                  <NavLink to="/transaction-history">
                    <BackIcon />
                  </NavLink>
                </div>
                <div className="order-id">
                  <h5>
                    Order ID <span>{transactionDetails.id}</span>
                  </h5>
                </div>
              </div>
              <div className="download-div">
                  <Pdf targetRef={ref} filename="code-example.pdf">
                    {/* <a href="" download></a> */}
                    {({ toPdf }) => (
                      <button onClick={toPdf}>
                        <DownloadIcon />
                        Download
                      </button>
                    )}
                  </Pdf>
              </div>
            </div>
          </Row>

          <Row>
            <Col>
              <div className="border-bottomm"></div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="order-detail-div" ref={ref}>
                <ul>
                  <li>
                    <span>Invoice ID:</span>
                    <span>{transactionDetails.invoice_id}</span>
                  </li>
                  <li>
                    <span>Date & Time:</span>
                    <span>{transactionDetails.date}</span>
                  </li>
                  <li>
                    <span>Payment Method:</span>
                    <div className="master-div">
                      <span>{transactionDetails.card_type}</span>
                      <span>{transactionDetails.card_number}</span>
                    </div>
                  </li>
                  <li>
                    <span>Amount:</span>
                    <span
                      className={returnAmountStatusColor(
                        transactionDetails.status
                      )}
                    >
                      {transactionDetails.amount}
                    </span>
                  </li>
                  <li>
                    <span>Status:</span>
                    <span
                      className={returnStatusColor(transactionDetails.status)}
                    >
                      {transactionDetails.status}
                    </span>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default BuyerTransactionDetail;
