import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Assets } from "../../constant/images";
import { InputGroup } from "react-bootstrap";
import { useState } from "react";
import {
  AppleIcon,
  Basket,
  CategoryCrossIcon,
  ChatIcon,
  Copyright,
  DropdownIcon,
  FacebookIcon,
  FacebookNewIcon,
  Heart,
  InstagramIcon,
  InstaNewIcon,
  LinkedIcon,
  MenuIcon,
  SearchIcon,
  TwitterIcon,
  TwitterNewIcon,
} from "../../constant/svg";
import { Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";


const Footer = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="footer-bg main-section">
        <Container>
          <Row>
            <Col md={3}>
              <div className="footer-title">Quick Links</div>
              <div className="title-type">
                <NavLink to="/product-listing">Home & living</NavLink>
              </div>
              <div className="title-type">
                <NavLink to="/buyer-product-listing">Clothing & Fashion</NavLink>
              </div>
              <div className="title-type">
                <NavLink to="/buyer-product-listing">Health & Beauty</NavLink>
              </div>
              <div className="title-type">
                <NavLink to="/buyer-product-listing">Techniques & Devices</NavLink>
              </div>
              <div className="title-type">
                <NavLink to="/buyer-product-listing">Toys & Handicrafts</NavLink>
              </div>
              <div className="title-type">
                <NavLink to="/buyer-product-listing">Sports & Training</NavLink>
              </div>
            </Col>
            <Col md={3}>
              <div className="footer-title">Policies</div>
              <div className="title-type"><NavLink to="/buyer-terms">Terms and Conditions</NavLink></div>
              <div className="title-type"><NavLink to="/buyer-privacy">Privacy Policy</NavLink></div>
              <div onClick={handleShow} className="title-type">Sitemap</div>
              <div className="title-type"><NavLink to="/">Sell with Us</NavLink></div>
            </Col>
            <Col md={3}>
              <div className="footer-title">Contact</div>
              <div className="title-type"><NavLink to="/buyer-contact-us">Contact Us</NavLink></div>
            </Col>
            <Col md={3}>
              <div className="d-flex justify-content-start justify-content-sm-end mt-4 mt-md-0">
                <div>
                  <div className="download-app-heading">
                    GET THE APP :
                  </div>
                  <a target="_blank" href="https://play.google.com/store/apps/details?id=in.myinnos.AppManager&hl=en&gl=US&pli=1">
                  <div className="download-app-btn">
                    <div className="d-flex align-items-center">
                      <AppleIcon />
                      <div className="ms-3">
                        <p>Download on the</p>
                        <h2>App Store</h2>
                      </div>
                    </div>
                  </div>
                  </a>
                </div>
              </div>
            </Col> 
          </Row>
          <Row>
            <div className="footer-bottom-hr"></div>
            <Col md={12}>
              <div className="d-block d-sm-flex align-items-center justify-content-between mb_40 ">
              <div className="footer-copyrights">© 2022 <b>Afrotierre.</b> All Rights Reserved</div>
              <div className="d-flex justify-content-start content-sm-end mt-4 mt-sm-0">
                <a href="https://twitter.com/i/flow/login" target="_blank">
                <div className="social-links-container">
                  <TwitterNewIcon/>
                </div>
                </a>
                <a href="https://www.facebook.com/" target="_blank">
                  <div className="social-links-container">
                    <FacebookNewIcon/>
                  </div>
                </a>
                <a href="https://www.instagram.com/accounts/login/" target="_blank">
                  <div className="social-links-container">
                    <InstaNewIcon/>
                  </div>
                </a>
              </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <div className="footer-bottom main-section">
        <Container>
          <Row>
            <Col md={4}>
              <div>
                <Copyright />
                <span className="ms-2 copyright-text">
                  noon. All Rights Reserved
                </span>
              </div>
            </Col>
            <Col md={8}>
              <div className="d-flex justify-content-end align-items-center">
                <ul>
                  <li className="ms-0">
                    <NavLink to="">Privacy Policy</NavLink>
                  </li>
                  <li>
                    <NavLink to="">Careers</NavLink>
                  </li>
                  <li>
                    <NavLink to="">Warranty Policy</NavLink>
                  </li>
                  <li>
                    <NavLink to="">Sell with us</NavLink>
                  </li>
                  <li>
                    <NavLink to="">Terms of Use</NavLink>
                  </li>
                  <li>
                    <NavLink to="">Consumer Rights</NavLink>
                  </li>
                </ul>
                <div>
                  <Button className="help-btn ms_50 d-flex justify-content-center align-items-center">
                    <ChatIcon />
                    <div className="ms-2">Help</div>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}
            <div>
        <Offcanvas
          show={show}
          onHide={handleClose}
          className="category-btn-offcanvas"
        >
          <Offcanvas.Body>
            <div className="d-flex align-items-center justify-content-between">
              <div className="category-heading">All Categories</div>
              <div onClick={handleClose}>
                <CategoryCrossIcon />
              </div>
            </div>
            <div className="boxes-section">
              <div className="category-boxes">Daily Deals</div>
              <div className="category-boxes">Top Promotions</div>
              <div className="category-boxes">New Arrival</div>
              <div className="category-boxes">Brands</div>
              <div className="category-boxes">Handmade</div>
              <div className="category-boxes">Home & Living</div>
              <div className="category-boxes">Clothing & Fashion</div>
              <div className="category-boxes">Health & Beauty </div>
              <div className="category-boxes">Techniques & Devices</div>
              <div className="category-boxes">Toys & Handicrafts</div>
              <div className="category-boxes">Sports & Training</div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default Footer;
