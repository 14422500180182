import { createSlice } from "@reduxjs/toolkit";

const initialState={
    City:[],
}

export const CitySlice = createSlice({
    name:'City',
    initialState,
    reducers:{},
})

export default CitySlice.reducer; 

export const selectCity = (state)=>state.City.city;