import React, { useState } from "react";
import Select, { components } from "react-select";
import { Assets } from '../../constant/images'

const options = [
  { value: "inprocess", label: "In Process" },
  { value: "dispatched", label: "Dispatched" },
  { value: "completed", label: "Completed" },
];


const customStyles = {
  control: (styles, { value }) => ({
    ...styles,
    backgroundColor: value === "inprocess" ? "blue" : value === "dispatched" ? "yellow" : "#EDF9FF",
    border: "none",
    width: "105px",
    height: "26px",
    fontSize: "12px",
    borderRadius: "20px",
    "&:focus": {
      border: "none",
      boxShadow: "none",
    },
    singleValue: (provided) => ({
      ...provided,
      color: 'red',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: 'red',
      '&:hover': {
        color: 'blue',
      },
    }),
  }),
};


const MainProjectStatus = ({
  orderDetail,
  selectedOption,
  setSelectedOption,
}) => {
  console.log(selectedOption);
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={Assets.SelectDropDown} alt="Dropdown Arrow" />
      </components.DropdownIndicator>
    );
  };
  return (
    <div className="">
      <Select
        components={{ DropdownIndicator }}
        isSearchable={false}
        value={selectedOption}
        onChange={(e) => setSelectedOption(e)}
        options={options}
        styles={customStyles}
      />
    </div>
  );
};

export default MainProjectStatus;
