import React from "react";
import {Container} from "react-bootstrap";
import {Assets} from "../constant/images";
import {Row, Col, Button} from "react-bootstrap";
import SelectRole from "./AuthComponents/SelectRole";
import {BackArrow} from "../constant/svg";
import {NavLink} from "react-router-dom";
import {ToastContainer, toast} from 'react-toastify';


const BackAuthLayout = (props) => {
    return (
        <>
           
            <div className="main-section">
                <Container>
                    <div className="auth-container">
                        <Row>
                            <Col md={6}>
                                <div>
                                    <img className="img-fluid" src={Assets.MainLogo}/>
                                </div>
                                <div>
                                    <hr/>
                                </div>
                                <div>
                                    <div className="d-flex align-items-center">
                                        {props.isShow &&
                                        <div className="me-2"><NavLink to={props.link}><BackArrow/></NavLink></div>}
                                        <h2>{props.heading}</h2>
                                    </div>
                                    {props.children}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default BackAuthLayout;
