import {React, useState} from "react";
import {Container} from "react-bootstrap";
import {Assets} from "../constant/images";
import {Row, Col, Button, Form} from "react-bootstrap";
import SelectRole from "./AuthComponents/SelectRole";
import OtpInput from "react-otp-input";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import {Navigate, NavLink, useNavigate} from "react-router-dom";
import SocialLogin from "./AuthComponents/SocialLogin";
import {toast} from "react-toastify";
import {TailSpin} from "react-loader-spinner";
import {OpenIcon, CloseIcon} from "../utils/svg";
import {
    SPECIAL_CHARACTER_ARRAY,
    SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
    VALIDATIONS,
    VALIDATIONS_TEXT,
    EXCLUDE_NUMBER_INPUT,
    EXCLUDE_ALPHABETS_SPECIAL_CHAR,
    SPECIAL_CHARACTER_NUMBER_ARRAY,
} from "../constant/app-constants";
import {useForm} from "react-hook-form";
import { useLocation } from 'react-router-dom';
import { useDispatch,useSelector } from "react-redux";
import {useResetpasswordMutation} from '../redux/reducers/UserSlice/UserApiSlice'
import { UserActionCalls } from "../redux/reducers/UserSlice/UserSlice";

const ResetPassword = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [resetpassword,{isLoading}] = useResetpasswordMutation();

    let email;
    
    if (location.state && location.state.email) {
    email = location.state.email;
    } 

    const {
        register,
        handleSubmit,
        watch,
        reset,
        setError,
        formState: {errors},
    } = useForm({
        mode: "onChange",
    });
    const password = watch("password");
    const onSubmit = async(data) => {

        const userData = {
            email,
            password: data.password,
            password_confirmation: data.password_confirmation,
        }
        try{
            const ResetUser = await resetpassword(userData).unwrap()
            // console.log(LoginUser);
            dispatch(UserActionCalls.ResetPassword({...ResetUser}))
            
            toast.success('Password Reset successfully!');
            navigate('/')
            

        }catch(e){
            console.log(e);
            toast.error(`${e.data.error}`);
        }
    };
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    return (
        <>
            <Row>
                <Col md={10}>
                    <h6><b>Reset your password,</b> Your new password must be different from the previously used
                        passwords</h6>
                </Col>
            </Row>
            <div className="new-hr">
                <hr/>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="signup-form">
                    <Row>
                        <Col md={6}>
                            <FloatingLabel controlId="floatingInput" label="New Password">
                                <Form.Control
                                    type={!showNewPassword ? "password" : "text"}
                                    placeholder="Enter your new password"
                                    maxLength={VALIDATIONS_TEXT.PASSWORD_MAX}
                                    {...register("password", {
                                        minLength: {
                                            value: VALIDATIONS.PASSWORD_MIN,
                                            message: VALIDATIONS_TEXT.PASSWORD_MIN,
                                        },
                                        maxLength: {
                                            value: VALIDATIONS.PASSWORD_MAX,
                                            message: VALIDATIONS_TEXT.PASSWORD_MAX,
                                        },
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                                        },
                                        pattern: {
                                            value: /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/,
                                            message: VALIDATIONS_TEXT.PASSWORD_FORMAT,
                                        },
                                    })}
                                />
                                {errors.password && (
                                    <small className="text-red error_from error_from">
                                        {errors.password.message}
                                    </small>
                                )}

                                <span className="iconfeildauth">
                    {showNewPassword ? (
                        <span
                            className="toggle-password"
                            onClick={() => setShowNewPassword(false)}
                        >
                        <OpenIcon/>
                      </span>
                    ) : (
                        <span
                            onClick={() => setShowNewPassword(true)}
                            className="toggle-password"
                        >
                        <CloseIcon/>
                      </span>
                    )}
                  </span>
                            </FloatingLabel>
                        </Col>

                        <Col md={6}>
                            <FloatingLabel controlId="floatingInput" label="Confirm Password">
                                <Form.Control
                                    type={!showConfirmPassword ? "password" : "text"}

                                    placeholder="Confirm new password"
                                    maxLength={VALIDATIONS_TEXT.PASSWORD_MAX}
                                    {...register("password_confirmation", {
                                        minLength: {
                                            value: VALIDATIONS.PASSWORD_MIN,
                                            message: VALIDATIONS_TEXT.PASSWORD_MIN,
                                        },
                                        maxLength: {
                                            value: VALIDATIONS.PASSWORD_MAX,
                                            message: VALIDATIONS_TEXT.PASSWORD_MAX,
                                        },
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.CONFIRM_PASSWORD_REQUIRED,
                                        },
                                        validate: (value) =>
                                            value === password ||
                                            "Password and Confirm Password must be same",
                                    })}
                                />
                                {errors.password_confirmation && (
                                    <small className="text-red error_from">
                                        {errors.password_confirmation.message}
                                    </small>
                                )}

                                <span className="iconfeildauth">
                    {showConfirmPassword ? (
                        <span
                            className="toggle-password"
                            onClick={() => setShowConfirmPassword(false)}
                        >
                        <OpenIcon/>
                      </span>
                    ) : (
                        <span
                            onClick={() => setShowConfirmPassword(true)}
                            className="toggle-password"
                        >
                        <CloseIcon/>
                      </span>
                    )}
                  </span>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <div>
                        {!isLoading ? 
                        <Button type="submit" className="auth-btn">Reset Password</Button>:
                        <div className="loader">
                                    <TailSpin
                                        height="30"
                                        width="30"
                                        color="#dbbb51"
                                        ariaLabel="tail-spin-loading"
                                        radius="1"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                    />
                                </div>
}
                    </div>
                </div>
            </Form>


        </>
    );
};

export default ResetPassword;
