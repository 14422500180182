import React, { useState } from "react";
import { Col, FloatingLabel, Nav, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { NavLink } from "react-router-dom";
import { ModalCrossIcon } from "../../utils/svg";
import Form from "react-bootstrap/Form";
import { Assets } from "../../constant/images";
import {
  DeleteBinIcon,
  HeartIcon,
  NegativeIcon,
  PositiveIcon,
} from "../../constant/svg";
import DeleteModal from "./DeleteModal";
const AddtoCartModal = (props) => {
  const [count, setCount] = useState(1);
  const [deletemodalShow,setDeleteModalShow] = useState(false);

  const incrementCount = () => {
    setCount(count + 1);
  };
  const decrementCount = () => {
    if (count > 0) setCount(count - 1);
  };
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="add-to-cart-modal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4> Cart</h4>
          </Modal.Title>
          <div className="close-div">
            <Button onClick={props.onHide}>
              <ModalCrossIcon />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="add-to-cart">
            <hr />
            <div className="content-section">
              <div className="d-block d-sm-flex justify-content-between">
                {/* first section starts here */}
                <div className="d-block d-sm-flex align-items-center">
                  <div className="productimg-container">
                    <img className="img-fluid" src={Assets.wallclock} />
                  </div>
                  <div className="ps-3">
                    <div className="product-title mt-3 mt-sm-0">
                      Black Minimalist Wall Clock
                    </div>
                    <div className="quantity-btn">
                      <div className="cursor-link" onClick={decrementCount}>
                        <NegativeIcon />
                      </div>
                      <div>{count}</div>
                      <div className="cursor-link" onClick={incrementCount}>
                        <PositiveIcon />
                      </div>
                    </div>
                  </div>
                </div>
                {/* first section ends here */}
                {/* second section starts here */}
                <div>
                  <div className="product-price">$120.20</div>
                  <div             onClick={() => {setDeleteModalShow(true)}}
 className="d-flex align-items-center">
                    <DeleteBinIcon />
                    <div className="delete-text">Delete</div>
                  </div>
                </div>
                {/* second section ends here */}
              </div>
            </div>
            <div className="mt_20">
              <hr />
            </div>
            <div className="content-section">
              <div className="also-recommend">Is also recommended</div>
              <div className="mb_30 d-flex justify-content-center">
                <Row>
                  <Col sm={4}>
                    <div >
                      <div className="product-card-container">
                        <img src={Assets.wallclock} />
                        <div className="card-title">Flower Clock</div>
                        <NavLink to="/wishlist">
                        <div className="white-circle">
                          <HeartIcon />
                        </div>
                        </NavLink>
                      </div>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="mt-5 mt-sm-0">
                      <div className="product-card-container">
                        <img src={Assets.wallclock} />
                        <div className="card-title">Flower Clock</div>
                        <NavLink to="/wishlist">
                        <div className="white-circle">
                          <HeartIcon />
                        </div>
                        </NavLink>
                      </div>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="mt-5 mt-sm-0">
                      <div className="product-card-container">
                        <img src={Assets.wallclock} />
                        <div className="card-title">Flower Clock</div>
                        <NavLink to="/wishlist">
                        <div className="white-circle">
                          <HeartIcon />
                        </div>
                        </NavLink>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="submit-div">
                <NavLink to="/home">
                <Button onClick={props.onHide}>Continue Shopping</Button>
                </NavLink>
              </div>
              <div className="cancel-div">
                <NavLink to="/checkout-screen1">
                <Button onClick={props.onHide}>Proceed to Checkout</Button>
                </NavLink>
              </div>
            </div>
          </div>
        </Modal.Body>
        <DeleteModal
            heading="Delete Product"
            type="delete this product"
            btntext="Delete"
            link="/products/product-details"
            display="onClick={()=>{props.onHide}}"
            show={deletemodalShow}
            onHide={() => setDeleteModalShow(false)}
          />
      </Modal>

    </>
  );
};

export default AddtoCartModal;
