import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { OpenIcon, CloseIcon } from "../../utils/svg";
import {
  SPECIAL_CHARACTER_ARRAY,
  SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
  VALIDATIONS,
  VALIDATIONS_TEXT,
  EXCLUDE_NUMBER_INPUT,
  EXCLUDE_ALPHABETS_SPECIAL_CHAR,
  SPECIAL_CHARACTER_NUMBER_ARRAY,
} from "../../constant/app-constants";
import {useChangepasswordMutation} from '../../redux/reducers/UserSlice/UserApiSlice'
import { UserActionCalls } from "../../redux/reducers/UserSlice/UserSlice";
import {useDispatch,useSelector } from "react-redux";
import {toast} from "react-toastify";
import { selectCurrentUser } from "../../redux/reducers/UserSlice/UserSlice";
import { useNavigate } from 'react-router-dom';

const BuyerChangePassword = () => {

  const user = useSelector(selectCurrentUser)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [changepassword] = useChangepasswordMutation();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const password = watch("password");

  const onSubmit = async (data) => {

    const userData = {
      email:user.email,
      current_password: data.currentpassword,
      password: data.password,
      password_confirmation: data.password_confirmation,
      
  };
  try{
      const UpdateUser = await changepassword(userData).unwrap()
      console.log(UpdateUser);
      dispatch(UserActionCalls.ChangePassword({...UpdateUser}))
      toast.success('Password changed successfully!');
      navigate('/')

  }catch(e){
      console.log(e);
      toast.error(`${e.data.error}`);
  }
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  return (
    <>
      <div className="main-buyer-changepassword">
        <div className="top-heading-edit-div">
          <div className="heading-div">
            <Row>
              <Col xs={12} sm={12} md={6}>
                <h4>Change Password</h4>
                <p>
                  Please enter your current password and your new password, then
                  confirm the new password to change it
                </p>
              </Col>
            </Row>
          </div>
        </div>

        <div className="buyer-contact-fields">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={6} className="position-realtive">
                <FloatingLabel controlId="floatingInput" label="Current Password">
                  <Form.Control
                    type={!showCurrentPassword ? "password" : "text"}
                    placeholder="Enter your old password"
                    maxLength={VALIDATIONS_TEXT.PASSWORD_MAX}
                    {...register("currentpassword", {
                      minLength: {
                        value: VALIDATIONS.PASSWORD_MIN,
                        message: VALIDATIONS_TEXT.PASSWORD_MIN,
                      },
                      maxLength: {
                        value: VALIDATIONS.PASSWORD_MAX,
                        message: VALIDATIONS_TEXT.PASSWORD_MAX,
                      },
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                      },
                      pattern: {
                        value: /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/,
                        message: VALIDATIONS_TEXT.PASSWORD_FORMAT,
                      },
                    })}
                  />
                  {errors.currentpassword && (
                    <small className="text-red error_from error_from">
                      {errors.currentpassword.message}
                    </small>
                  )}

                  <span className="iconfeild">
                    {showCurrentPassword ? (
                      <span
                        className="toggle-password"
                        onClick={() => setShowCurrentPassword(false)}
                      >
                        <OpenIcon />
                      </span>
                    ) : (
                      <span
                        onClick={() => setShowCurrentPassword(true)}
                        className="toggle-password"
                      >
                        <CloseIcon />
                      </span>
                    )}
                  </span>
                </FloatingLabel>
              </Col>

              {/* <Form.Group
                as={Col}
                controlId="formGridEmail"
                className="form-group"
              >
                <Form.Label>Old Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter your old password"
                  maxLength={VALIDATIONS_TEXT.PASSWORD_MAX}
                  {...register("currentpassword", {
                    minLength: {
                      value: VALIDATIONS.PASSWORD_MIN,
                      message: VALIDATIONS_TEXT.PASSWORD_MIN,
                    },
                    maxLength: {
                      value: VALIDATIONS.PASSWORD_MAX,
                      message: VALIDATIONS_TEXT.PASSWORD_MAX,
                    },
                    required: {
                      value: true,
                      message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                    },
                    pattern: {
                      value: /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/,
                      message: VALIDATIONS_TEXT.PASSWORD_FORMAT,
                    },
                  })}
                />
                {errors.currentpassword && (
                  <small className="text-red error_from error_from">
                    {errors.currentpassword.message}
                  </small>
                )}
              </Form.Group> */}

              <Col md={6} className="position-realtive">
                <FloatingLabel controlId="floatingInput" label="New Password">
                  <Form.Control
                    type={!showNewPassword ? "password" : "text"}
                    placeholder="Enter your new password"
                    maxLength={VALIDATIONS_TEXT.PASSWORD_MAX}
                    {...register("password", {
                      minLength: {
                        value: VALIDATIONS.PASSWORD_MIN,
                        message: VALIDATIONS_TEXT.PASSWORD_MIN,
                      },
                      maxLength: {
                        value: VALIDATIONS.PASSWORD_MAX,
                        message: VALIDATIONS_TEXT.PASSWORD_MAX,
                      },
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                      },
                      pattern: {
                        value: /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/,
                        message: VALIDATIONS_TEXT.PASSWORD_FORMAT,
                      },
                    })}
                  />
                  {errors.password && (
                    <small className="text-red error_from error_from">
                      {errors.password.message}
                    </small>
                  )}

                  <span className="iconfeild">
                    {showNewPassword ? (
                      <span
                        className="toggle-password"
                        onClick={() => setShowNewPassword(false)}
                      >
                        <OpenIcon />
                      </span>
                    ) : (
                      <span
                        onClick={() => setShowNewPassword(true)}
                        className="toggle-password"
                      >
                        <CloseIcon />
                      </span>
                    )}
                  </span>
                </FloatingLabel>
              </Col>

              {/* <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter your new password"
                  maxLength={VALIDATIONS_TEXT.PASSWORD_MAX}
                  {...register("password", {
                    minLength: {
                      value: VALIDATIONS.PASSWORD_MIN,
                      message: VALIDATIONS_TEXT.PASSWORD_MIN,
                    },
                    maxLength: {
                      value: VALIDATIONS.PASSWORD_MAX,
                      message: VALIDATIONS_TEXT.PASSWORD_MAX,
                    },
                    required: {
                      value: true,
                      message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                    },
                    pattern: {
                      value: /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/,
                      message: VALIDATIONS_TEXT.PASSWORD_FORMAT,
                    },
                  })}
                />
                {errors.password && (
                  <small className="text-red error_from error_from">
                    {errors.password.message}
                  </small>
                )}
              </Form.Group> */}
            </Row>

            <Row>
              <Col md={6} className="position-realtive">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Confirm Password"
                >
                  <Form.Control
                    type={!showConfirmPassword ? "password" : "text"}
                    placeholder="Confirm new password"
                    maxLength={VALIDATIONS_TEXT.PASSWORD_MAX}
                    {...register("password_confirmation", {
                      minLength: {
                        value: VALIDATIONS.PASSWORD_MIN,
                        message: VALIDATIONS_TEXT.PASSWORD_MIN,
                      },
                      maxLength: {
                        value: VALIDATIONS.PASSWORD_MAX,
                        message: VALIDATIONS_TEXT.PASSWORD_MAX,
                      },
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.CONFIRM_PASSWORD_REQUIRED,
                      },
                      validate: (value) =>
                        value === password ||
                        "Password and Confirm Password must be same",
                    })}
                  />
                  {errors.password_confirmation && (
                    <small className="text-red error_from">
                      {errors.password_confirmation.message}
                    </small>
                  )}

                  <span className="iconfeild">
                    {showConfirmPassword ? (
                      <span
                        className="toggle-password"
                        onClick={() => setShowConfirmPassword(false)}
                      >
                        <OpenIcon />
                      </span>
                    ) : (
                      <span
                        onClick={() => setShowConfirmPassword(true)}
                        className="toggle-password"
                      >
                        <CloseIcon />
                      </span>
                    )}
                  </span>
                </FloatingLabel>
              </Col>

              {/* <Form.Group
                as={Col}
                controlId="formGridEmail"
                className="form-group col-lg-6"
              >
                <Form.Label>Confirm New Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm new password"
                  maxLength={VALIDATIONS_TEXT.PASSWORD_MAX}
                  {...register("password_confirmation", {
                    minLength: {
                      value: VALIDATIONS.PASSWORD_MIN,
                      message: VALIDATIONS_TEXT.PASSWORD_MIN,
                    },
                    maxLength: {
                      value: VALIDATIONS.PASSWORD_MAX,
                      message: VALIDATIONS_TEXT.PASSWORD_MAX,
                    },
                    required: {
                      value: true,
                      message: VALIDATIONS_TEXT.CONFIRM_PASSWORD_REQUIRED,
                    },
                    validate: (value) =>
                      value === password ||
                      "Password and Confirm Password must be same",
                  })}
                />
                {errors.password_confirmation && (
                  <small className="text-red error_from">
                    {errors.password_confirmation.message}
                  </small>
                )}
              </Form.Group> */}
            </Row>

            <div className="button-div">
              <Button type="submit">Submit</Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default BuyerChangePassword;
