import React from "react";
import { Assets } from "../../constant/images";
import ReviewCard from "../ReviewCard/ReviewCard";
import { StarReviewIcon } from "../../constant/svg";
const CompletedReview = () => {
  return (
    <>
      <div className="rating-and-review-section">
        <div className="rating-heading">Ratings & Reviews</div>

        {/* Card 1 Starts Here */}
        <ReviewCard
          image={Assets.Kate}
          Author="Kate Carter"
          date="Sep 20, 2022"
          rating="4.0"
          review="
        This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text."
        />
        {/* Card 1 Ends Here */}
      </div>
    </>
  );
};

export default CompletedReview;
