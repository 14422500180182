import { createSlice } from "@reduxjs/toolkit";

const initialState={
    State:[],
}

export const StateSlice = createSlice({
    name:'State',
    initialState,
    reducers:{},
})

export default StateSlice.reducer; 

export const selectState = (state)=>state.State.state;