import React from "react";
import {useNavigate} from "react-router-dom";
import {Button, Col, Form, Row} from "react-bootstrap";
import {SellerIcon, ShoppingCart} from "../../constant/svg";
import {Roles} from "../../constant/app-constants"
import {useForm} from "react-hook-form";

const SelectRole = () => {
        const navigate = useNavigate()
        const {
            register,
            handleSubmit,
            watch,
            reset,
            formState: {errors},
        } = useForm({
            mode: "onChange",
        });

        const onSubmit = (data) => {
            navigate('/signup', {state: {value: data}});

        }


        return (
            <div>
                <Row>
                    <Col md={8}>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className="select-role-form">
                                <div className="wrapper mb_20">
                                    <Form.Check
                                        value={Roles.buyer}
                                        name="role"
                                        type="radio"
                                        id="shopping"
                                        {...register("role", {
                                            required: {
                                                value: true,
                                                message: "Required",
                                            },
                                        })}
                                    />
                                    <label htmlFor="shopping">
                                        <div className="select-role">
                                            <div className="icon-container">
                                                <ShoppingCart/>
                                            </div>
                                            <div className="registered-as">
                                                Register as <b>Buyer</b>
                                            </div>
                                        </div>
                                    </label>
                                </div>

                                <div className="wrapper">
                                    <Form.Check
                                        value={Roles.seller}
                                        name="role"
                                        type="radio"
                                        id="brand-owner"
                                        {...register("role", {
                                            required: {
                                                value: true,
                                                message: "Required",
                                            },
                                        })}
                                    />
                                    <label htmlFor="brand-owner">
                                        <div className="select-role">
                                            <div className="icon-container">
                                                <SellerIcon/>
                                            </div>
                                            <div className="registered-as">
                                                Register as <b>Seller</b>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div>
                                <Button type="submit" className="auth-btn">Get Started</Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </div>
        );
    }
;

export default SelectRole;
