import React from "react";
import { FloatingLabel, Nav } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { NavLink } from "react-router-dom";
import { ModalCrossIcon } from "../../utils/svg";
import Form from "react-bootstrap/Form";
const AddNewModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="add-new-modal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4> Add New</h4>
          </Modal.Title>
          <div className="close-div">
            <Button onClick={props.onHide}>
              <ModalCrossIcon />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="signup-form">
              <FloatingLabel controlId="floatingInput" label="Account Title">
                <Form.Control type="text" placeholder="name@example.com" />
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="Account Number">
                <Form.Control type="text" placeholder="name@example.com" />
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="CCV">
                <Form.Control type="text" placeholder="name@example.com" />
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="Expiry Date">
                <Form.Control type="text" placeholder="name@example.com" />
              </FloatingLabel>
            </div>
          </Form>
          <div className="submit-div">
            <Button onClick={props.onHide}>Submit</Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddNewModal;
