import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import React from "react";
import { Button } from "react-bootstrap";
import { SliderNextBtn, SliderPrevBtn } from "../../constant/svg";
import { NavLink } from "react-router-dom";

const ShopNowSlider = (slidenavnext, slidenavprev) => {
  // console.log("...",slidenavnext)
  return (
    <div>
     <Swiper
        loop = {true}
        className="mySwiper"
        navigation={{
          nextEl: `#testnext`,
          prevEl: `#testprev`,
        }}
        modules={[Navigation]}
      >
         <SwiperSlide>
          <div className="shop-container">
            <div className="product-details">
              <h2>Handmade decorative teardrops</h2>
              <p>
                New arrivals from handmade masters, Ceramic and aluminum in
                bright yellow
              </p>
              <div>
                <NavLink to="/product-listing"><Button className="shop-btn">Shop Now</Button></NavLink>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="shop-container">
            <div className="product-details">
              <h2>Handmade decorative teardrops</h2>
              <p>
                New arrivals from handmade masters, Ceramic and aluminum in
                bright yellow
              </p>
              <div>
                <NavLink to="/product-listing"><Button className="shop-btn">Shop Now</Button></NavLink>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="shop-container">
            <div className="product-details">
              <h2>Handmade decorative teardrops</h2>
              <p>
                New arrivals from handmade masters, Ceramic and aluminum in
                bright yellow
              </p>
              <div>
                <NavLink to="/product-listing"><Button className="shop-btn">Shop Now</Button></NavLink>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="shop-container">
            <div className="product-details">
              <h2>Handmade decorative teardrops</h2>
              <p>
                New arrivals from handmade masters, Ceramic and aluminum in
                bright yellow
              </p>
              <div>
                <NavLink to="/product-listing"><Button className="shop-btn">Shop Now</Button></NavLink>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="shop-container">
            <div className="product-details">
              <h2>Handmade decorative teardrops</h2>
              <p>
                New arrivals from handmade masters, Ceramic and aluminum in
                bright yellow
              </p>
              <div>
                <NavLink to="/product-listing"><Button className="shop-btn">Shop Now</Button></NavLink>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <div className="navigation-btns">
          <div className="d-flex justify-content-between">
            <Button className="prev-next-btn" id="testprev">
              <SliderPrevBtn />
            </Button>
            <Button className="prev-next-btn" id="testnext">
              <SliderNextBtn />
            </Button>
          </div>
        </div>
      </Swiper> 
    </div>
  );
};

export default ShopNowSlider;
