import React from "react";
import { Form } from "react-bootstrap";
import {Row,Col} from "react-bootstrap";

const PushNotification = () => {
  return (
    <>
      <Row>
        <Col md={6}>
          <div className="d-flex align-items-center justify-content-between">
            <div className="push-notification-text">
              Turn on Push Notifications
            </div>
            <Form>
              <Form.Check type="switch" id="custom-switch" />
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PushNotification;
