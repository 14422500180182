import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { SmallStar, StarReviewIcon } from "../../constant/svg";
import { Assets } from "../../constant/images";

const ReviewCard = (props) => {
  return (
    <>
      <div className="review-card d-flex">
        <div className="author-img">
          <img className="img-fluid" src={props.image} />
        </div>

        <div className="review-card-details">
          <div className="d-flex align-items-center">
            <div className="review-author">{props.Author}</div>
            <div className="review-date">{props.date}</div>
          </div>
          <div className="d-flex align-items-center">
            <SmallStar />
            <div className="rating">{props.rating}</div>
          </div>
          <div className="review">
            {props.review}
          </div>
        </div>
      </div>
      {window.location.pathname !== '/orders/order-detail' && <hr />}
    </>
  );
};

export default ReviewCard;
