import { React, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import DeleteModal from "../../components/Modals/DeleteModal";
import { Assets } from "../../constant/images";
import { DeleteBin, NegativeIcon, PositiveIcon } from "../../constant/svg";

const CartScreen = () => {
  const [count, setCount] = useState(1);
  const [count2,setCount2] = useState(1);
  const [deletemodalShow,setDeleteModalShow]= useState(false)

  const incrementCount = () => {
    setCount(count + 1);
  };
  const decrementCount = () => {
    if (count > 0) setCount(count - 1);
  };


  const incrementCount2 = () => {
    setCount2(count2 + 1);
  };
  const decrementCount2 = () => {
    if (count2 > 0) setCount2(count2 - 1);
  };

  const CartItems = (props) => {
    return (
      <>
      <div className="d-block d-sm-flex justify-content-between">
        <div >
        <div className="cartitemscard d-flex align-items-center">
            <div className="cartimg-box">
              <img src={props.cartimg} />
            </div>
            <div className="ps_20">
              <div className="cart-item-title">Engraved Gucci Watch</div>
              <div className="d-flex align-items-center">
                <div className="main-price">$123</div>
                <div className="sub-price">
                  <strike>$150.45</strike>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
        <div className="quantity-btn text-end mb-5 mb-sm-0">
            <div className="cursor-pointer" onClick={props.func1}>
              <NegativeIcon />
            </div>
            <div>{props.counts}</div>
            <div className="cursor-pointer" onClick={props.func2}>
              <PositiveIcon />
            </div>
          </div>
        </div>
      </div>


      </>
    );
  };

  return (
    <div className="main-section">
      <Container>
        <div className="cart-empty-section">
          <div className="empty-msg">
            <div className="d-flex">
              <div>
                <img src={Assets.Cartempty} />
              </div>
              <div className="msg-text">
                Buy confidently with afrotierre Purchase Protection program for
                buyers, get a full refund in the rare case your item doesn't
                arrive, arrives damaged, or isn't as described. See eligibility
              </div>
            </div>
          </div>
        </div>
        <div className="cart-screen-section">
          <Row>
            <Col md={6}>
              <div className="d-block d-sm-flex align-items-center justify-content-between">
                <div className="no-of-items">2 Items in your cart</div>
                <div             onClick={() => setDeleteModalShow(true)}
                className="delete-txt mt-2 mb-2 mt-sm-0 mb-sm-0 ">
                  <span>
                    <DeleteBin />
                  </span>
                  Delete
                </div>
              </div>
              <Form className="mt_20 w-100">
                <div className="d-flex">
                <Form.Check
                  type="checkbox"
                  id="capital"
                  name="capital"
                />
                <label>{<CartItems cartimg={Assets.CartProduct1} counts={count} func2={incrementCount} func1={decrementCount} />}</label>
                </div>
                <div className="d-flex">
                <Form.Check
                  type="checkbox"
                  id="reverse"
                  name="reverse"
                />
                <label>{<CartItems cartimg={Assets.CartProduct2} counts={count2} func2={incrementCount2} func1={decrementCount2} />}</label>
                </div>
              </Form>
            </Col>
            <Col md={6}>
              <div className="total-item-section">
                <h4>Items (2) Total</h4>
                <hr className="subhr"></hr>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="item">Sub Total :</div>
                  <div className="total">$140</div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt_15">
                  <div className="item">Shipping:</div>
                  <div className="total">Free</div>
                </div>
                <div className="d-flex justify-content-end mt_15 est-time">
                  (Est time. 14-16 Nov)
                </div>
                <div className="totalhr">
                  <hr />
                </div>
                <div className="d-flex align-items-center justify-content-between mt_15">
                  <div className="item" style={{ fontWeight: 600 }}>
                    Total Amount:
                  </div>
                  <div className="total" style={{ fontWeight: 600 }}>
                    $140.45
                  </div>
                </div>
                <div>
                 <NavLink to="/checkout-screen1"> <Button className="proceed-btn">Proceed to Checkout</Button></NavLink>
                </div>
                <div>
                <NavLink to="/home"><Button className="continue-btn">Continue Shopping</Button></NavLink>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <DeleteModal
            heading="Delete Product"
            type="delete this product"
            btntext="Delete"
            link="/products/product-details"
            display="onClick={()=>{props.onHide}}"
            show={deletemodalShow}
            onHide={() => setDeleteModalShow(false)}
          />
    </div>
  );
};

export default CartScreen;
