import React,{useState,useEffect} from "react";
import { Row, Col, Container, Button, InputGroup, DropdownButton } from "react-bootstrap";
import DonutChart from "../../components/DonutChart/DonutChart";
import LineGraph from "../../components/LineGraph/LineGraph";
import DashboardSidebar from "../../components/Sidebar/DashboardSidebar";
import TopBar from "../../components/TopBar/TopBar";
import { Dropdown } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import { Form } from "react-bootstrap";
import { Assets } from "../../constant/images";
import Dispatched from "../../components/ProjectStatus/Dispatched";
import InProcess from "../../components/ProjectStatus/InProcess";
import Completed from "../../components/ProjectStatus/Completed";
import { AddProductBtn, ArrowRight, BackPage, HiddenPagesNo, NextestPage, NextPage, SearchIconTopBar, UserIcon } from "../../constant/svg";
import { NavLink } from "react-router-dom";
import { useGetallcategoryQuery } from "../../redux/reducers/CategorySlice/CategoryApiSlice";
import { CategoryActionCalls } from "../../redux/reducers/CategorySlice/CategorySlice";
import { useDispatch } from "react-redux";

import {
  DataGridPremium,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from "@mui/x-data-grid-premium";
import Select from "react-select";
import AddCategoryModal from "../../components/Modals/AddCategoryModal";
import DeleteModal from "../../components/Modals/DeleteModal";

const allstores = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const Fasion=()=>{
    return(
        <div className="fashionbold">Fashion</div>
    )
}

const UserDropdownBtn=(props)=>{
  const [editcategorymodal,setEditCategoryModal] = useState(false);
  const [deletemodalShow,setDeleteModalShow] = useState(false);
  return(
    <div className="user-button">
    <DropdownButton id="dropdown-basic-button" title={<UserIcon />}>
      <Dropdown.Item
      onClick={() => setEditCategoryModal(true)}
      >
        Edit Category
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => {
          setDeleteModalShow(true);
        }}
      >
        Delete Category
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => {
          setDeleteModalShow(true);
        }}
      >
        Deactivate Category
      </Dropdown.Item>
    </DropdownButton>

    <AddCategoryModal
            title="Edit Category"
            btntitle="Update"             
            show={editcategorymodal}
            onHide={() => setEditCategoryModal(false)} />

      <DeleteModal
            heading="Delete Product"
            type="delete this category"
            btntext="Delete"
            link="/products/product-details"
            display="onClick={()=>{props.onHide}}"
            show={deletemodalShow}
            onHide={() => setDeleteModalShow(false)}
          />
  </div>
  )
}



const CategoryTable = () => {
    const [showcategorymodal,setShowCategoryModal] = useState(false);
    const [editcategorymodal,setEditCategoryModal] = useState(false);
    const [deletemodalShow,setDeleteModalShow] = useState(false);
    const dispatch = useDispatch();
    const { data: categories, isLoading: categoryLoading } = useGetallcategoryQuery();
    const[allCategory,setAllCategory] = useState([]);

    const getAllCategories= async()=>{
      try{
          dispatch(CategoryActionCalls.GetAllCategory({...categories}));
          setAllCategory(categories?.data || []);

      }catch(e){
          console.log(e);
      }
  }

  useEffect(()=>{
      getAllCategories();

  },[categoryLoading])

  // console.log(allCategory);
    
  const colourStyles = {
    control: (styles, state) => ({
      ...styles,
      backgroundColor: "white",
      borderRadius: "20px",
      fontFamily: "Ageo",
      fontSize: "14px",
      textAlign: "left",
      boxShadow: "none",
      border: state.isFocused ? '1px solid #dbbb51' : '1px solid #ccc',
      "&:hover": {
        borderColor: state.isFocused ? "#dbbb51" : "#dbbb51",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        cursor: "pointer",
        backgroundColor: isDisabled ? "red" : "white",
        fontFamily: "Ageo",
        fontSize: "14px",
        color: "#989898",
        boxShadow: "none",
        textAlign: "left",
        ":hover": {
          backgroundColor: "#F4F4F4",
          color: "#dbbb51",
        },
      };
    },
  };

  const columns = [
    {
      field: "UserId",
      headerName: "Category Name",
      width: 400,
      headerAlign: "left",
      
      renderCell: (params) => {
        return (
            <div className="projectids">
              {params.row.subcategory ? null :
                <div className="fashionbold" mainParent={true}>{params.row.category}</div>}
                {params.row.subsubcategory ? null :
                <div className="subid">{params.row.subcategory}</div>}
                <div className="subsubid">{params.row.subsubcategory}</div>
            </div>
        );
      },
      sortable: false

    },
    {
      field: "qty",
      headerName: "Items",
      width: 300,
      align: "center",
      headerAlign: "center",
      sortable: false

    },
    {
        headerName: "Action",
        width: 300,
        id: "action",
        name: "Action",
        align: "center",
        headerAlign: "center",
        renderCell: (params) => {
          return (
            <div>{params.row.action}</div>
          );
        },
        sortable: false

      },
  ];

 const rows = [];

allCategory.forEach((category) => {
  // add category row
  rows.push({
    id: category._id,
    category: category.name,
    qty: "41",
    action: <UserDropdownBtn />,
  });

  // add subcategories
  category.sub_categories.forEach((subcategory) => {
    rows.push({
      id: subcategory._id,
      category: category.name,
      subcategory: subcategory.name,
      qty: "41",
      action: <UserDropdownBtn />,
    });

    // add sub subcategories
    subcategory.sub_sub_category.forEach((subsubcategory) => {
      rows.push({
        id: subsubcategory._id,
        category: category.name,
        subcategory: subcategory.name,
        subsubcategory: subsubcategory.name,
        qty: "41",
        action: <UserDropdownBtn />,
      });
    });
  });
});

  return (
    <>
      <div className="main-title-heading">Category Management</div>
      <div className="order-table-container">
        <div className="d-block d-md-flex justify-content-between">
          <div className="table-search-box">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <NavLink to="/search">
                  <SearchIconTopBar />
                </NavLink>
              </InputGroup.Text>
              <Form.Control
                placeholder="Search Category"
                aria-label="Search"
                aria-describedby="basic-addon1"
              />
            </InputGroup>
          </div>
          <div className="d-flex">
            {/* <div className="text-end dropdown-custom-btn">
              <Form.Select   aria-label="Default select example" styles={colourStyles}>
                <option>All</option>
                <option value="1">New</option>
                <option value="2">This Month</option>
                <option value="3">Last Month</option>
              </Form.Select>
            </div> */}
            <div className="order-dropdown">
              <Select
                className=" dropdown-custom-btn normal w-100"
                classNamePrefix="afrorierre"
                isMenuOpen={true}
                options={[
                  { value: "1", label: "In Stock" },
                  { value: "2", label: "Out of Stock" },
                ]}
                placeholder="Sort By"
                name="reactSelectName"
                // menuIsOpen={true}
                components={{
                  IndicatorSeparator: () => null,
                }}
                styles={colourStyles}
              />
            </div>

            <Button onClick={() => setShowCategoryModal(true)} download className="table-right-btn">
            <span><AddProductBtn/></span> Add Category
            </Button>
          </div>
        </div>
        <div style={{ height: 560, width: "100%" }}>
          <DataGrid
            className="datagrid-table"
            rows={rows}
            columns={columns}
            pageSize={20}
            checkboxSelection
          />
        </div>
        <div className="pagination-border d-flex justify-content-end">
          <div className="seller-paginations d-flex">
            <div className="page-box"><BackPage/></div>
            <div className="page-box active">1</div>
            <div className="page-box"><HiddenPagesNo/></div>
            <div className="page-box"><NextPage/></div>
            <div className="page-box"><NextestPage/></div>
          </div>
        </div>
      </div>

      <AddCategoryModal
            title="Add Category"
            btntitle="Add Category"             
            show={showcategorymodal}
            setShowCategoryModal={setShowCategoryModal}
            onHide={() => setShowCategoryModal(false)} />


<AddCategoryModal

            title="Edit Category"
            btntitle="Update"             
            show={editcategorymodal}
            setEditCategoryModal={setEditCategoryModal}
            onHide={() => setEditCategoryModal(false)} 
            />

      <DeleteModal
            heading="Delete Product"
            type="delete this category"
            btntext="Delete"
            link="/products/product-details"
            display="onClick={()=>{props.onHide}}"
            show={deletemodalShow}
            onHide={() => setDeleteModalShow(false)}
          />
    </>
  );
};

export default CategoryTable;
