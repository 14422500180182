import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import CheckoutProducts from "../../components/Checkout/CheckoutProducts";
import PaymentCard from "../../components/Checkout/PaymentCard";
import AddNewModal from "../../components/Modals/AddNewModal";
import { Assets } from "../../constant/images";
import { AddNewCard } from "../../constant/svg";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import {
  SPECIAL_CHARACTER_ARRAY,
  SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
  VALIDATIONS,
  VALIDATIONS_TEXT,
  EXCLUDE_NUMBER_INPUT,
  EXCLUDE_ALPHABETS_SPECIAL_CHAR,
  SPECIAL_CHARACTER_NUMBER_ARRAY,
} from "../../constant/app-constants";
const CheckoutScreenOne = (props) => {
  const [addnewmodal, setAddNewModal] = useState(false);
  const [options, setOptions] = useState([
    { value: "0", label: "United States" },
    { value: "1", label: "England" },
    { value: "2", label: "Canada" },
  ]);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const navigate = useNavigate();
  const onSubmit = async (data) => {
      setTimeout(()=>{
        navigate("/checkout-screen2")}, 1500)
    }

    const OTPToast = () =>{
      return(
        <div className=" toast-notif d-flex align-items-center">
          <div><img src={Assets.ToastCheck}/></div>
          <div className="pl_15">
            <div className="main-toast-heading"> Thankyou!</div>
            <div className="toast-subheading"> You have successfully created an account.</div>
          </div>
        </div>
      )
    }

    const [errorMessage, setErrorMessage] = useState("");
    const notify = () => toast(<OTPToast/>);
  return (
    <>
      <div className="checkout">
        <Container>
          <div className="checkout-main-heading">{props.mainheading}</div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={5} lg={6}>
                <div className="checkout-subheading">1. Shipping Details</div>
                <div className="signup-form">
                  <Row>
                    <Col md={6}>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Full Name"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Full Name"
                          onKeyDown={(e) =>
                            EXCLUDE_NUMBER_INPUT.includes(e.key) &&
                            e.preventDefault()
                          }
                          maxLength={VALIDATIONS.NAME}
                          {...register("fname", {
                            maxLength: {
                              value: VALIDATIONS.NAME,
                              message: VALIDATIONS_TEXT.NAME_MAX,
                            },
                            pattern: {
                              value: /^[a-zA-Z\s]*$/,
                              message:
                                VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS,
                            },
                            required: {
                              value: true,
                              message: VALIDATIONS_TEXT.NAME_REQUIRED,
                            },
                          })}
                        />
                        {errors.fname && (
                          <small className="text-red">
                            {errors.fname.message}
                          </small>
                        )}
                      </FloatingLabel>
                    </Col>
                    <Col md={6}>
                      <FloatingLabel controlId="floatingInput" label="Address">
                        <Form.Control
                          type="text"
                          placeholder="Address"
                          maxLength={VALIDATIONS.ADDRESS_MAX}
                          {...register("address", {
                            maxLength: {
                              value: VALIDATIONS.ADDRESS_MAX,
                              message: VALIDATIONS_TEXT.ADDRESS_MAX,
                            },
                            required: {
                              value: true,
                              message: VALIDATIONS_TEXT.ADDRESS_REQUIRED,
                            },
                          })}
                        />
                        {errors.address && (
                          <small className="text-red">
                            {errors.address.message}
                          </small>
                        )}
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div class="form-floating">
                        <select
                          class="form-select mb-3"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                        >
                          {options.map((data) => (
                            <option key={data.value} value={data.value}>
                              {data.label}
                            </option>
                          ))}
                        </select>
                        <label for="floatingSelect">Country</label>
                      </div>
                    </Col>
                    <Col md={6}>
                      <FloatingLabel controlId="floatingInput" label="State">
                        <Form.Control
                          type="text"
                          placeholder="State"
                          {...register("state", {
                            maxLength: {
                              value: VALIDATIONS.STATE,
                              message: VALIDATIONS_TEXT.STATE_MAX,
                            },
                            pattern: {
                              value: /^[a-zA-Z\s]*$/,
                              message:
                                VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS_STATE,
                            },
                            required: {
                              value: true,
                              message: VALIDATIONS_TEXT.STATE_REQUIRED,
                            },
                          })}
                        />
                        {errors.state && (
                          <small className="text-red">
                            {errors.state.message}
                          </small>
                        )}
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FloatingLabel controlId="floatingInput" label="City">
                        <Form.Control
                          type="text"
                          placeholder="City"
                          maxLength={VALIDATIONS.CITY_NAME}
                          onKeyDown={(e) =>
                            SPECIAL_CHARACTER_NUMBER_ARRAY.includes(e.key) &&
                            e.preventDefault()
                          }
                          {...register("city", {
                            maxLength: {
                              value: VALIDATIONS.CITY_NAME,
                              message: VALIDATIONS_TEXT.CITY_MAX,
                            },
                            pattern: {
                              value: /^[a-zA-Z\s]*$/,
                              message:
                                VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS_CITY,
                            },
                            required: {
                              value: true,
                              message: VALIDATIONS_TEXT.CITY_NAME_REQUIRED,
                            },
                          })}
                        />
                        {errors.city && (
                          <small className="text-red">
                            {errors.city.message}
                          </small>
                        )}
                      </FloatingLabel>
                    </Col>
                    <Col md={6}>
                      <FloatingLabel controlId="floatingInput" label="Zipcode">
                        <Form.Control
                          type="text"
                          placeholder="Zipcode"
                          onKeyDown={(e) => {
                            EXCLUDE_ALPHABETS_SPECIAL_CHAR.includes(
                              e.key.toUpperCase()
                            ) && e.preventDefault();
                          }}
                          maxLength={VALIDATIONS.ZIP_CODE}
                          {...register("zip_code", {
                            pattern: {
                              value: /^\d+$/,
                              message:
                                VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS_ZIP,
                            },
                            maxLength: {
                              value: VALIDATIONS.ZIP_CODE,
                              message: VALIDATIONS_TEXT.ZIP_MAX,
                            },
                            required: {
                              value: true,
                              message: VALIDATIONS_TEXT.ZIP_CODE_REQUIRED,
                            },
                          })}
                        />
                        {errors.zip_code && (
                          <small className="text-red">
                            {errors.zip_code.message}
                          </small>
                        )}
                      </FloatingLabel>
                    </Col>
                  </Row>
                  {/* <Row>
          <Col md={12}>
            <FloatingLabel controlId="floatingInput" label="Address Line 2">
              <Form.Control type="text" placeholder="name@example.com" />
            </FloatingLabel>
          </Col>
        </Row> */}
                </div>
                <div className="checkout-checkbox">
                  <Form>
                    <Form.Check
                      type="checkbox"
                      id="capital"
                      label="Save this for next time"
                      name="capital"
                    />
                  </Form>
                </div>
                <div className="d-flex justify-content-between mt_20">
                  <div className="checkout-subheading mt_10 ">
                    2. Payment Method
                  </div>
                  <div
                    onClick={() => setAddNewModal(true)}
                    className="d-flex align-items-center"
                  >
                    <div>
                      <AddNewCard />
                    </div>
                    <div className="add-new-card">Add New Card</div>
                  </div>
                </div>
                <div className="payment-checkbox">
                  <Form>
                    <Form.Check
                      type="checkbox"
                      id="stripe"
                      label={
                        <PaymentCard
                          img={Assets.Stripe}
                          cardtitle="Stripe"
                          status="(connected)"
                        />
                      }
                      name="payment"
                    />
                    <Form.Check
                      type="checkbox"
                      id="applepay"
                      label={
                        <PaymentCard
                          img={Assets.Applepay}
                          cardtitle="ApplePay"
                          code="**** 1234"
                        />
                      }
                      name="payment"
                    />
                    <Form.Check
                      type="checkbox"
                      id="mastercard"
                      label={
                        <PaymentCard
                          img={Assets.Mastercard}
                          cardtitle="Mastercard"
                          code="**** 1234"
                        />
                      }
                      name="payment"
                    />
                  </Form>
                </div>
              </Col>
              <Col md={7} lg={{ span: 5, offset: 1 }}>
                <div className="total-items-container ">
                  <div className="items-heading">Items (2) Total</div>
                  <div className="subhr">
                    <hr />
                  </div>
                  <CheckoutProducts
                    checkouttitle="DiW_ Shop 24/7"
                    checkoutprice="USD $48"
                    qtyprice="$150.45"
                    qty="1"
                    cartproductimg={Assets.CartProduct2}
                  />
                  <CheckoutProducts
                    checkouttitle="DiW_ Shop 24/7"
                    checkoutprice="USD $48"
                    qtyprice="$150.45"
                    qty="1"
                    cartproductimg={Assets.CartProduct1}
                  />
                  <div className="total-item-checkout-section">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="item">Sub Total :</div>
                      <div className="total">$140</div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt_15">
                      <div className="item">Shipping:</div>
                      <div className="total">Free</div>
                    </div>
                    <div className="d-flex justify-content-end mt_15 est-time">
                      (Est time. 14-16 Nov)
                    </div>
                    <div className="totalhr">
                      <hr />
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt_15">
                      <div className="item" style={{ fontWeight: 600 }}>
                        Total Amount:
                      </div>
                      <div className="total" style={{ fontWeight: 600 }}>
                        $140.45
                      </div>
                    </div>
                    <div>
                      <Button className="proceed-btn" type="submit">
                        {props.btn1txt}
                      </Button>
                    </div>
                    <div>
                      <NavLink to={props.link2}>
                        <Button className="continue-btn">
                          {props.btn2txt}
                        </Button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>

      <AddNewModal show={addnewmodal} onHide={() => setAddNewModal(false)} />
    </>
  );
};

export default CheckoutScreenOne;
