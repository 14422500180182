import React from "react";
import { Row, Col, Container, Button, InputGroup } from "react-bootstrap";
import DonutChart from "../../components/DonutChart/DonutChart";
import LineGraph from "../../components/LineGraph/LineGraph";
import DashboardSidebar from "../../components/Sidebar/DashboardSidebar";
import TopBar from "../../components/TopBar/TopBar";
import { Dropdown } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import { Form } from "react-bootstrap";
import { Assets } from "../../constant/images";
import Dispatched from "../../components/ProjectStatus/Dispatched";
import InProcess from "../../components/ProjectStatus/InProcess";
import Completed from "../../components/ProjectStatus/Completed";
import { ArrowRight, BackPage, HiddenPagesNo, NextestPage, NextPage, SearchIconTopBar } from "../../constant/svg";
import { NavLink } from "react-router-dom";

import {
  DataGridPremium,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from "@mui/x-data-grid-premium";
import Select from "react-select";

const allstores = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const Order = () => {
  const colourStyles = {
    control: (styles, state) => ({
      ...styles,
      backgroundColor: "white",
      borderRadius: "20px",
      textAlign: "left",
      boxShadow: "none",
      fontFamily: "Ageo",
      fontSize: "14px",
      border: state.isFocused ? '1px solid #dbbb51' : '1px solid #ccc',
      "&:hover": {
        borderColor: state.isFocused ? "#dbbb51" : "#dbbb51",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        cursor: "pointer",
        backgroundColor: isDisabled ? "red" : "white",
        fontFamily: "Ageo",
        fontSize: "14px",
        color: "#989898",
        boxShadow: "none",
        textAlign: "left",
        ":hover": {
          backgroundColor: "#F4F4F4",
          color: "#dbbb51",
        },
      };
    },
  };

  const columns = [
    {
      field: "UserId",
      headerName: "Order ID",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <NavLink to="/orders/order-detail">
            <div className="d-flex align-items-center mt-2">
              <img className="img-fluid" src={params.row.image} />
              <div className="product-table-title">
                {params.row.producttitle}
              </div>
            </div>
          </NavLink>
        );
      },
    },
    {
      field: "startdate",
      headerName: "Date Placed",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <NavLink to="/orders/order-detail">
              <div className="start-date">
              {params.row.startdate}
              </div>
          </NavLink>
        );
      },
    },
    {
      field: "projectdate",
      headerName: "Date Delivered",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <NavLink to="/orders/order-detail">
              <div className="start-date">
              {params.row.projectdate}
              </div>
          </NavLink>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (<NavLink to="/orders/order-detail"><div>{params.row.status}</div></NavLink>)
      },
    },
    {
      field: "price",
      headerName: "Price",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <NavLink to="/orders/order-detail">
              <div className="start-date">
              {params.row.price}
              </div>
          </NavLink>
        );
      },
    },
  ];

  const rows = [
    {
      id: 1,
      image: Assets.Tableavatarone,
      producttitle: "RW5562",
      startdate: "Mar 22, 2022",
      projectdate: "Mar 29, 2022",
      status: <Dispatched />,
      price: "$120.00",
    },

    {
      id: 2,
      image: Assets.Tableavatartwo,
      producttitle: "RW5562",
      UserId: "02",
      startdate: "Mar 22, 2022",
      projectdate: "Mar 29, 2022",
      status: <InProcess />,
      price: "$120.00",
    },

    {
      id: 3,
      image: Assets.Tableavatarthree,
      producttitle: "RW5562",
      UserId: "03",
      startdate: "Mar 22, 2022",
      projectdate: "Mar 29, 2022",
      status: <Completed />,
      price: "$120.00",
    },
    {
      id: 4,
      image: Assets.Tableavatarfour,
      producttitle: "RW5562",
      UserId: "04",
      startdate: "Mar 22, 2022",
      projectdate: "Mar 29, 2022",
      status: <Dispatched />,
      price: "$120.00",
    },
    {
      id: 5,
      image: Assets.Tableavatarone,
      producttitle: "RW5562",
      UserId: "05",
      startdate: "Mar 22, 2022",
      projectdate: "Mar 29, 2022",
      status: <InProcess />,
      price: "$120.00",
    },
    {
      id: 6,
      image: Assets.Tableavatartwo,
      producttitle: "RW5562",
      UserId: "06",
      startdate: "Mar 22, 2022",
      projectdate: "Mar 29, 2022",
      status: <Completed />,
      price: "$120.00",
    },
    {
      id: 7,
      image: Assets.Tableavatarthree,
      producttitle: "RW5562",
      UserId: "07",
      startdate: "Mar 22, 2022",
      projectdate: "Mar 29, 2022",
      status: <Dispatched />,
      price: "$120.00",
    },
    {
      id: 8,
      image: Assets.Tableavatarfour,
      producttitle: "RW5562",
      UserId: "08",
      startdate: "Mar 22, 2022",
      projectdate: "Mar 29, 2022",
      status: <InProcess />,
      price: "$120.00",
    },
    {
      id: 9,
      image: Assets.Tableavatarone,
      producttitle: "RW5562",
      UserId: "09",
      startdate: "Mar 22, 2022",
      projectdate: "Mar 29, 2022",
      status: <Completed />,
      price: "$120.00",
    },
  ];

  return (
    <>
      <div className="main-title-heading">Order Management</div>
      <div className="order-table-container">
        <div className="d-block d-md-flex justify-content-between">
          <div className="table-search-box">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <NavLink to="/search">
                  <SearchIconTopBar />
                </NavLink>
              </InputGroup.Text>
              <Form.Control
                placeholder="Search Orders"
                aria-label="Search"
                aria-describedby="basic-addon1"
              />
            </InputGroup>
          </div>
          <div className="d-flex">
            {/* <div className="text-end dropdown-custom-btn">
              <Form.Select   aria-label="Default select example" styles={colourStyles}>
                <option>All</option>
                <option value="1">New</option>
                <option value="2">This Month</option>
                <option value="3">Last Month</option>
              </Form.Select>
            </div> */}
            <div className="order-dropdown">
              <Select
                className=" dropdown-custom-btn normal w-100"
                classNamePrefix="afrorierre"
                isMenuOpen={true}
                options={[
                  { value: "chocolate", label: "All" },
                  { value: "strawberry", label: "New" },
                  { value: "vanilla", label: "Last Month" },
                  { value: "banana", label: "This Month" },
                ]}
                placeholder="All"
                name="reactSelectName"
                // menuIsOpen={true}
                components={{
                  IndicatorSeparator: () => null,
                }}
                styles={colourStyles}
              />
            </div>

            <NavLink download className="table-right-btn">
              Export CSV
            </NavLink>
          </div>
        </div>
        <div style={{ height: 560, width: "100%" }}>
          <DataGrid
            className="datagrid-table"
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[7]}
            checkboxSelection
          />
        </div>
        <div className="pagination-border d-flex justify-content-end">
          <div className="seller-paginations d-flex">
            <div className="page-box"><BackPage/></div>
            <div className="page-box active">1</div>
            <div className="page-box"><HiddenPagesNo/></div>
            <div className="page-box"><NextPage/></div>
            <div className="page-box"><NextestPage/></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Order;
