import React, { useState } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import DonutChart from "../../components/DonutChart/DonutChart";
import LineGraph from "../../components/LineGraph/LineGraph";
import DashboardSidebar from "../../components/Sidebar/DashboardSidebar";
import TopBar from "../../components/TopBar/TopBar";
import { Dropdown } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import { Form } from "react-bootstrap";
import { Assets } from "../../constant/images";
import Dispatched from "../../components/ProjectStatus/Dispatched";
import InProcess from "../../components/ProjectStatus/InProcess";
import Completed from "../../components/ProjectStatus/Completed";
import { ArrowRight, BackArrow } from "../../constant/svg";
import { NavLink, useNavigate } from "react-router-dom";
import RejectModal from "../../components/Modals/RejectModal";
import CancelOrderModal from "../../components/Modals/CancelOrderModal";
import { ToastContainer, toast } from "react-toastify";
import New from "../../components/ProjectStatus/New";
import Cancelled from "../../components/ProjectStatus/Cancelled";
import MainProjectStatus from "../../components/ProjectStatus/MainProjectStatus";
import DispatchedTrackingDetails from "../../components/ProjectStatus/DispatchedTrackingDetails";
import CompletedReview from "../../components/ProjectStatus/CompletedReview";
import CompletedTrackingDetails from "../../components/ProjectStatus/CompletedTrackingDetails";

const OrderDetails = () => {
  const [rejectmodalShow, setRejectModalShow] = useState(false);
  const [cancelModalShow, setCancelModalShow] = useState(false);

  const navigate = useNavigate();
  const onSubmit = async (data) => {
    setTimeout(() => {
      navigate("/checkout-screen2");
    }, 1500);
  };

  const OTPToast = () => {
    return (
      <div className=" toast-notif d-flex align-items-center">
        <div>
          <img src={Assets.ToastCheck} />
        </div>
        <div className="pl_15">
          <div className="main-toast-heading"> Success!</div>
          <div className="toast-subheading">
            {" "}
            Order accepted, let's deliver it on time!
          </div>
        </div>
      </div>
    );
  };

  const notify = () => toast(<OTPToast />);

  const columns = [
    {
      field: "UserId",
      headerName: "Product Title",
      width: 150,
      align: "center",
      headerAlign: "start",
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center mt-2">
            <img className="img-fluid" src={params.row.image} />
            <div className="product-table-title">{params.row.producttitle}</div>
          </div>
        );
      },
      sortable: false,
    },
    {
      field: "qty",
      headerName: "qty",
      width: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return <div className="order-detail-table-text">{params.row.qty}</div>;
      },
      sortable: false,
    },
    {
      field: "price",
      headerName: "Unit Price",
      width: 200,
      align: "start",
      headerAlign: "start",
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="order-detail-table-text">{params.row.price}</div>
        );
      },
    },
    {
      field: "total",
      headerName: "Total",
      width: 150,
      align: "start",
      headerAlign: "start",
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="order-detail-table-text">{params.row.total}</div>
        );
      },
    },
  ];

  const [orderDetail, setOrderDetail] = useState({
    id: "#RW5562",
    orderDate: "Mar 22, 2022",
    orderTime: "14:06",
    orderName: "Sams Andrews",
    orderShippingAddress: "Street 24g, Flat no. 345, newyork city.",
    orderBillingAddress: "Street 24g, Flat no. 345, newyork city.",
    dateTime: "12/06/2022, 16:05",
    status: "new",
  });

  const handeClick = () => {
    if (orderDetail.status === "new") {
      setOrderDetail({ ...orderDetail, status: "inProcess" });
    }
  };

  const [selectedOption, setSelectedOption] = useState({
    label: "In Process",
    value: "inprocess",
  });

  const status = {
    new: <New />,
    inProcess: (
      <MainProjectStatus
        orderDetail={orderDetail}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />
    ),
    dispatched: <Dispatched />,
    cancelled: <Cancelled />,
    completed: <Completed />,
  };

  const rows = [
    {
      id: 1,
      image: Assets.Tableavatarone,
      producttitle: "RW5562",
      qty: "2",
      projectdate: "Mar 29, 2022",
      status: <Dispatched />,
      price: "$120.00",
      total: "$400.00",
    },

    {
      id: 2,
      image: Assets.Tableavatartwo,
      producttitle: "RW5562",
      UserId: "02",
      qty: "1",
      projectdate: "Mar 29, 2022",
      status: <InProcess />,
      price: "$120.00",
      total: "$400.00",
    },

    {
      id: 3,
      image: Assets.Tableavatarthree,
      producttitle: "RW5562",
      UserId: "03",
      qty: "2",
      projectdate: "Mar 29, 2022",
      status: <Completed />,
      price: "$120.00",
      total: "$400.00",
    },
    {
      id: 4,
      price: "Sub Total",
      total: "$601.00",
    },
    {
      id: 5,
      price: "VAT(0%)",
      total: "$0.00",
    },
    {
      id: 6,
      price: "Shipping Rate",
      total: (
        <div>
          <div className="">(Free)</div>
          <div className="order-est-delivery-time">
            (Est. delivery 15-17 Oct)
          </div>
        </div>
      ),
    },
    {
      id: 7,
      price: <b>Total</b>,
      total: <b>$606.00</b>,
    },
  ];

  console.log(orderDetail);

  return (
    <>
      <div className="d-flex align-items-center">
        <NavLink to="/orders">
          <BackArrow />
        </NavLink>
        <div className="main-subheading">Order Detail</div>
      </div>
      <div>
        <Row>
          <Col md={4}>
            <Row>
              <div className="orderno-bg">
                <h2>
                  Order ID <span className="order-no">{orderDetail.id}</span>
                </h2>
                <div className="order-data">
                  <Row>
                    <Col md={6}>
                      <div className="order-ques">Order Date: </div>
                    </Col>
                    <Col md={6}>
                      <div className="order-ans">{orderDetail.orderDate}</div>
                    </Col>
                  </Row>
                </div>
                <div className="order-data">
                  <Row>
                    <Col md={6}>
                      <div className="order-ques">Order Time: </div>
                    </Col>
                    <Col md={6}>
                      <div className="order-ans">{orderDetail.orderTime}</div>
                    </Col>
                  </Row>
                </div>
                <div className="order-data">
                  <Row>
                    <Col md={6}>
                      <div className="order-ques">Name: </div>
                    </Col>
                    <Col md={6}>
                      <div className="order-ans">{orderDetail.orderName}</div>
                    </Col>
                  </Row>
                </div>
                <div className="order-data">
                  <Row>
                    <Col md={6}>
                      <div className="order-ques">Shipping Address: </div>
                    </Col>
                    <Col md={6}>
                      <div className="order-ans">
                        {orderDetail.orderShippingAddress}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="order-data">
                  <Row>
                    <Col md={6}>
                      <div className="order-ques">Billing Address: </div>
                    </Col>
                    <Col md={6}>
                      <div className="order-ans">
                        {orderDetail.orderBillingAddress}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="order-data">
                  <Row>
                    <Col md={6}>
                      <div className="order-ques">Status: </div>
                    </Col>
                    <Col md={6}>
                      <div className="order-ans">
                        {selectedOption.value == "completed" ? (
                          <Completed />
                        ) : (
                          status[orderDetail.status]
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
                {selectedOption.value == "completed" ? (
                  <div className="order-data">
                    <Row>
                      <Col md={6}>
                        <div className="order-ques">Delivery Date & Time: </div>
                      </Col>
                      <Col md={6}>
                        <div className="order-ans">{orderDetail.dateTime}</div>
                      </Col>
                    </Row>
                  </div>
                ) : null}
              </div>
              {selectedOption.value == "dispatched" ? (
                <>
                  <DispatchedTrackingDetails />
                </>
              ) : null}
              {selectedOption.value == "completed" ? (
                <>
                  <CompletedTrackingDetails />
                </>
              ) : null}
            </Row>
          </Col>
          <Col md={8}>
            <div className="order-table order-detail-table mt-3 mt-md-0">
              <div style={{ height: 500, width: "100%" }}>
                <DataGrid
                  className="datagrid-table"
                  rows={rows}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[7]}
                  checkboxSelection
                />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-end">
              {orderDetail.status == "new" ? (
                <>
                  <Button
                    onClick={() => setCancelModalShow(true)}
                    className="reject-btn"
                  >
                    Reject
                  </Button>
                  <Button onClick={handeClick} className="accept-btn">
                    Accept Order
                  </Button>
                </>
              ) : null}
            </div>
          </Col>

          <Col>
            {selectedOption.value == "completed" ? (
              <>
                <CompletedReview />
              </>
            ) : null}
          </Col>
        </Row>
      </div>
      <RejectModal
        heading="Reject Order"
        type="reject this order?"
        btntext="Reject"
        link="/products/product-details"
        display="onClick={()=>{props.onHide}}"
        show={rejectmodalShow}
        onHide={() => setRejectModalShow(false)}
      />

      <CancelOrderModal
        show={cancelModalShow}
        onHide={() => setCancelModalShow(false)}
        orderDetail={orderDetail}
        setOrderDetail={setOrderDetail}
      />
      <ToastContainer hideProgressBar={true} />
    </>
  );
};

export default OrderDetails;
