import React, {useEffect, useState} from "react";
import {Col, Form, Row, Button} from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import {NavLink, useNavigate} from "react-router-dom";
import signupupload from "../../src/assets/images/Auth/signupupload.png";
import {useForm} from "react-hook-form";
import {
    VALIDATIONS,
    VALIDATIONS_TEXT,
    EXCLUDE_NUMBER_INPUT,
    EXCLUDE_ALPHABETS_SPECIAL_CHAR,
} from "../constant/app-constants";
import {OpenIcon, CloseIcon} from "../utils/svg";
import SocialLogin from "./AuthComponents/SocialLogin";
import PhoneInput from "react-phone-number-input";
import {useLocation} from 'react-router-dom';
import {toast} from "react-toastify";
import { useDispatch } from "react-redux";
import {useSignupMutation} from '../redux/reducers/UserSlice/UserApiSlice'
import { UserActionCalls } from "../redux/reducers/UserSlice/UserSlice";
import { useGetcountryQuery } from "../redux/reducers/CountrySlice/CountryApiSlice";
import { CountryActionCalls } from "../redux/reducers/CountrySlice/CountrySlice";
import {useGetstateQuery} from '../redux/reducers/StateSlice/StateApiSlice'
import {useGetcityQuery} from '../redux/reducers/CitySlice/CityApiSlice'


const SignUp = (props) => {
    const location = useLocation();
    const {role} = location.state?.value;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [signup] = useSignupMutation();
    const[countryId,setCountryId] = useState(null);
    const[stateId,setStateId] = useState(null);
    const { data: countries, isLoading: countryLoading } = useGetcountryQuery();
    const { data: states, isLoading: stateLoading, isFetching: stateFetching } = useGetstateQuery(countryId);
    const { data: cities, isLoading: cityLoading, isFetching: cityFetching } = useGetcityQuery(stateId);
    const pcName = navigator.userAgent;
    const [country, setCountry] = useState([]);
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);

    const getCountry = async () => {
        try {
          if (!countryLoading) {
            dispatch(CountryActionCalls.getCountry(countries));
            setCountry(countries?.data || []);
          }
        } catch (e) {
          console.log(e);
        }
      };
      
      const handleCountrySelect = (id) => {
        setCountryId(id);
        setCity([]);
      };
      
      const handleStateSelect = (id) => {
        setStateId(id);
      };
      
      useEffect(() => {
        console.log('city=======',city);
        if (states?.data) {
          setState(states?.data || []);
        }
      }, [states?.data, stateFetching]);
      
      useEffect(() => {
        if (cities?.data) {
          setCity(cities?.data || []);
        }
      }, [cities?.data, cityFetching]);
      
      useEffect(() => {
        getCountry();
      }, [countryLoading]);
    
    const [value, setValue] = useState();
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: {errors},
    } = useForm({
        mode: "onChange",
    });

    const password = watch("password");
    const onSubmit = async (data) => {
        const formattedPhone = data.phone.replace(/\s/g, '');
        const email = data.email;
        const username = email.substring(0, email.indexOf('@'));
        const userData = {
            first_name: data.fname,
            last_name: data.lname,
            email: data.email,
            username: username,
            password: data.password,
            password_confirmation: data.password_confirmation,
            phone: formattedPhone,
            country_id: data.country,
            state_id:data.state,
            city_id: data.city,
            zip_code: data.zip_code,
            address: data.address,
            role_id: role,
            store_name: data.storename,
            device_type: "web",
            device_token: pcName
        };
        try{
            const SignUpUser = await signup(userData).unwrap()
            // console.log(LoginUser);
            dispatch(UserActionCalls.SignUp({...SignUpUser}))
            // toast.success('Thank you! You have successfully Sign Up');
            navigate('/otpverify')

        }catch(e){
            console.log(e);
            toast.error(`${e.data.error}`);
        }
    };
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [profilePic, setProfilePic] = useState({
        file: null,
        file_url: null,
    });


    return (
        <>
            <h2>Signup as a {role === '20' ? 'Buyer' : 'Seller'}</h2>
            <Row>
                <Col md={9}>
                    <h6>
                        <b>Create your account</b>, It takes less than a minutes. Enter your
                        personal info.
                    </h6>
                </Col>
            </Row>
            <div className="new-hr">
                <hr/>
            </div>
            <div className="signup-form">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col>
                            <div className="img-div">
                                <label htmlFor="img-upload" className="file-upload">
                                    <input
                                        type="file"
                                        accept=".png, .jpg, .jpeg"
                                        id="img-upload"
                                        name="avatar"
                                        className="d-none"
                                        onChange={(e) =>
                                            setProfilePic({
                                                file: e.target.files[0],
                                                file_url: URL.createObjectURL(e.target.files[0]),
                                            })
                                        }
                                    />
                                    <img
                                        src={
                                            profilePic.file_url ? profilePic.file_url : signupupload
                                        }
                                        alt=""
                                        className="w-100 h-100 img-upload"
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FloatingLabel controlId="floatingInput" label="First Name *">
                                <Form.Control
                                    placeholder="FirstName *"
                                    type="text"
                                    onKeyDown={(e) =>
                                        EXCLUDE_NUMBER_INPUT.includes(e.key) && e.preventDefault()
                                    }
                                    maxLength={VALIDATIONS.NAME}
                                    {...register("fname", {
                                        maxLength: {
                                            value: VALIDATIONS.NAME,
                                            message: VALIDATIONS_TEXT.NAME_MAX,
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z\s]*$/,
                                            message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS,
                                        },
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.FIRST_NAME_REQUIRED,
                                        },
                                    })}
                                />
                                {errors.fname && (
                                    <small className="text-red">{errors.fname.message}</small>
                                )}
                            </FloatingLabel>
                        </Col>
                        <Col md={6}>
                            <FloatingLabel controlId="floatingInput" label="Last Name *">
                                <Form.Control
                                    placeholder="Last Name"
                                    type="text"
                                    onKeyDown={(e) =>
                                        EXCLUDE_NUMBER_INPUT.includes(e.key) && e.preventDefault()
                                    }
                                    maxLength={VALIDATIONS.NAME}
                                    {...register("lname", {
                                        maxLength: {
                                            value: VALIDATIONS.NAME,
                                            message: VALIDATIONS_TEXT.NAME_MAX,
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z\s]*$/,
                                            message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS,
                                        },
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.LAST_NAME_REQUIRED,
                                        },
                                    })}
                                />
                                {errors.lname && (
                                    <small className="text-red">{errors.lname.message}</small>
                                )}
                            </FloatingLabel>
                        </Col>
                        {role === '30' && (
                            <Col md={6}>
                                <FloatingLabel controlId="floatingInput" label="Store Name *">
                                    <Form.Control
                                        placeholder="Name"
                                        type="text"
                                        onKeyDown={(e) =>
                                            EXCLUDE_NUMBER_INPUT.includes(e.key) && e.preventDefault()
                                        }
                                        maxLength={VALIDATIONS.STORE_NAME}
                                        {...register("storename", {
                                            maxLength: {
                                                value: VALIDATIONS.STORE_NAME,
                                                message: VALIDATIONS_TEXT.STORE_NAME_MAX,
                                            },
                                            pattern: {
                                                value: /^[a-zA-Z\s]*$/,
                                                message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS,
                                            },
                                            required: {
                                                value: true,
                                                message: VALIDATIONS_TEXT.STORE_NAME_REQUIRED,
                                            },
                                        })}
                                    />
                                    {errors.storename && (
                                        <small className="text-red">
                                            {errors.storename.message}
                                        </small>
                                    )}
                                </FloatingLabel>
                            </Col>
                        )}

                        <Col md={6}>
                            <FloatingLabel controlId="floatingInput" label="Email Address *">
                                <Form.Control
                                    type="email"
                                    placeholder="Email Address"
                                    maxLength={VALIDATIONS.EMAIL}
                                    {...register("email", {
                                        maxLength: {
                                            value: VALIDATIONS.EMAIL,
                                            message: VALIDATIONS_TEXT.EMAIL_MAX,
                                        },
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                                        },
                                        pattern: {
                                            value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                            message: VALIDATIONS_TEXT.EMAIL_FORMAT,
                                        },
                                    })}
                                />
                                {errors.email && (
                                    <small className="text-red error_from">
                                        {errors.email.message}
                                    </small>
                                )}
                            </FloatingLabel>
                        </Col>
                        <Col md={6}>
                            <FloatingLabel controlId="floatingInput" label="New Password *">
                                <Form.Control
                                    type={!showNewPassword ? "password" : "text"}
                                    placeholder="Enter your new password"
                                    maxLength={VALIDATIONS_TEXT.PASSWORD_MAX}
                                    {...register("password", {
                                        minLength: {
                                            value: VALIDATIONS.PASSWORD_MIN,
                                            message: VALIDATIONS_TEXT.PASSWORD_MIN,
                                        },
                                        maxLength: {
                                            value: VALIDATIONS.PASSWORD_MAX,
                                            message: VALIDATIONS_TEXT.PASSWORD_MAX,
                                        },
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                                        },
                                        pattern: {
                                            value: /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/,
                                            message: VALIDATIONS_TEXT.PASSWORD_FORMAT,
                                        },
                                    })}
                                />
                                {errors.password && (
                                    <small className="text-red error_from error_from">
                                        {errors.password.message}
                                    </small>
                                )}

                                <span className="iconfeildauth">
                  {showNewPassword ? (
                      <span
                          className="toggle-password"
                          onClick={() => setShowNewPassword(false)}
                      >
                      <OpenIcon/>
                    </span>
                  ) : (
                      <span
                          onClick={() => setShowNewPassword(true)}
                          className="toggle-password"
                      >
                      <CloseIcon/>
                    </span>
                  )}
                </span>
                            </FloatingLabel>
                        </Col>

                        <Col md={6}>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Confirm Password *"
                            >
                                <Form.Control
                                    type={!showConfirmPassword ? "password" : "text"}
                                    placeholder="Confirm new password"
                                    maxLength={VALIDATIONS_TEXT.PASSWORD_MAX}
                                    {...register("password_confirmation", {
                                        minLength: {
                                            value: VALIDATIONS.PASSWORD_MIN,
                                            message: VALIDATIONS_TEXT.PASSWORD_MIN,
                                        },
                                        maxLength: {
                                            value: VALIDATIONS.PASSWORD_MAX,
                                            message: VALIDATIONS_TEXT.PASSWORD_MAX,
                                        },
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.CONFIRM_PASSWORD_REQUIRED,
                                        },
                                        validate: (value) =>
                                            value === password ||
                                            "Password and Confirm Password must be same",
                                    })}
                                />
                                {errors.password_confirmation && (
                                    <small className="text-red error_from">
                                        {errors.password_confirmation.message}
                                    </small>
                                )}

                                <span className="iconfeildauth">
                  {showConfirmPassword ? (
                      <span
                          className="toggle-password"
                          onClick={() => setShowConfirmPassword(false)}
                      >
                      <OpenIcon/>
                    </span>
                  ) : (
                      <span
                          onClick={() => setShowConfirmPassword(true)}
                          className="toggle-password"
                      >
                      <CloseIcon/>
                    </span>
                  )}
                </span>
                            </FloatingLabel>
                        </Col>
                        <Col md={6}>
                            <div className="phone-input position-realtive">
                                <PhoneInput
                                    international
                                    placeholder="Enter phone number *"
                                    value={value}
                                    onChange={setValue}
                                    maxLength={VALIDATIONS.PHONE_MAX}
                                    {...register("phone", {
                                        maxLength: {
                                            value: VALIDATIONS.PHONE_MAX,
                                            message: VALIDATIONS_TEXT.PHONE_MAX,
                                        },
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.PHONE_REQUIRED,
                                        },
                                    })}
                                />
                                {errors.phone && (
                                    <small className="text-red error_from">
                                        {errors.phone.message}
                                    </small>
                                )}
                            </div>
                        </Col>

                        <Col md={6}>
                            <div class="form-floating">
                                <select
                                    
                                    class="form-select mb-3"
                                    id="floatingSelect"
                                    aria-label="Floating label select example"
                                    {...register("country", {
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.COUNTRY_NAME_REQUIRED,
                                        },
                                        onChange: (e) => {
                                            handleCountrySelect(e.target.value);
                                          },
                                    })}
                                >
                                    {!countryLoading && country.map((data) => (
                                        <option key={data.id} value={data._id}  >
                                            {data.name}
                                        </option>
                                    ))}
                                </select>
                                <label for="floatingSelect">Country *</label>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div class="form-floating">
                                <select
                                    class="form-select mb-3"
                                    id="floatingSelect"
                                    aria-label="Floating label select example"
                                    {...register("state", {
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.STATE_REQUIRED,
                                        },
                                        onChange: (e) => {
                                            handleStateSelect(e.target.value);
                                          },
                                    })}
                                >
                                    { state.length !== 0 && state.map((data) => (
                                        <option key={data.id} value={data._id}>
                                            {data.name}
                                        </option>
                                    ))}
                                </select>
                                <label for="floatingSelect">State *</label>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div class="form-floating">
                                <select
                                    class="form-select mb-3"
                                    id="floatingSelect"
                                    aria-label="Floating label select example"
                                    {...register("city", {
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.CITY_REQUIRED,
                                        },
                                    })}
                                >
                                    {city.length !== 0 &&city.map((data) => (
                                        <option key={data.id} value={data._id}>
                                            {data.name}
                                        </option>
                                    ))}
                                </select>
                                <label for="floatingSelect">City *</label>
                            </div>
                        </Col>
                        
                        <Col md={6}>
                            <FloatingLabel controlId="floatingInput" label="Address *">
                                <Form.Control
                                    type="text"
                                    placeholder="name@example.com"
                                    {...register("address", {
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.ADDRESS_REQUIRED,
                                        },
                                    })}
                                />
                                {errors.address && (
                                    <small className="text-red error_from">
                                        {errors.address.message}
                                    </small>
                                )}
                            </FloatingLabel>
                        </Col>
                        <Col md={6}>
                            <FloatingLabel controlId="floatingInput" label="Zip Code*">
                                <Form.Control
                                    type="tel"
                                    placeholder="12345"
                                    onKeyDown={(e) => {
                                        EXCLUDE_ALPHABETS_SPECIAL_CHAR.includes(
                                            e.key.toUpperCase()
                                        ) && e.preventDefault();
                                    }}
                                    maxLength={VALIDATIONS.ZIP_CODE}
                                    {...register("zip_code", {
                                        pattern: {
                                            value: /^\d+$/,
                                            message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS_ZIP,
                                        },
                                        maxLength: {
                                            value: VALIDATIONS.ZIP_CODE,
                                            message: VALIDATIONS_TEXT.ZIP_MAX,
                                        },
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.ZIP_CODE_REQUIRED,
                                        },
                                    })}
                                />
                                {errors.zip_code && (
                                    <small className="text-red">{errors.zip_code.message}</small>
                                )}
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <div>
                        <Button type="submit" className="auth-btn mt-0 mb_20">
                            Continue
                        </Button>
                    </div>
                    <SocialLogin/>

                    <div className="d-flex mt_30">
                        <div className="already-account">Already have an account?</div>
                        <div className="already-acc-link">
                            <NavLink to="/">SIGN IN</NavLink>
                        </div>
                    </div>
                    <div className="as-guest">
                        <NavLink to="/guest-home">Continue as a guest</NavLink>
                    </div>
                </Form>
            </div>
            <Row>
                <Col md={9}>
                    <div className="checkout-signup ">
                        <Form>
                            <div className="d-flex">
                                <Form.Check type="checkbox" id="capital" name="capital"/>
                                <label>
                                    <div className="privacy">
                                        By clicking you agree to Afrotierre’s{" "}
                                        <NavLink to="/buyer-terms">
                                            <span>Terms and Conditions</span>
                                        </NavLink>{" "}
                                        and{" "}
                                        <NavLink to="/buyer-privacy">
                                            <span>Privacy Policy</span>
                                        </NavLink>
                                    </div>
                                    <div className="privacy mt_10 mb_20">
                                        Afrotierre may send you communications, you may change your
                                        preferences in your account settings. We'll never post
                                        without your permission.
                                    </div>
                                </label>
                            </div>
                        </Form>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default SignUp;
