import { createSlice } from "@reduxjs/toolkit";
import UserAction from "../../actions/UserAction";


const initialState={
    User:null,
    Token:null
}

export const UserSlice = createSlice({
    name:'User',
    initialState,
    reducers:UserAction,
})

export const UserActionCalls = UserSlice.actions;

export default UserSlice.reducer; 

export const selectCurrentUser = (state)=>state.User.User;
export const selectCurrentToken = (state)=>state.User.Token;