import React, { useState } from "react";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Assets } from "../../constant/images";
import { NavLink } from "react-router-dom";
import { NewCrossIcon, NewSearchIcon, SearchIcon } from "../../utils/svg";
import Table from "react-bootstrap/Table";
import { STATUS } from "../../constant/app-constants";
const BuyerTransactionHistory = () => {
  const [searchBar, setSearchBar] = useState(false);
  const returnStatusColor = (status) => {
    if (status == STATUS.completed) {
      return "completedd";
    } else {
      return "cancelled";
    }
  };
  const [transactionData, setTransactionData] = useState([
    {
      img: Assets.Order1,
      id: "RW55455",
      date: "25 Nov, 12:15",
      status: "completed",
      amount: "- $120.20",
      invoice_id: "WT3459",
      card_type: "Mastercard",
      card_number: "•••• 1234",
    },
    {
      img: Assets.Order1,
      id: "RW55623",
      date: "25 Nov, 12:15",
      status: "cancelled",
      amount: "+ $320.20",
      invoice_id: "WT3449",
      card_type: "Mastercard",
      card_number: "•••• 1234",
    },
    {
      img: Assets.Order1,
      id: "RW55621",
      date: "25 Nov, 12:15",
      status: "completed",
      amount: "- $220.20",
      invoice_id: "WT3453",
      card_type: "Visacard",
      card_number: "•••• 1234",
    },
  ]);
  return (
    <>
      <div className="main-transaction-history">
        <Container>
          <Row>
            <div className="heading-search-div">
              <div className="heading-div">
                <h5>Transaction History</h5>
              </div>
              <div className="svg-div">
                {searchBar ? (
                  <div className="searchbar-div" >
                    <InputGroup className="">
                      <InputGroup.Text id="basic-addon1">
                        <NewSearchIcon />
                      </InputGroup.Text>
                      <Form className="d-flex">
                        <Form.Control
                          type="search"
                          placeholder="Search"
                          className=""
                          aria-label="Search"
                        />
                      </Form>
                      <InputGroup.Text className="cross-icon" onClick={() => setSearchBar(false)}>
                        <NewCrossIcon />
                      </InputGroup.Text>
                    </InputGroup>
                  </div>
                ) : (
                  <Button onClick={() => setSearchBar(true)} >
                    <SearchIcon />
                  </Button>
                )}
              </div>
            </div>
          </Row>

          <div className="main-order-view">
            {/* <div className="top-header">
              <h5>Order ID</h5>
              <h5>Date & Time</h5>
              <h5>Status</h5>
              <h5>Total Amount</h5>
            </div>

            <div className="data-record">
              <div className="img-id-div">
                <div className="img-div">
                  <img className="img-fluid" src={Assets.Order1} />
                </div>
                <div className="order-id">
                  <p>RW5562</p>
                </div>
              </div>
              <div className="date-time-div">
                <span>25 Nov, 22</span> <span>12:15</span>
              </div>
              <div className="status-div">
                <p>Completed</p>
              </div>
              <div className="total-amount-div">
                <p>$240.40</p>
              </div>
              <div className="view-details-div">
                <NavLink to="">
                    View Details
                </NavLink>
              </div>
            </div> */}

            <Table>
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th className="text-center">Date & Time</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Total Amount</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {transactionData.map((data) => (
                  <tr>
                    <>
                      <td>
                        <div className="img-id-div">
                          <div className="img-div">
                            <img className="img-fluid" src={data.img} />
                          </div>
                          <div className="order-id">
                            <p>{data.id}</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="date-time-div">
                          <span>{data.date}</span>
                        </div>
                      </td>
                      <td>
                        <div className="status-div">
                          <p className={returnStatusColor(data.status)}>
                            {data.status}
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className="total-amount-div">
                          <p>{data.amount}</p>
                        </div>
                      </td>
                      <td>
                        <div className="view-details-div">
                          <NavLink
                            to={`/transaction-history/${data.id}`}
                            state={{ data: data }}
                          >
                            View Details
                          </NavLink>
                        </div>
                      </td>
                    </>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Container>
      </div>
    </>
  );
};

export default BuyerTransactionHistory;
