import {createApi,fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import { UserActionCalls } from '../reducers/UserSlice/UserSlice';

const baseQuery = fetchBaseQuery({
    baseUrl:process.env.REACT_APP_BASE_URL,
    credentials:'same-origin',
    prepareHeaders:(headers,{getState})=>{
        const token = getState().User.Token
        if(token){
            headers.set("authorization",`Bearer ${token}`)
            headers.set('x-access-token', token);
        }
        return headers;
    }
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)

    if (result?.error?.originalStatus === 403) {
        const { email, password, device_type,device_token } = api.getState().User.User;
        console.log('access token expired, requesting new token...')
        try {
            const loginResult = await baseQuery('/user/login', {
              method: 'POST',
              body: { email, password,device_type,device_token },
            })
            if (loginResult?.data) {
              api.dispatch(UserActionCalls.LogIn(loginResult.data))
              console.log('new token received:', loginResult.data.token)
              result = await baseQuery(args, api, extraOptions)
            } else {
              console.log('login failed')
              api.dispatch(UserActionCalls.LogOut())
            }
        } catch (err) {
            console.log('login failed', err)
            api.dispatch(UserActionCalls.LogOut())
        }
    }

    return result
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({})
})