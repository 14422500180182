import React from "react";
import SelectRole from "./AuthComponents/SelectRole";
import {NavLink} from "react-router-dom";

const GetStarted = () => {
    return (
        <>
            <h2>Welcome to Afrotierre</h2>
            <h4>
                Select Account Type <span></span>
            </h4>
            <h6 className="mt-2">
                Is your Afrotierre account <span>Buyer</span> or <span>Seller?</span>
            </h6>
            <div className="new-hr">
                <hr/>
            </div>
            <SelectRole/>

            <div className="d-flex align-items-center mt_30">
                <div className="already-account">Already have an account?</div>
                <div className="already-acc-link">
                    <NavLink to="/">SIGN IN</NavLink>
                </div>
            </div>
            <div className="as-guest">
                <NavLink to="/guest-home">Continue as a guest</NavLink>
            </div>
        </>
    );
};

export default GetStarted;
