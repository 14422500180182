import React, { useState } from "react";
import { BackIcon } from "../../utils/svg";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Assets } from "../../constant/images";
import { NavLink, useLocation } from "react-router-dom";
import PaymentCard from "../../components/Checkout/PaymentCard";

const ReturnOrderPayment = () => {
  return (
    <>
      <div className="main-order-return-payment">
        <Container>
          <Row>
            <div className="orderid-status-div">
              <div className="orderid-div">
                <div className="back-icon-div">
                  <NavLink to="/my-orders">
                    <BackIcon />
                  </NavLink>
                </div>
                <div className="order-id">
                  <h5>Return Order</h5>
                </div>
              </div>
            </div>
          </Row>

          <div className="product-detail-amount">
            <Row>
              <Col lg={6}>
                <div className="order-card-wrapper">
                  <div className="order-img-div">
                    <img className="img-fluid" src={Assets.Order1} />
                  </div>
                  <div className="order-info">
                    <p>
                      Engraved Gucci Watch <span>x2</span>
                    </p>
                  </div>
                </div>
              </Col>
              {/* <Col lg={6} className="my-auto">
                  <div className="amount-div">
                    <p>{orderinfo.price}</p>
                  </div>
                </Col> */}
            </Row>
          </div>

          <div className="return-shipment">
            <div className="d-flex justify-content-between">
              <h2>Select Payment Method</h2>
            </div>

            <div className="payment-checkbox">
              <Form>
                <Form.Check
                  type="checkbox"
                  id="stripe"
                  label={
                    <PaymentCard
                      img={Assets.Stripe}
                      cardtitle="Stripe"
                      status="(connected)"
                    />
                  }
                  name="payment"
                />
                <Form.Check
                  type="checkbox"
                  id="applepay"
                  label={
                    <PaymentCard
                      img={Assets.Applepay}
                      cardtitle="ApplePay"
                      code="**** 1234"
                    />
                  }
                  name="payment"
                />
                <Form.Check
                  type="checkbox"
                  id="mastercard"
                  label={
                    <PaymentCard
                      img={Assets.Mastercard}
                      cardtitle="Mastercard"
                      code="**** 1234"
                    />
                  }
                  name="payment"
                />
              </Form>
            </div>
          </div>

          <div className="product-sub-total">
            <Row>
              <Col lg={6}>
                <div className="sub-total-heading">
                  <h5>Subtotal</h5>
                </div>
              </Col>
              <Col lg={6} className="my-auto">
                <div className="amount-div">
                  <p>$120</p>
                </div>
              </Col>
            </Row>
          </div>

          <div className="product-shipping-rate">
            <Row>
              <Col lg={6}>
                <div className="shipping-rate-heading">
                  <h5>Shipping rate</h5>
                </div>
              </Col>
              <Col lg={6} className="my-auto">
                <div className="amount-div">
                  <p>$120</p>
                  <h5>(Est pickup. 14-16 Nov)</h5>
                </div>
              </Col>
            </Row>
          </div>

          <div className="product-total-amount">
            <Row>
              <Col lg={6}>
                <div className="total-amount-heading">
                  <h5>Total Refund Amount</h5>
                </div>
              </Col>
              <Col lg={6} className="my-auto">
                <div className="amount-div">
                  <p>$120</p>
                </div>
              </Col>
            </Row>
          </div>
          <div className="main-buttons-div">
            <div className=" buttons-div">
              <div className="right-div">
                <NavLink to="/transaction-history">Submit</NavLink>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default ReturnOrderPayment;
