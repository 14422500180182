import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { AppleIcon, AppleLogo, FbIcon, GoogleLogo } from '../../constant/svg'
import { LoginSocialGoogle,LoginSocialFacebook } from 'reactjs-social-login'
import {toast} from "react-toastify";
import { useDispatch } from "react-redux";
import {useSocialMutation} from '../../redux/reducers/UserSlice/UserApiSlice'
import { UserActionCalls } from "../../redux/reducers/UserSlice/UserSlice";
import {useNavigate} from "react-router-dom";



const SocialLogin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [social,{isLoading}] = useSocialMutation();

    const onSuccess = async (provider,data) => {
        const pcName = navigator.userAgent;
        const userData = {
            username: data.name,
            email: data.email,
            device_type: "web",
            device_token: pcName,
            platform:provider,
            client_id:'12345fsd32122122',
            role_id:'20'
        }

        try{
            const LoginUser = await social(userData).unwrap()
            dispatch(UserActionCalls.LogIn({...LoginUser}))
            if(LoginUser.data.is_verified){
                // console.log(LoginUser.data.role_id)
                toast.success('Thank you! You have successfully logged in');
                if(LoginUser.data.role_id !== '30'){
                    navigate('/home')

                }else {
                    navigate('/dashboard')
                }
                
            } else{
                toast.warning('Please Verify your account to proceed!');
                navigate('otpverify')
            }
            

        }catch(e){
            console.log(e);
              if (e.status === 401) {
                toast.error('Sorry! Your email and password are incorrect.');
            } else if (e.status === 400) {
                toast.error('Unauthorized');
            } else {
                toast.error('No Server Response');
            }
        }
    };

  return (
    <div className='social-login'>
        <div className='social-login-text'>or sign in with social accounts</div>
        <Row>
            <Col md={6}>
                <a target="_blank" href="https://appleid.apple.com/auth/authorize?client_id=com.beyondlive.services&redirect_uri=https%3A%2F%2Fauth.beyondlive.com%2Fusers%2Fapple%2Fsuccess&nonce=alsndklnalskdnlkanslkdn%3D%3D&response_type=code+id_token&scope=name+email&response_mode=form_post"><div><Button className="apple-btn"><AppleLogo/> <span>Continue with Apple</span></Button></div></a>
            </Col>
            <Col md={6}>
                <div>
                <LoginSocialGoogle
          client_id='712983263919-2pgqueutjmdp9lrrd8tqfapbdl9t0lr4.apps.googleusercontent.com'
          redirect_uri='http://localhost:3000/'
          scope="openid profile email"
          discoveryDocs="claims_supported"
          access_type="offline"
          onResolve={({ provider, data }) => {
            onSuccess(provider,data);
          }}
          onReject={err => {
            console.log(err);
          }}
        >
            <Button className="google-btn"><GoogleLogo/><span>Continue with Google</span></Button>
            </LoginSocialGoogle>
            </div>
            </Col>
            <Col md={6}>
                <div>
                <LoginSocialFacebook
          appId='560085319364303'
          fieldsProfile={
            'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
          }
          redirect_uri='http://localhost:3000/'
          onResolve={({ provider, data }) => {
            onSuccess(provider,data);
          }}
          onReject={err => {
            console.log(err);
          }}
        ><Button className="fb-btn"><FbIcon/><span>Continue with Facebook</span></Button>
        </LoginSocialFacebook>
        </div>
            </Col>
        </Row>
    </div>
  )
}

export default SocialLogin