import React, { useState } from "react";
import { BackIcon } from "../../utils/svg";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Assets } from "../../constant/images";
import { NavLink } from "react-router-dom";
import { NegativeIcon, PositiveIcon } from "../../constant/svg";

const ReturnOrderDEtail = (props) => {
  const [imageSrc, setImageSrc] = useState(null);

  const handleImageUpload = (event) => {
    const reader = new FileReader();
    reader.onload = () => {
      setImageSrc(reader.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const [value, setValue] = useState(1);

  const handleIncrement = () => {
    setValue(value + 1);
  };

  const handleDecrement = () => {
    if (value > 0) setValue(value - 1);
  };
  return (
    <>
      <div className="main-return-order-detail">
        <Container>
          <Row>
            <div className="orderid-status-div">
              <div className="orderid-div">
                <div className="back-icon-div">
                  <NavLink to="/my-orders">
                    <BackIcon />
                  </NavLink>
                </div>
                <div className="order-id">
                  <h5>Return Order</h5>
                </div>
              </div>
            </div>
          </Row>
          <div className="product-total-div">
            <Row>
              <Col lg={4}>
                <p>Product</p>
              </Col>
              <Col lg={3}>
                <p>Quntity</p>
              </Col>
              {/* <Col lg={6} className="offset-lg-2">
                <p>Total</p>
              </Col> */}
            </Row>
          </div>
          <div className="product-detail-amount">
            <Row>
              <Col lg={4}>
                <div className="order-card-wrapper">
                  <div className="order-img-div">
                    <img className="img-fluid" src={Assets.Order1} />
                  </div>
                  <div className="order-info">
                    <p>Engraved Gucci Watch </p>
                  </div>
                </div>
              </Col>
              <Col lg={3} className="my-auto">
                <div className="quantity-btn">
                  <div className="cursor-pointer" onClick={handleDecrement}>
                    <NegativeIcon />
                  </div>
                  <Form.Control type="hidden" id="quantity" value={value} />
                  <span>{value}</span>
                  <div className="cursor-pointer" onClick={handleIncrement}>
                    <PositiveIcon />
                  </div>
                </div>
              </Col>
              <Col lg={5} className="">
                <div className="amount-div">
                  <Form>
                    <div class="form-floating">
                      <select
                        class="form-select"
                        id="floatingSelect"
                        aria-label="Floating label select example"
                      >
                        <option selected>Other</option>
                        <option value="1">Fraud</option>
                        <option value="2">Spam</option>
                      </select>
                      <label for="floatingSelect">
                        Reason For Returning the Order
                      </label>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <div className="main-additional-detail">
        <Container>
          <Row>
            <Col xs={12}>
              <h4>Additional Details</h4>
              <Form>
                <div class="form-floating">
                  <textarea
                    class="form-control"
                    placeholder="Leave a comment here"
                    id="floatingTextarea"
                  ></textarea>
                  <label for="floatingTextarea">Comment</label>
                </div>
              </Form>
            </Col>
            <Col xs={12}>
              <h5>Upload Images</h5>
              <p>Take a picture of the product, invoice screenshots.</p>
              <div className="image-uploader">
                <label htmlFor="image-upload">
                  {imageSrc ? (
                    <img
                      src={imageSrc}
                      alt="uploaded-img"
                      className="img-fluid"
                    />
                  ) : (
                    <img
                      src={Assets.UploadImage}
                      alt="upload-img"
                      className="img-fluid"
                    />
                  )}
                </label>
                <input
                  type="file"
                  id="image-upload"
                  accept="image/*"
                  onChange={handleImageUpload}
                  style={{ display: "none" }}
                />
              </div>
              <div className="main-buttons-div">
                <div className=" buttons-div">
                  <div className="right-div">
                    <NavLink to="/my-orders/return-orders/payment">Submit</NavLink>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ReturnOrderDEtail;
