import { apiSlice } from "../../api/ApiSlice"

export const ShippingApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        addshipping: builder.mutation({
            query: credentials => ({
                url: '/shipping/add',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        getshipping: builder.query({
            query: id => ({
                url: `/shipping/all?per_page=20&page=1&order_by=id&order=-1&user_id=${id}`,
                providesTags: ['Shipping'],
            })
        }),
        updateshipping: builder.mutation({
            query: ({id,credentials}) => ({
                url: `/shipping/update/${id}`,
                method: 'PUT',
                body: credentials, 
            })
        }),
    })
})

export const {
    useAddshippingMutation,
    useGetshippingQuery,
    useUpdateshippingMutation
} = ShippingApiSlice