import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import React, {useState} from "react";
import { Assets } from "../../constant/images";
import { NavLink } from "react-router-dom";
import {
  CategoryIcon,
  ChatIcon,
  ChatIcon2,
  DashboardIcon,
  InventoryIcon,
  OrderIcon,
  ProductsIcon,
} from "../../constant/svg";

function OffcanvasHeader() {
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <>
      {[false].map((expand) => (
        <Navbar
          key={expand}
          bg="light"
          expand={expand}
          className="mb-3 d-lg-none"
        >
          <Container fluid>
            <Navbar.Brand href="#">
              {" "}
              <div className="logo-container">
                <img src={Assets.Logo} />
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={() => setMenuOpen(true)}/>
            <Navbar.Offcanvas
              show={menuOpen}
              onHide={() => setMenuOpen(false)}
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <div className="logo-container">
                    <img src={Assets.Logo} />
                  </div>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div className="dashboard-sidebar">
                  <ul>
                    <li className="mt_10">
                      <NavLink onClick={() => setMenuOpen(false)} to="/dashboard">
                        <div className="nav-bg">
                          <span>
                            <DashboardIcon />
                          </span>
                          <span className="ps-3">Dashboard</span>
                        </div>
                      </NavLink>
                    </li>
                    <li className="mt_10">
                      <NavLink onClick={() => setMenuOpen(false)} to="/orders">
                        <div className="nav-bg">
                          <span>
                            <OrderIcon />
                          </span>
                          <span className="ps-3">Orders</span>
                        </div>
                      </NavLink>
                    </li>
                    <li className="mt_10">
                      <NavLink onClick={() => setMenuOpen(false)} to="/category/category-table">
                        <div className="nav-bg">
                          <span>
                            <CategoryIcon />
                          </span>
                          <span className="ps-3">Category</span>
                        </div>
                      </NavLink>
                    </li>
                    <li className="mt_10">
                      <NavLink onClick={() => setMenuOpen(false)} to="/products/list">
                        <div className="nav-bg">
                          <span>
                            <ProductsIcon />
                          </span>
                          <span className="ps-3">Products</span>
                        </div>
                      </NavLink>
                    </li>
                    <li className="mt_10">
                      <NavLink onClick={() => setMenuOpen(false)} to="/inventory">
                        <div className="nav-bg">
                          <span>
                            <InventoryIcon />
                          </span>
                          <span className="ps-3">Inventory</span>
                        </div>
                      </NavLink>
                    </li>
                    <li className="mt_10">
                      <NavLink onClick={() => setMenuOpen(false)} to="/chat">
                        <div className="nav-bg">
                          <span>
                            <ChatIcon2 />
                          </span>
                          <span className="ps-3">Chat</span>
                        </div>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default OffcanvasHeader;
