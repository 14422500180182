import React, {useEffect, useContext, useState} from "react";
import {Container} from "react-bootstrap";
import {Assets} from "../constant/images";
import {Row, Col, Button} from "react-bootstrap";
import SelectRole from "./AuthComponents/SelectRole";
import OtpInput from "react-otp-input";
import {NavLink} from "react-router-dom";
import {useForm} from "react-hook-form";
import {Link, useNavigate} from "react-router-dom";
import {ToastContainer, toast} from 'react-toastify';
import { useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import {Form} from "react-bootstrap";
import { useDispatch,useSelector } from "react-redux";
import {useOtpverifyMutation,useResendotpMutation} from '../redux/reducers/UserSlice/UserApiSlice'
import { UserActionCalls } from "../redux/reducers/UserSlice/UserSlice";
import { selectCurrentUser } from "../redux/reducers/UserSlice/UserSlice"

const OtpVerification = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const user = useSelector(selectCurrentUser)
    const [otpverify,{isLoading}] = useOtpverifyMutation();
    const [resendotp] = useResendotpMutation();
    const [otp, setOtp] = useState("");
    const [counter, setCounter] = useState(30);
    const [oerror, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    let email;
    
    if (location.state && location.state.email) {
    email = location.state.email;
    } 
    
    if(user) {
    email = user.data ? user.data.email : user.email;
    }
    // const notify = () => toast(<OTPToast/>);

    useEffect(() => {
        let timer =
            counter > 0 &&
            setInterval(() => {
                setCounter(counter - 1);
            }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [counter]);

    const {
        register,
        handleSubmit,
        watch,
        formState: {errors},
    } = useForm({
        mode: "onChange",
    });

    const handleOtpChange = (otp) => {
        setOtp(otp);
        if (otp.length === 0) {
            setError(true);
            setErrorMessage("Please Enter OTP code");
        } else if (otp.length > 0 && otp.length < 5) {
            setError(true);
            setErrorMessage("OTP code is incomplete");
        } else {
            setError(false);
        }
    };


    const onSubmit = async (data) => {
        if (otp.length === 0) {
            setError(true);
            setErrorMessage("Please Enter OTP code");
        } else if (otp.length > 0 && otp.length < 5) {
            setError(true);
            setErrorMessage("OTP code is incomplete");
        }
        const userData = {
            email,
            type: "signUp",
            otp: otp,
        }
        try{
            const OtpUser = await otpverify(userData).unwrap()
            // console.log(LoginUser);
            dispatch(UserActionCalls.OtpVerify({...OtpUser}))
            if (location.state && location.state.email)
            {
                navigate('/reset-password', { state: { email:email }})

            }else {
                toast.success('Thank you! You have created an account successfully!');
            navigate('/')
            }
            

        }catch(e){
            console.log(e);
            toast.error(`${e.data.error}`);
        }

    }

    const reSendOPT= async ()=> {
        console.log(email);
        const userData = {
            email,
            type: "signUp",
        }
        try{
            const ResendOtp = await resendotp(userData).unwrap()
            // console.log(LoginUser);
            dispatch(UserActionCalls.ResendOtp({...ResendOtp}))
            toast.success('Otp has been sent to your Email!');
            navigate('/otpverify')

        }catch(e){
            console.log(e);
            toast.error(`${e.data.error}`);
        }
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div>
                <Row>
                    <Col md={8}>
                        <h6>
                            <b>Enter OTP</b>, You received the verification code at your email address.
                        </h6>
                    </Col>
                </Row>
                <div className="new-hr">
                    <hr/>
                </div>
                <div className="otp-div">
                    <OtpInput
                        className="otp"
                        value={otp}
                        numInputs={5}
                        onChange={(otp) => handleOtpChange(otp)}
                        containerStyle={false}
                        inputStyle={false}
                        focusStyle={false}
                        isInputNum={true}
                        separator={false}
                    />

                    <div className="timer">
                        {oerror && <small className="text-red-two ">{errorMessage}</small>}
                        <p className="">
                            {" "}
                            <span>
              00:{counter < 10 && "0"}
                                {counter}
            </span>
                        </p>
                    </div>
                    <div>
                        <Button type="submit" className="auth-btn">Verify</Button>

                    </div>
                    <div className="d-flex mt_30">
                        <div className="already-account">Didn't Get Code?</div>
                        <div className="already-acc-link">
                            <NavLink onClick={reSendOPT}>RESEND</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    );
};


export default OtpVerification;
