import React, { useState } from "react";
import { Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useForm } from "react-hook-form";
import { OpenIcon, CloseIcon } from "../../utils/svg";
import {
  SPECIAL_CHARACTER_ARRAY,
  SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
  VALIDATIONS,
  VALIDATIONS_TEXT,
  EXCLUDE_NUMBER_INPUT,
  EXCLUDE_ALPHABETS_SPECIAL_CHAR,
  SPECIAL_CHARACTER_NUMBER_ARRAY,
} from "../../constant/app-constants";
import {useChangepasswordMutation} from '../../redux/reducers/UserSlice/UserApiSlice'
import { UserActionCalls } from "../../redux/reducers/UserSlice/UserSlice";
import {useDispatch,useSelector } from "react-redux";
import {toast} from "react-toastify";
import { selectCurrentUser } from "../../redux/reducers/UserSlice/UserSlice";
import { useNavigate } from 'react-router-dom';

const ChangePassword = () => {
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const user = useSelector(selectCurrentUser)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [changepassword] = useChangepasswordMutation();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const password = watch("password");
  const onSubmit = async (data) => {

    const userData = {
      email:user.email,
      current_password: data.currentpassword,
      password: data.password,
      password_confirmation: data.password_confirmation,
      
  };
  try{
      const UpdateUser = await changepassword(userData).unwrap()
      console.log(UpdateUser);
      dispatch(UserActionCalls.ChangePassword({...UpdateUser}))
      toast.success('Password changed successfully!');
      navigate('/')

  }catch(e){
      console.log(e);
      toast.error(`${e.data.error}`);
  }
  };

  return (
    <div className="change-password-page">
      <Row>
        <Col md={6}>
          <h3>
            Your new password must be different from the previously used
            passwords.
          </h3>
          <Row>
            <Col md={10}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="position-realtive">
                  <FloatingLabel controlId="floatingInput" label="Current Password">
                    <Form.Control
                      type={!showCurrentPassword ? "password" : "text"}
                      placeholder="Enter your old password"
                      maxLength={VALIDATIONS_TEXT.PASSWORD_MAX}
                      {...register("currentpassword", {
                        minLength: {
                          value: VALIDATIONS.PASSWORD_MIN,
                          message: VALIDATIONS_TEXT.PASSWORD_MIN,
                        },
                        maxLength: {
                          value: VALIDATIONS.PASSWORD_MAX,
                          message: VALIDATIONS_TEXT.PASSWORD_MAX,
                        },
                        required: {
                          value: true,
                          message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                        },
                        pattern: {
                          value: /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/,
                          message: VALIDATIONS_TEXT.PASSWORD_FORMAT,
                        },
                      })}
                    />
                    {errors.currentpassword && (
                      <small className="text-red error_from error_from">
                        {errors.currentpassword.message}
                      </small>
                    )}

                    <span className="iconfeild">
                      {showCurrentPassword ? (
                        <span
                          className="toggle-password"
                          onClick={() => setShowCurrentPassword(false)}
                        >
                          <OpenIcon />
                        </span>
                      ) : (
                        <span
                          onClick={() => setShowCurrentPassword(true)}
                          className="toggle-password"
                        >
                          <CloseIcon />
                        </span>
                      )}
                    </span>
                  </FloatingLabel>
                  {/* <InputGroup className="password-input">
                  <Form.Control
                    placeholder="Current Password"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                  />
                  <InputGroup.Text id="basic-addon2">
                    
                  </InputGroup.Text>
                </InputGroup> */}
                </div>

                <div className="position-realtive">
                  <FloatingLabel controlId="floatingInput" label="New Password">
                    <Form.Control
                      type={!showNewPassword ? "password" : "text"}
                      placeholder="Enter your new password"
                      maxLength={VALIDATIONS_TEXT.PASSWORD_MAX}
                      {...register("password", {
                        minLength: {
                          value: VALIDATIONS.PASSWORD_MIN,
                          message: VALIDATIONS_TEXT.PASSWORD_MIN,
                        },
                        maxLength: {
                          value: VALIDATIONS.PASSWORD_MAX,
                          message: VALIDATIONS_TEXT.PASSWORD_MAX,
                        },
                        required: {
                          value: true,
                          message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                        },
                        pattern: {
                          value: /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/,
                          message: VALIDATIONS_TEXT.PASSWORD_FORMAT,
                        },
                      })}
                    />
                    {errors.password && (
                      <small className="text-red error_from error_from">
                        {errors.password.message}
                      </small>
                    )}

                    <span className="iconfeild">
                      {showNewPassword ? (
                        <span
                          className="toggle-password"
                          onClick={() => setShowNewPassword(false)}
                        >
                          <OpenIcon />
                        </span>
                      ) : (
                        <span
                          onClick={() => setShowNewPassword(true)}
                          className="toggle-password"
                        >
                          <CloseIcon />
                        </span>
                      )}
                    </span>
                  </FloatingLabel>
                  {/* <InputGroup className="password-input">
                  <Form.Control
                    placeholder="Current Password"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                  />
                  <InputGroup.Text id="basic-addon2">
                        
                  </InputGroup.Text>
                </InputGroup> */}
                </div>
                {/* <InputGroup className="password-input mt_20">
                  <Form.Control
                    placeholder="New Password"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                  />
                  <InputGroup.Text id="basic-addon2">
                    
                  </InputGroup.Text>
                </InputGroup> */}

                <div className="position-realtive">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Confirm Password"
                  >
                    <Form.Control
                      type={!showConfirmPassword ? "password" : "text"}
                      placeholder="Confirm new password"
                      maxLength={VALIDATIONS_TEXT.PASSWORD_MAX}
                      {...register("password_confirmation", {
                        minLength: {
                          value: VALIDATIONS.PASSWORD_MIN,
                          message: VALIDATIONS_TEXT.PASSWORD_MIN,
                        },
                        maxLength: {
                          value: VALIDATIONS.PASSWORD_MAX,
                          message: VALIDATIONS_TEXT.PASSWORD_MAX,
                        },
                        required: {
                          value: true,
                          message: VALIDATIONS_TEXT.CONFIRM_PASSWORD_REQUIRED,
                        },
                        validate: (value) =>
                          value === password ||
                          "Password and Confirm Password must be same",
                      })}
                    />
                    {errors.password_confirmation && (
                      <small className="text-red error_from">
                        {errors.password_confirmation.message}
                      </small>
                    )}

                    <span className="iconfeild">
                      {showConfirmPassword ? (
                        <span
                          className="toggle-password"
                          onClick={() => setShowConfirmPassword(false)}
                        >
                          <OpenIcon />
                        </span>
                      ) : (
                        <span
                          onClick={() => setShowConfirmPassword(true)}
                          className="toggle-password"
                        >
                          <CloseIcon />
                        </span>
                      )}
                    </span>
                  </FloatingLabel>
                  {/* <InputGroup className="password-input">
                  <Form.Control
                    placeholder="Current Password"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                  />
                  <InputGroup.Text id="basic-addon2">
                    
                  </InputGroup.Text>
                </InputGroup> */}
                </div>
                {/* <InputGroup className="password-input mt_20">
                  <Form.Control
                    placeholder="Confirm Password"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                  />
                  <InputGroup.Text id="basic-addon2"></InputGroup.Text>
                </InputGroup> */}

                <div className="d-flex justify-content-end">
                  <Button type="submit" className="reset-pass-btn">Reset Password</Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ChangePassword;
