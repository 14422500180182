import { createSlice } from "@reduxjs/toolkit";
import CountryAction from "../../actions/CountryAction";

const initialState={
    Country:[],
}

export const CountrySlice = createSlice({
    name:'Country',
    initialState,
    reducers:CountryAction,
})

export const CountryActionCalls = CountrySlice.actions;

export default CountrySlice.reducer; 

export const selectCountry = (state)=>state.Country.Country;