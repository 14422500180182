import React, { useState,useEffect } from "react";
import { Col, FloatingLabel, Row } from "react-bootstrap";
import { Assets } from "../../constant/images";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { NavLink, useNavigate,useLocation } from "react-router-dom";
import { SignupUpload } from "../../utils/svg";
import signupupload from "../../../src/assets/images/Auth/signupupload.png";
import { useForm } from "react-hook-form";
import {useDispatch } from "react-redux";
import { useGetcountryQuery } from "../../redux/reducers/CountrySlice/CountryApiSlice";
import { CountryActionCalls } from "../../redux/reducers/CountrySlice/CountrySlice";
import { useGetcityQuery } from "../../redux/reducers/CitySlice/CityApiSlice";
import { useGetstateQuery } from "../../redux/reducers/StateSlice/StateApiSlice";
import {useUpdateprofileMutation} from '../../redux/reducers/UserSlice/UserApiSlice'
import { UserActionCalls } from "../../redux/reducers/UserSlice/UserSlice";
import {
  SPECIAL_CHARACTER_ARRAY,
  SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
  VALIDATIONS,
  VALIDATIONS_TEXT,
  EXCLUDE_NUMBER_INPUT,
  EXCLUDE_ALPHABETS_SPECIAL_CHAR,
  SPECIAL_CHARACTER_NUMBER_ARRAY,
} from "../../constant/app-constants";
import { OpenIcon, CloseIcon } from "../../utils/svg";
import PhoneInput from "react-phone-number-input";
import { ToastContainer, toast } from 'react-toastify';

const MyProfile = () => {
  const [editprofile, setEditProfile] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const [updateprofile] = useUpdateprofileMutation();
  const {data} = location.state.profile;
  const[countryId,setCountryId] = useState(null);
    const[stateId,setStateId] = useState(null);
    const { data: countries, isLoading: countryLoading } = useGetcountryQuery();
    const { data: states, isLoading: stateLoading, isFetching: stateFetching } = useGetstateQuery(countryId);
    const { data: cities, isLoading: cityLoading, isFetching: cityFetching } = useGetcityQuery(stateId);
    const [country, setCountry] = useState([]);
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);
    const getCountry = async () => {
      try {
        if (!countryLoading) {
          dispatch(CountryActionCalls.getCountry(countries));
          setCountry(countries?.data || []);
        }
      } catch (e) {
        console.log(e);
      }
    };
    
    const handleCountrySelect = (id) => {
      setCountryId(id);
    };
    
    const handleStateSelect = (id) => {
      setStateId(id);
    };
    
    useEffect(() => {
      if (states?.data) {
        setState(states?.data || []);
      }
    }, [states?.data, stateFetching]);
    
    useEffect(() => {
      if (cities?.data) {
        setCity(cities?.data || []);
      }
    }, [cities?.data, cityFetching]);
    
    useEffect(() => {
      getCountry();
    }, [countryLoading]);

  const [profilePic, setProfilePic] = useState({
    file: null,
    file_url: null,
  });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",

  });

  const password = watch("password");
  
  const navigate = useNavigate();
  const onSubmit = async (e) => {

    const names = e.fname.split(' ');
    const userData = {
      first_name: names[0],
      last_name: names[1],
      phone: e.phone,
      store_name : e.storename ? e.storename : "",
      username:data.username,
      country_id: e.country,
      state_id:e.state,
      city_id: e.city,
      zip_code: e.zip_code,
      address: e.address,
      role_id: data.role_id,
  };
  console.log(userData);
  console.log(data._id);
  try{
      const UpdateUser = await updateprofile( {id: data._id, credentials:userData} )
      console.log(UpdateUser);
      dispatch(UserActionCalls.UpdateProfile({...UpdateUser}))
      toast.success('Profile updated successfully!');
      navigate('/my-profile')

  }catch(e){
      console.log(e);
      toast.error(`${e.data.error}`);
  }
  };

  useEffect(() => {
    reset({
      fname: data.first_name +" "+ data.last_name,
      storename: data.store_name,
      email: data.email,
      phone: data.phone,
      address: data.address,
      country: data.country?._id,
      city: data.city?._id,
      zip_code: data.zip_code,
      state: data.state?._id
    });
  }, []);
  return (
    <>
    <ToastContainer    hideProgressBar={true}/>
    <div className="all-profile-heading">My Profile</div>
    <div className="my-profile">
      <Row>
        {/* <Col md={3}>
          <div className="profile-data">
            <div className="image-container">
              <img src={Assets.ProfileImg} />
            </div>
            <div className="profile-title">The Half Moon</div>
            <div className="profile-occupation">Store Manager</div>
            <div className="profile-mail">thehalfmoon@gmail.com</div>
          </div>
        </Col> */}
        <Col md={12}>
          <div className="profile-form">
          <Row>
            <Col>
              <div className="img-div mb-0">
                <label htmlFor="img-upload" className="file-upload">
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    id="img-upload"
                    name="avatar"
                    className="d-none"
                    onChange={(e) =>
                      setProfilePic({
                        file: e.target.files[0],
                        file_url: URL.createObjectURL(e.target.files[0]),
                      })
                    }
                  />
                  <img
                    src={
                      profilePic.file_url ? profilePic.file_url : signupupload
                    }
                    alt=""
                    className="w-100 h-100 img-upload"
                  />
                </label>
              </div>
            </Col>
          </Row>
            <Row>
              <Col md={6}>
                <div className="signup-form mt_20">
                  <Form  onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                    <Col md={6}>
              <FloatingLabel controlId="floatingInput" label="Your Name">
                <Form.Control
                  placeholder="FirstName"
                  type="text"
                  onKeyDown={(e) =>
                    EXCLUDE_NUMBER_INPUT.includes(e.key) && e.preventDefault()
                  }
                  maxLength={VALIDATIONS.NAME}
                  {...register("fname", {
                    maxLength: {
                      value: VALIDATIONS.NAME,
                      message: VALIDATIONS_TEXT.NAME_MAX,
                    },
                    pattern: {
                      value: /^[a-zA-Z\s]*$/,
                      message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS,
                    },
                    required: {
                      value: true,
                      message: VALIDATIONS_TEXT.FIRST_NAME_REQUIRED,
                    },
                  })}
                />
                {errors.fname && (
                  <small className="text-red">{errors.fname.message}</small>
                )}
              </FloatingLabel>
                    </Col>
                    <Col md={6}>
              <FloatingLabel controlId="floatingInput" label="Store Name">
                <Form.Control
                  placeholder="Name"
                  type="text"
                  onKeyDown={(e) =>
                    EXCLUDE_NUMBER_INPUT.includes(e.key) && e.preventDefault()
                  }
                  maxLength={VALIDATIONS.STORE_NAME}
                  {...register("storename", {
                    maxLength: {
                      value: VALIDATIONS.STORE_NAME,
                      message: VALIDATIONS_TEXT.STORE_NAME_MAX,
                    },
                    pattern: {
                      value: /^[a-zA-Z\s]*$/,
                      message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS,
                    },
                    required: {
                      value: true,
                      message: VALIDATIONS_TEXT.STORE_NAME_REQUIRED,
                    },
                  })}
                />
                {errors.storename && (
                  <small className="text-red">{errors.storename.message}</small>
                )}
              </FloatingLabel>
            </Col>
                    </Row>
                    <Row>
                    <Col md={6}>
              <FloatingLabel controlId="floatingInput" label="Email Address">
                <Form.Control
                disabled
                  type="email"
                  placeholder="Email Address"
                  maxLength={VALIDATIONS.EMAIL}
                  {...register("email", {
                    maxLength: {
                      value: VALIDATIONS.EMAIL,
                      message: VALIDATIONS_TEXT.EMAIL_MAX,
                    },
                    required: {
                      value: true,
                      message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                    },
                    pattern: {
                      value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                      message: VALIDATIONS_TEXT.EMAIL_FORMAT,
                    },
                  })}
                />
                {errors.email && (
                  <small className="text-red error_from">
                    {errors.email.message}
                  </small>
                )}
              </FloatingLabel>
            </Col>
            <Col md={6}>
            <FloatingLabel controlId="floatingInput" label="Phone Number">
                    <Form.Control
                      type="tel"
                      placeholder="+01-143553464"
                      onKeyDown={(e) => {
                        EXCLUDE_ALPHABETS_SPECIAL_CHAR.includes(
                          e.key.toUpperCase()
                        ) && e.preventDefault();
                      }}
                      maxLength={VALIDATIONS.PHONE_MAX}
                      {...register("phone", {
                        pattern: {
                          value:
                            /^[\+]?[(]?[0-9]{2}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                          message: VALIDATIONS_TEXT.INVALID_PHONE_FORMAT,
                        },
                        minLength: {
                          value: VALIDATIONS.PHONE_MIN,
                          message: VALIDATIONS_TEXT.PHONE_MIN,
                        },
                        maxLength: {
                          value: VALIDATIONS.PHONE_MAX,
                          message: VALIDATIONS_TEXT.PHONE_MAX,
                        },
                        required: {
                          value: true,
                          message: VALIDATIONS_TEXT.PHONE_REQUIRED,
                        },
                      })}
                    />
                    {errors.phone && (
                      <small className="text-red">{errors.phone.message}</small>
                    )}
                  </FloatingLabel>
            </Col>
                    </Row>
                    <Row>
                    <Col md={6}>
              <FloatingLabel controlId="floatingInput" label="Address">
                <Form.Control type="text" placeholder="name@example.com"
                                {...register("address", {
                                  required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.ADDRESS_REQUIRED,
                                  },
                                })}
                              />
                              {errors.address && (
                                <small className="text-red error_from">
                                  {errors.address.message}
                                </small>
                              )}
              </FloatingLabel>
            </Col>
            <Col md={6}>
              <div class="form-floating">
                <select
                  class="form-select"
                  id="floatingSelect"
                  aria-label="Floating label select example"
                  {...register("country", {
                    required: {
                      value: true,
                      message: VALIDATIONS_TEXT.COUNTRY_NAME_REQUIRED,
                    },
                    onChange: (e) => {
                      handleCountrySelect(e.target.value);
                    },
                  })}
                >
                  {!countryLoading && country.map((data) => (
                        <option key={data.id} value={data._id}>
                          {data.name}
                        </option>
                      ))}
                </select>
                <label for="floatingSelect">Country</label>
              </div>
            </Col>
                    </Row>
                    <Row>
                    <Col md={6}>
                    <div class="form-floating">
                                <select
                                    class="form-select mb-3"
                                    id="floatingSelect"
                                    aria-label="Floating label select example"
                                    {...register("state", {
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.STATE_REQUIRED,
                                        },
                                        onChange: (e) => {
                                            handleStateSelect(e.target.value);
                                          },
                                    })}
                                >
                                    { state.length !== 0 && state.map((data) => (
                                        <option key={data.id} value={data._id}>
                                            {data.name}
                                        </option>
                                    ))}
                                </select>
                                <label for="floatingSelect">State *</label>
                            </div>
            </Col>
            <Col md={6}>
            <div class="form-floating">
                                <select
                                    class="form-select mb-3"
                                    id="floatingSelect"
                                    aria-label="Floating label select example"
                                    {...register("city", {
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.CITY_REQUIRED,
                                        },
                                    })}
                                >
                                    {city.length !== 0 && city.map((data) => (
                                        <option key={data.id} value={data._id}>
                                            {data.name}
                                        </option>
                                    ))}
                                </select>
                                <label for="floatingSelect">City *</label>
                            </div>
            </Col>
                    </Row>
                    <Row>
                    <Col md={6}>
                  <FloatingLabel controlId="floatingInput" label="Zip Code">
                    <Form.Control
                      type="tel"
                      placeholder="12345"
                      onKeyDown={(e) => {
                        EXCLUDE_ALPHABETS_SPECIAL_CHAR.includes(
                          e.key.toUpperCase()
                        ) && e.preventDefault();
                      }}
                      maxLength={VALIDATIONS.ZIP_CODE}
                      {...register("zip_code", {
                        pattern: {
                          value: /^\d+$/,
                          message:
                            VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS_ZIP,
                        },
                        maxLength: {
                          value: VALIDATIONS.ZIP_CODE,
                          message: VALIDATIONS_TEXT.ZIP_MAX,
                        },
                        required: {
                          value: true,
                          message: VALIDATIONS_TEXT.ZIP_CODE_REQUIRED,
                        },
                      })}
                    />
                    {errors.zip_code && (
                      <small className="text-red">
                        {errors.zip_code.message}
                      </small>
                    )}
                  </FloatingLabel>
                </Col>
                    </Row>
                    <div className="d-flex justify-content-end">
                      <Button type="submit" className="update-btn">Save</Button>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
    </>
  );
};

export default MyProfile;
