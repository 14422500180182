import { React, useState ,useEffect} from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import ProductCard from "../../components/ProductCard/ProductCard";
import {
  Row,
  Col,
  Container,
  Dropdown,
  Offcanvas,
  Form,
} from "react-bootstrap";
import { Assets } from "../../constant/images";
import ShopNowSlider from "../../components/SwiperSlider/ShopNowSlider";
import { Button } from "react-bootstrap";
import {
  CategoryCrossIcon,
  FilterIcon,
  GridViewIcon,
  ListViewIcon,
} from "../../constant/svg";
import ProductListingCard from "../../components/ProductCard/ProductListingCard";
import ProductListingData from "./ProductListingData";
import { FloatingLabel } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { NavLink ,useLocation} from "react-router-dom";
import {useGetallproductQuery} from '../../redux/reducers/ProductSlice/ProductApiSlice.js'
import { ProductActionCalls } from "../../redux/reducers/ProductSlice/ProductSlice";
import {useDispatch} from 'react-redux';
import {TailSpin} from "react-loader-spinner";

const ProductListing = (props) => {
  let active = 1;
  let items = [];
  for (let number = 1; number <= 3; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  // const [productdata, setProductData] = useState(ProductListingData);
  // console.log("......sadas/", productdata);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const location = useLocation();

  const name = location.state?.name;
  const sub_id = location.state?.sub_id;

  const [listview, setListView] = useState(false);
  const dispatch = useDispatch();
  const { data: products, isLoading: productLoading } = useGetallproductQuery(sub_id);
  const[allProduct,setAllProduct] = useState([]);

    const getAllProducts= async()=>{
        try{
            if (!productLoading) {
                dispatch(ProductActionCalls.GetAllProduct({...products}));
                setAllProduct(products?.data || []);
              }

        }catch(e){
            console.log(e);
        }
    }

    useEffect(()=>{
      getAllProducts();

    },[productLoading])


    // console.log(allProduct);

  return (
    <>
      <div className="main-section banner-bg">
        <Container>
          <Row>
            <Col md={12}>
              <div className="product-listing-banner">
                <div className="banner-container">
                  <div className="banner-title">Everything for your Home</div>
                  <div className="banner-desc">
                    New arrivals from handmade masters,Ceramic and aluminum in
                    bright yellow
                  </div>
                  <div>
                    <NavLink to="/product-listing">
                      {" "}
                      <Button className="banner-button">Shop Now</Button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="main-section">
        <div className="product-listing-section">
          <Container>
            {/* Shop */}
            <div className="section-heading">{name}</div>
            <div className="d-block d-sm-flex align-items-center justify-content-between mb_30">
              <div>
                <Button className="filter-btn" onClick={handleShow}>
                  <span className="px-2">All Filters</span> <FilterIcon />
                </Button>
              </div>
              <div className="d-block d-sm-flex mt-3 mt-sm-0 ps-0">
                <Dropdown className="">
                  <Dropdown.Toggle id="filter-dropdown">
                    Sort by: <b>Default Sorting</b>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>A-Z</Dropdown.Item>
                    <Dropdown.Item>Z-A</Dropdown.Item>
                    <Dropdown.Item>Top Sales</Dropdown.Item>
                    <Dropdown.Item>Top Rated</Dropdown.Item>
                    <Dropdown.Item>Price (High to Low)</Dropdown.Item>
                    <Dropdown.Item>Price (Low to High)</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <div className="d-flex align-items-center mt-3 mt-sm-0">
                  <div className="view">View: </div>
                  <Form>
                    <div className="list-grid-view">
                      <div>
                        <Form.Check
                          value="shopping"
                          name="role"
                          type="radio"
                          id="shopping"
                        />
                        <label>
                          <div>
                            <Button
                              className={listview ? "view-btn" : "view-btn  bg-yellow"}
                              onClick={() => setListView(false)}
                            >
                              <GridViewIcon />
                            </Button>
                          </div>
                        </label>
                      </div>

                      <div>
                        <Form.Check
                          value="brand_owner"
                          name="role"
                          type="radio"
                          id="brand-owner"
                        />
                        <label>
                          <div>
                            <Button
                              className={listview ? "view-btn  bg-yellow" : "view-btn"}

                              onClick={() => setListView(true)}
                            >
                              <ListViewIcon />
                            </Button>
                          </div>
                        </label>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>

            <Row>
              { allProduct.length!== 0 ? allProduct.data.map((data, index) => (
                <ProductListingCard
                  key={index}
                  data={data}
                  listview={listview}
                />
              )):<div className="loader">
              <TailSpin
                  height="30"
                  width="30"
                  color="#dbbb51"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
              />
          </div>}
            </Row>
            <Row>
              <div className="pagination ps-3">
                <Pagination>{items}</Pagination>
              </div>
            </Row>
          </Container>
        </div>
      </div>
      <div>
        <Offcanvas
          show={show}
          onHide={handleClose}
          className="filter-btn-offcanvas"
        >
          <Offcanvas.Body>
            <div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter-heading">Filters</div>
                <div onClick={handleClose}>
                  <CategoryCrossIcon />
                </div>
              </div>
              <hr />
              <div className="filter-conditions-section">
                <div className="subheading">Sort By</div>
                <Form>
                  <Form.Check
                    type="checkbox"
                    id="capital"
                    label="A-Z"
                    name="capital"
                  />
                  <Form.Check
                    type="checkbox"
                    id="reverse"
                    label="Z-A"
                    name="reverse"
                  />
                  <Form.Check
                    type="checkbox"
                    label="Top Sales"
                    id="sales"
                    name="sales"
                  />
                  <Form.Check
                    type="checkbox"
                    label="Top Rated"
                    id="rated"
                    name="rated"
                  />
                </Form>
                <div className="subheading">Price ($)</div>
                <Form>
                  <Form.Check
                    type="radio"
                    id="one"
                    label="Any Price"
                    name="one"
                  />
                  <Form.Check
                    type="radio"
                    id="two"
                    label="Under USD 25"
                    name="one"
                  />
                  <Form.Check
                    type="radio"
                    label="USD 25 to USD 50"
                    id="three"
                    name="one"
                  />
                  <Form.Check
                    type="radio"
                    label="USD 50 to USD 100"
                    id="four"
                    name="one"
                  />
                  <Form.Check
                    type="radio"
                    label="Over USD 100"
                    id="five"
                    name="one"
                  />
                  <Form.Check type="radio" label="Custom" id="six" name="one" />
                  <div className="custom">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control type="text" placeholder="High" />
                    </Form.Group>
                    <div className="to">To</div>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control type="text" placeholder="Low" />
                    </Form.Group>
                  </div>
                </Form>
                <div className="subheading">Origin</div>
                <Form>
                  <Form.Check
                    type="radio"
                    id="onea"
                    label="Anywhere"
                    name="one"
                  />
                  <Form.Check type="radio" id="twob" label="USA" name="one" />
                  <Form.Check
                    type="radio"
                    label="Custom"
                    id="threec"
                    name="one"
                  />
                  <div className="custom origin">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control type="text" placeholder="Made in" />
                    </Form.Group>
                  </div>
                </Form>
              </div>
            </div>
            <div className="btn-pos">
              <hr />
            </div>
            <div className="d-block d-sm-flex align-items-center justify-content-between">
              <div>
                <Button onClick={handleClose} className="cancel-btn">Cancel</Button>
              </div>
              <div>
                <Button onClick={handleClose} className="apply-btn mt-3 mt-sm-0">Apply</Button>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default ProductListing;
