import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import { UserActionCalls } from "../../redux/reducers/UserSlice/UserSlice";
import { useDispatch,useSelector } from "react-redux";
import {useLogoutMutation} from '../../redux/reducers/UserSlice/UserApiSlice'
import { selectCurrentToken } from "../../redux/reducers/UserSlice/UserSlice"
import {TailSpin} from "react-loader-spinner";


function LogoutModal(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector(selectCurrentToken)

    const [logout,{isLoading}] = useLogoutMutation();

     const sayLogout = async ()=> {

        const Data={
            device_type:"android",
            device_token:"777"
        }
        // console.log(token);
        try{
            const LoggedOut = await logout(Data).unwrap()
            dispatch(UserActionCalls.LogOut({...LoggedOut}))
            toast.success('Thank you, Have a good day!');
            navigate('/')
            
        }catch(e){
            console.log(e);
            toast.error(`${e.data.error}`);
        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="delete-modal"
        >
            <div className="main-heading">{props.heading}</div>
            <p>Are you sure you want to {props.type}?</p>
            <div className="mt_30 d-flex justify-content-end">
                <Button onClick={props.onHide} className="sub-btn">Cancel</Button>
                {/*<NavLink to={props.link}><Button className="main-btn">{props.btntext}</Button></NavLink>*/}
                {!isLoading ?
                <Button className="main-btn" onClick={sayLogout}>{props.btntext}</Button>:
                <div>
                                    <TailSpin
                                        height="30"
                                        width="30"
                                        color="#dbbb51"
                                        ariaLabel="tail-spin-loading"
                                        radius="1"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                    />
                                </div>
                                }

            </div>

        </Modal>
    );
}

export default LogoutModal;
