import {Assets} from "../../constant/images";


const ProductListingData=[

    {
        id:1,
        className:"mt_30",
        productimg:require("../../assets/images/ProductListing/list1.png"),
        price:"$122",
        originalprice:"$154",
        desc:"Lorem Ipsum is simply dummy text of the printing and of the typesetting industry.",

    },
    {
        id:2,
        className:"mt_30",
        productimg:require("../../assets/images/ProductListing/list2.png"),
        price:"$122",
        originalprice:"$154",
        desc:"Lorem Ipsum is simply dummy text of the printing and of the typesetting industry.",

    },
    {
        id:3,
        className:"mt_30",
        productimg:require("../../assets/images/ProductListing/list3.png"),
        price:"$122",
        originalprice:"$154",
        desc:"Lorem Ipsum is simply dummy text of the printing and of the typesetting industry.",

    },    {
        id:4,
        className:"mt_30",
        productimg:require("../../assets/images/ProductListing/list4.png"),
        price:"$122",
        originalprice:"$154",
        desc:"Lorem Ipsum is simply dummy text of the printing and of the typesetting industry.",

    },
    {
        id:5,
        className:"mt_30",
        productimg:require("../../assets/images/ProductListing/list5.png"),
        price:"$122",
        originalprice:"$154",
        desc:"Lorem Ipsum is simply dummy text of the printing and of the typesetting industry.",

    },
    {
        id:6,
        className:"mt_30",
        productimg:require("../../assets/images/ProductListing/list6.png"),
        price:"$122",
        originalprice:"$154",
        desc:"Lorem Ipsum is simply dummy text of the printing and of the typesetting industry.",

    },
    {
        id:7,
        className:"mt_30",
        productimg:require("../../assets/images/ProductListing/list2.png"),
        price:"$122",
        originalprice:"$154",
        desc:"Lorem Ipsum is simply dummy text of the printing and of the typesetting industry.",

    },
    {
        id:8,
        className:"mt_30",
        productimg:require("../../assets/images/ProductListing/list8.png"),
        price:"$122",
        originalprice:"$154",
        desc:"Lorem Ipsum is simply dummy text of the printing and of the typesetting industry.",

    },
    {
        id:9,
        className:"mt_30",
        productimg:require("../../assets/images/ProductListing/list9.png"),
        price:"$122",
        originalprice:"$154",
        desc:"Lorem Ipsum is simply dummy text of the printing and of the typesetting industry.",

    },
    {
        id:10,
        className:"mt_30",
        productimg:require("../../assets/images/ProductListing/list10.png"),
        price:"$122",
        originalprice:"$154",
        desc:"Lorem Ipsum is simply dummy text of the printing and of the typesetting industry.",

    },
    {
        id:11,
        className:"mt_30",
        productimg:require("../../assets/images/ProductListing/list11.png"),
        price:"$122",
        originalprice:"$154",
        desc:"Lorem Ipsum is simply dummy text of the printing and of the typesetting industry.",

    },
    {
        id:12,
        className:"mt_30",
        productimg:require("../../assets/images/ProductListing/list12.png"),
        price:"$122",
        originalprice:"$154",
        desc:"Lorem Ipsum is simply dummy text of the printing and of the typesetting industry.",

    },
    {
        id:13,
        className:"mt_30",
        productimg:require("../../assets/images/ProductListing/list13.png"),
        price:"$122",
        originalprice:"$154",
        desc:"Lorem Ipsum is simply dummy text of the printing and of the typesetting industry.",

    },
    {
        id:14,
        className:"mt_30",
        productimg:require("../../assets/images/ProductListing/list14.png"),
        price:"$122",
        originalprice:"$154",
        desc:"Lorem Ipsum is simply dummy text of the printing and of the typesetting industry.",

    },
    {
        id:15,
        className:"mt_30",
        productimg:require("../../assets/images/ProductListing/list15.png"),
        price:"$122",
        originalprice:"$154",
        desc:"Lorem Ipsum is simply dummy text of the printing and of the typesetting industry.",

    },
    {
        id:16,
        className:"mt_30",
        productimg:require("../../assets/images/ProductListing/list16.png"),
        price:"$122",
        originalprice:"$154",
        desc:"Lorem Ipsum is simply dummy text of the printing and of the typesetting industry.",

    },
    {
        id:17,
        className:"mt_30",
        productimg:require("../../assets/images/ProductListing/list17.png"),
        price:"$122",
        originalprice:"$154",
        desc:"Lorem Ipsum is simply dummy text of the printing and of the typesetting industry.",

    },
    {
        id:18,
        className:"mt_30",
        productimg:require("../../assets/images/ProductListing/list18.png"),
        price:"$122",
        originalprice:"$154",
        desc:"Lorem Ipsum is simply dummy text of the printing and of the typesetting industry.",

    },
    {
        id:19,
        className:"mt_30",
        productimg:require("../../assets/images/ProductListing/list19.png"),
        price:"$122",
        originalprice:"$154",
        desc:"Lorem Ipsum is simply dummy text of the printing and of the typesetting industry.",

    },
    {
        id:20,
        className:"mt_30",
        productimg:require("../../assets/images/ProductListing/list20.png"),
        price:"$122",
        originalprice:"$154",
        desc:"Lorem Ipsum is simply dummy text of the printing and of the typesetting industry.",

    },
    {
        id:21,
        className:"mt_30",
        productimg:require("../../assets/images/ProductListing/list21.png"),
        price:"$122",
        originalprice:"$154",
        desc:"Lorem Ipsum is simply dummy text of the printing and of the typesetting industry.",

    },
    {
        id:22,
        className:"mt_30",
        productimg:require("../../assets/images/ProductListing/list22.png"),
        price:"$122",
        originalprice:"$154",
        desc:"Lorem Ipsum is simply dummy text of the printing and of the typesetting industry.",

    },
    {
        id:23,
        className:"mt_30",
        productimg:require("../../assets/images/ProductListing/list23.png"),
        price:"$122",
        originalprice:"$154",
        desc:"Lorem Ipsum is simply dummy text of the printing and of the typesetting industry.",

    },
    {
        id:24,
        className:"mt_30",
        productimg:require("../../assets/images/ProductListing/list24.png"),
        price:"$122",
        originalprice:"$154",
        desc:"Lorem Ipsum is simply dummy text of the printing and of the typesetting industry.",

    },
    
]

export default ProductListingData;