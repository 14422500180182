import { apiSlice } from "../../api/ApiSlice"

export const UserApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: credentials => ({
                url: '/user/login',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        logout:builder.mutation({
            query:(credentials) => ({
                url:'user/logout',
                method:'POST',
                body: { ...credentials },  
            })
        }),
        signup: builder.mutation({
            query: credentials => ({
                url: '/user/register',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        social: builder.mutation({
            query: credentials => ({
                url: '/user/social-login',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        otpverify: builder.mutation({
            query: credentials => ({
                url: '/user/verify-otp',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        resendotp: builder.mutation({
            query: credentials => ({
                url: '/user/resend-otp',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        forgotpassword: builder.mutation({
            query: credentials => ({
                url: '/user/forgot-password',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        resetpassword: builder.mutation({
            query: credentials => ({
                url: '/user/reset-password',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        getprofile:builder.query({
            query: (id) => ({
                url: `/user/profile/${id}`,
                providesTags: ['User',id],
            }),
        }),
        updateprofile:builder.mutation({
            query:({id, credentials}) => ({
                url: `/user/profile/${id}`,
                method:'PUT',
                body: credentials,  
            }),
        }),
        changepassword: builder.mutation({
            query: credentials => ({
                url: '/user/change-password',
                method: 'POST',
                body: { ...credentials }
            })
        }),
    })
})

export const {
    useLoginMutation,
    useLogoutMutation,
    useSignupMutation,
    useOtpverifyMutation,
    useResendotpMutation,
    useForgotpasswordMutation,
    useResetpasswordMutation,
    useGetprofileQuery,
    useUpdateprofileMutation,
    useChangepasswordMutation,
    useSocialMutation
} = UserApiSlice