import React from 'react'
import { Assets } from '../../constant/images'

const CheckoutProducts = (props) => {
  return (
    <>
    <div className="checkout-component d-flex align-items-center">
        <div className='img-container '>
            <img src={props.cartproductimg} />
        </div>
        <div>
            <div className='product-checkout-title'>{props.checkouttitle}</div>
            <div className='product-checkout-price'>{props.checkoutprice}</div>
            <div><span className="checkout-price-and-quantity">{props.qtyprice}</span><span className="checkout-price-and-quantity ps-2">QTY: {props.qty}</span></div>
        </div>
    </div>
    <div className='mainhr'><hr/></div>
    </>
  )
}

export default CheckoutProducts