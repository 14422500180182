import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/all.css";
import "./components/navigation/Navigation.jsx"
import Navigation from './components/navigation/Navigation.jsx';
import {
    BrowserRouter,
} from "react-router-dom";
import {ToastContainer, Zoom} from "react-toastify";
import {React} from "react";

function App() {
    return (
        <div className="App">
            <ToastContainer
                hideProgressBar={true}
                autoClose={3000}
                transition={Zoom}
            />
            {/*<ToastContainer position="bottom-right"*/}
            {/*autoClose={3000}*/}
            {/*hideProgressBar={true}*/}
            {/*newestOnTop={false}*/}
            {/*closeOnClick*/}
            {/*rtl={false}*/}
            {/*pauseOnFocusLoss*/}
            {/*draggable*/}
            {/*transition={Zoom}*/}
            {/*pauseOnHover*/}
            {/*theme="colored"*/}
            {/*/>*/}
            <BrowserRouter>
                <Navigation/>
            </BrowserRouter>
        </div>
    );
}

export default App;
