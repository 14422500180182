import { createSlice } from "@reduxjs/toolkit";
import ReportAction from "../../actions/ReportAction";


const initialState={
    Report:[],
}

export const ReportSlice = createSlice({
    name:'Report',
    initialState,
    reducers:ReportAction,
})

export const ReportActionCalls = ReportSlice.actions;

export default ReportSlice.reducer; 

export const selectCurrentReport = (state)=>state.Report.Report;