import React from "react";
import { Col, Row } from "react-bootstrap";

const OrderSummary = () => {
  return (
    <div className="order-summary-page">
      <div className="order-no">
        Order ID <span>#RW5562</span>
      </div>
      <div className="review">
        <Row>
          <Col md={12}>
            <div className="profile-data">
              <div className="d-flex align-items-center mt_15">
                <div className="data-ques">Name</div>
                <div className="data-ans">John Doe</div>
              </div>
              <div className="d-flex align-items-center mt_15">
                <div className="data-ques">Address</div>
                <div className="data-ans">217 E Bando Street</div>
              </div>
              <div className="d-flex align-items-center mt_15">
                <div className="data-ques">City</div>
                <div className="data-ans">New York</div>
              </div>
              <div className="d-flex align-items-center mt_15">
                <div className="data-ques">Country:</div>
                <div className="data-ans">United States</div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OrderSummary;
