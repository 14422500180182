import { React, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import moment from "moment";
import {Chart, Filler} from 'chart.js';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import faker from "faker";

const LineGraph = () => {
    Chart.register(Filler);

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const range = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
    "Last Year": [
      moment().subtract(1, "year").startOf("year"),
      moment().subtract(1, "year").endOf("year"),
    ],
  };

  const handleEvent = (event, picker) => {
    setFromDate(picker.startDate._d.toISOString());
    setToDate(picker.endDate._d.toISOString());
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display:false,
        position: "top",
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };
  const labels = ["01", "02", "03", "04", "05", "06", "07"];

  const data = {
    labels,
    datasets: [
      {
        label: "Active",
        // data: labels.map(() =>
        //   faker.datatype.number({ min: -1000, max: 1000 })
        // ),
        borderColor: "rgba(219, 187, 81, 1)",
        backgroundColor: "rgba(219, 187, 81, 0.5)",
        lineTension: 0.3,
      },

    ],
  };

  const data2 = {
    labels: ["Jan", "Feb", "Mar",  "Apr", "May", "Jun", "Jul", "Aug","Sep","Oct","Nov","Dec"],
    datasets: [
      {
        label: "Income",
        data: [5, 10,13,14,14.5,15,16,18,19,20,21,18,19],
        fill: true,
        backgroundColor: "rgba(219, 187, 81, 0.1)",
        borderColor: "rgba(219, 187, 81, 1)",
        lineTension: 0,

      },

    ],
    labels: ["Jan", "Feb", "Mar",  "Apr", "May", "Jun", "Jul", "Aug","Sep","Oct","Nov","Dec"],

  };

  return (
    <div className="LineGraph">
      <Line options={options} data={data2} />
    </div>
  );
};

export default LineGraph;
