import React, { useState } from "react";
import { Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { PasswordEye } from "../../constant/svg";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import {
  SPECIAL_CHARACTER_ARRAY,
  SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
  VALIDATIONS,
  VALIDATIONS_TEXT,
  EXCLUDE_NUMBER_INPUT,
  EXCLUDE_ALPHABETS_SPECIAL_CHAR,
  SPECIAL_CHARACTER_NUMBER_ARRAY,
} from "../../constant/app-constants";
import { OpenIcon, CloseIcon } from "../../utils/svg";
import { Assets } from "../../constant/images";
import { ToastContainer, toast } from 'react-toastify';


const DeleteConfirmation = () => {

  const DeleteToast = () =>{
    return(
      <div className=" toast-notif d-flex align-items-center">
        <div><img src={Assets.ToastCheck}/></div>
        <div className="pl_15">
          <div className="main-toast-heading"> Account Deleted!</div>
          <div className="toast-subheading">Your account has been deleted successfully.</div>
        </div>
      </div>
    )
  }


  const [value, setValue] = useState();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const password = watch("password");

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const notify = () => toast(<DeleteToast/>);


  const navigate = useNavigate();
  const onSubmit = async (data) => {
      notify()

      setTimeout(()=>{
        navigate("/")}, 1500)
      }
    


  return (
<>
<div className="change-password-page signup-form">
      <Row>
        <Col md={6}>
          <h3>
          For security reasons, we need you to kindly confirm your password 
before we proceed to delete your account.
          </h3>
          <Row>
            <Col md={10}>
              <Form onSubmit={handleSubmit(onSubmit)}>
              <Col md={12}>
              <FloatingLabel controlId="floatingInput" label="New Password">
                <Form.Control
                  type={!showNewPassword ? "password" : "text"}
                  placeholder="Enter your new password"
                  maxLength={VALIDATIONS_TEXT.PASSWORD_MAX}
                  {...register("password", {
                    minLength: {
                      value: VALIDATIONS.PASSWORD_MIN,
                      message: VALIDATIONS_TEXT.PASSWORD_MIN,
                    },
                    maxLength: {
                      value: VALIDATIONS.PASSWORD_MAX,
                      message: VALIDATIONS_TEXT.PASSWORD_MAX,
                    },
                    required: {
                      value: true,
                      message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                    },
                    pattern: {
                      value: /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/,
                      message: VALIDATIONS_TEXT.PASSWORD_FORMAT,
                    },
                  })}
                />
                {errors.password && (
                  <small className="text-red error_from error_from">
                    {errors.password.message}
                  </small>
                )}

                <span className="iconfeildauth">
                  {showNewPassword ? (
                    <span
                      className="toggle-password"
                      onClick={() => setShowNewPassword(false)}
                    >
                      <OpenIcon />
                    </span>
                  ) : (
                    <span
                      onClick={() => setShowNewPassword(true)}
                      className="toggle-password"
                    >
                      <CloseIcon />
                    </span>
                  )}
                </span>
              </FloatingLabel>
            </Col>

            <Col md={12}>
              <FloatingLabel controlId="floatingInput" label="Confirm Password">
                <Form.Control
                  type={!showConfirmPassword ? "password" : "text"}
                  placeholder="Confirm new password"
                  maxLength={VALIDATIONS_TEXT.PASSWORD_MAX}
                  {...register("password_confirmation", {
                    minLength: {
                      value: VALIDATIONS.PASSWORD_MIN,
                      message: VALIDATIONS_TEXT.PASSWORD_MIN,
                    },
                    maxLength: {
                      value: VALIDATIONS.PASSWORD_MAX,
                      message: VALIDATIONS_TEXT.PASSWORD_MAX,
                    },
                    required: {
                      value: true,
                      message: VALIDATIONS_TEXT.CONFIRM_PASSWORD_REQUIRED,
                    },
                    validate: (value) =>
                      value === password ||
                      "Password and Confirm Password must be same",
                  })}
                />
                {errors.password_confirmation && (
                  <small className="text-red error_from">
                    {errors.password_confirmation.message}
                  </small>
                )}

                <span className="iconfeildauth">
                  {showConfirmPassword ? (
                    <span
                      className="toggle-password"
                      onClick={() => setShowConfirmPassword(false)}
                    >
                      <OpenIcon />
                    </span>
                  ) : (
                    <span
                      onClick={() => setShowConfirmPassword(true)}
                      className="toggle-password"
                    >
                      <CloseIcon />
                    </span>
                  )}
                </span>
              </FloatingLabel>
            </Col>

                <div className="d-flex justify-content-end">
                  <Button type="submit" className="reset-pass-btn">Delete Account</Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
    <ToastContainer               hideProgressBar={true}
/>
    </>
  );
};

export default DeleteConfirmation;
