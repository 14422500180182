import React from "react";
import { FloatingLabel , Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { NavLink } from "react-router-dom";
import { Assets } from "../../constant/images";
import { CrossIcon, ProfileRightArrow } from "../../constant/svg";

function QuantityModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="delete-modal"
    >
      <div className="main-heading">Update Quantity</div>
      <div className="signup-form ">
        <FloatingLabel controlId="floatingInput" label="Quantity">
          <Form.Control className="mb-0 mt_24" type="text" placeholder="name@example.com" />
        </FloatingLabel>
      </div>
      <div className="mt_24 d-flex justify-content-end">
        <Button onClick={props.onHide} className="sub-btn">
          Cancel
        </Button>
        <Button onClick={props.onHide} className="main-btn">
          Update
        </Button>
      </div>
    </Modal>
  );
}

export default QuantityModal;
