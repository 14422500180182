import React, { useEffect, useState } from "react";
import { Assets } from "../../constant/images";
import {NavLink} from "react-router-dom";
import { useGetprofileQuery } from "../../redux/reducers/UserSlice/UserApiSlice";
import { selectCurrentUser } from "../../redux/reducers/UserSlice/UserSlice";
import {TailSpin} from "react-loader-spinner";
import { useSelector } from "react-redux";

const BuyerAccountDetails = () => {

  const user = useSelector(selectCurrentUser)
  
  const { data: profile,isLoading,refetch} = useGetprofileQuery(user._id);
  useEffect(()=>{
    refetch();
    
  },[user])

  if (isLoading) return <div className="loader">
  <TailSpin
      height="30"
      width="30"
      color="#dbbb51"
      ariaLabel="tail-spin-loading"
      radius="1"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
  />
</div>
  if (!profile) return <div className="loader">
  <TailSpin
      height="30"
      width="30"
      color="#dbbb51"
      ariaLabel="tail-spin-loading"
      radius="1"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
  />
</div>

  const {data} = profile;

  // console.log(profile);

  return (
    <>
      <div className="main-buyer-account-details">
        <div className="top-heading-edit-div">
          <div className="heading-div">
            <h4>Account Details</h4>
          </div>
          <div className="edit-div">
            <NavLink to="/account-details/buyer-account-details-edit" state={{profile:profile}} >Edit</NavLink>
          </div>
        </div>

        <div className="buyer-account-content">
          <div className="buyer-avatar">
            <img className="img-fluid" src={Assets.BuyerAvatar} />
          </div>
          <div className="buyer-name">
            <h2>{data.first_name} {data.last_name}</h2>
          </div>
          <div className="buyer-info">
            <ul>
              <li>
                <span>Email Address:</span> <span>{data.email}</span>
              </li>
              <li>
                <span>Phone Number:</span> <span>{data.phone}</span>
              </li>
              <li>
                <span>Country:</span> <span>{data.country?.name}</span>
              </li>
              <li>
                <span>Address:</span> <span>{data.address}</span>
              </li>
              <li>
                <span>City:</span> <span>{data.city?.name}</span>
              </li>
              <li>
                <span>State:</span> <span>{data.state?.name}</span>
              </li>
              <li>
                <span>Zipcode:</span> <span>{data.zip_code}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyerAccountDetails;
