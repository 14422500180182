import { createSlice } from "@reduxjs/toolkit";
import ProductAction from "../../actions/ProductAction";

const initialState={
    Product:[],
}

export const ProductSlice = createSlice({
    name:'Product',
    initialState,
    reducers:ProductAction,
})

export const ProductActionCalls = ProductSlice.actions;

export default ProductSlice.reducer; 

export const selectProduct = (state)=>state.Product.Product;