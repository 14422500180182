import React from "react";
import { ArcElement } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import Chart from "chart.js/auto";


const DonutChart = () => {

  const plugins = [{
    beforeDraw: function(chart) {
     var width = chart.width,
         height = chart.height,
         ctx = chart.ctx;
         ctx.restore();
         var fontSize = (height / 170).toFixed(2);
         ctx.font = fontSize + "em sans-serif";
         ctx.textBaseline = "top";
         var text = "250 Orders",
         textX = Math.round((width - ctx.measureText(text).width) / 2),
         textY = height / 2.3;
         ctx.fillText(text, textX, textY);
         ctx.save();
    } 
  }]

  const options = {
    responsive: true,
    cutout: 60,
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
        
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
  };
  const data = {
    labels: ["Completed", "New"],
    datasets: [
      {
        data: [250,100],
        backgroundColor: ["rgba(219, 187, 81, 1)","rgba(219, 187, 81, 0.1)"],
        hoverBackgroundColor: ["rgba(219, 187, 81, 0.7)","rgba(219, 187, 81, 0.7)"],
        borderWidth: 0
      }
    ],
    
  };
  return (
    <div className="donutchart-container">
    <Doughnut options={options} data={data} plugins={plugins} />
    </div>
    )
}

export default DonutChart