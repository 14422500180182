import {React,useState,useEffect} from "react";
import { Row, Col, Container, Button, InputGroup, DropdownButton } from "react-bootstrap";
import DonutChart from "../../components/DonutChart/DonutChart";
import LineGraph from "../../components/LineGraph/LineGraph";
import DashboardSidebar from "../../components/Sidebar/DashboardSidebar";
import TopBar from "../../components/TopBar/TopBar";
import { Dropdown } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import { Form } from "react-bootstrap";
import { Assets } from "../../constant/images";
import Dispatched from "../../components/ProjectStatus/Dispatched";
import InProcess from "../../components/ProjectStatus/InProcess";
import Completed from "../../components/ProjectStatus/Completed";
import {
  AddProductBtn,
  ArrowRight,
  BackPage,
  HiddenPagesNo,
  NextestPage,
  NextPage,
  SearchIconTopBar,
  StarProductRating,
  UserIcon,
} from "../../constant/svg";
import { NavLink } from "react-router-dom";
import InStock from "../../components/ProjectStatus/InStock";
import OutStock from "../../components/ProjectStatus/OutStock";
import DeleteModal from "../../components/Modals/DeleteModal";
import EditProductModal from "../../components/Modals/EditProductModal";
import Select from "react-select";
import { useAllproductQuery } from "../../redux/reducers/ProductSlice/ProductApiSlice";


const ProductManagement = () => {

  const colourStyles = {
    control: (styles, state) => ({
      ...styles,
      backgroundColor: "white",
      borderRadius: "20px",
      textAlign: "left",
      paddingLeft: "10px",
      color: "red",
      fontSize: "14px",
      fontColor: "red",
      boxShadow: "none",
      fontFamily: "Ageo",
      fontSize: "14px",
      color: state.isSelected ? "red" : "yellow",
      border: state.isFocused ? "1px solid #dbbb51" : "1px solid #ccc",
      "&:hover": {
        borderColor: state.isFocused ? "#dbbb51" : "#dbbb51",
      },
    }),
    placeholder: (defaultStyles) => {
      return {
          ...defaultStyles,
          color: '#707581',
          paddingLeft:"0",
      }
  },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        cursor: "pointer",
        backgroundColor: isDisabled ? "red" : "white",
        fontFamily: "Ageo",
        fontSize: "14px",
        color: "#707581",
        fontSize: "14px",
        boxShadow: "none",
        textAlign: "left",
        ":hover": {
          backgroundColor: "#F4F4F4",
          color: "#dbbb51",
        },
      };
    },
  };


  const [deletemodalShow, setDeleteModalShow] = useState(false);
  const [editproductmodalshow, setEditProductModalShow] = useState(false);
  const [Addproductmodalshow, setAddProductModalShow] = useState(false);
  const { data: products, isLoading: ProductLoading } = useAllproductQuery();
    const[allProduct,setAllProduct] = useState([]);

    const getAllProducts= async()=>{
      try{
        setAllProduct(products?.data || []);

      }catch(e){
          console.log(e);
      }
  }

  useEffect(()=>{
    getAllProducts();

  },[ProductLoading])

 

  const columns = [
    {
      field: "UserId",
      headerName: "Product Title",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <NavLink to="/products/product-details">
            <div className="d-flex align-items-center mt-2">
              <img style={{maxWidth:'40px',height:'40px'}} className="img-fluid" src={params.row.image} />
              <div className=" product-table-data">
                <div className="product-name">{params.row.productname}</div>
                <div className="product-no">{params.row.productno}</div>
              </div>
            </div>
          </NavLink>
        );
      },
      sortable: false
    },
    {
      field: "price",
      headerName: "Price",
      width: 180,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <NavLink to="/products/product-details">
            <div className="start-date">
              ${params.row.price}
            </div>
          </NavLink>
        );
      },
    },
    {
      field: "discountprice",
      headerName: "Discounted Price",
      width: 180,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <NavLink to="/products/product-details">
            <div className="start-date">
              ${params.row.discountprice}
            </div>
          </NavLink>
        );
      },
    },
    {
      field: "rating",
      headerName: "Rating",
      width: 180,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <NavLink to="/products/product-details">
          <div className="d-flex align-items-center mt-2 product-management-rating">
            <div>
              <StarProductRating />
            </div>
            <div className="productstarrating">4.0</div>
            <div className="productstarsubrating">(28)</div>
          </div>
          </NavLink>
        );
      },
    },
    {
      field: "status",
      headerName: "Product Status",
      width: 180,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return( 
          <NavLink to="/products/product-details">
        <div>{params.row.status}</div>
        </NavLink>
        )
      },
    },
    {
      headerName: "Action",
      width: 120,
      id: "action",
      name: "Action",
      renderCell: (cellValues) => {
        return (
          <div className="user-button">
            <DropdownButton id="dropdown-basic-button" title={<UserIcon />}>
              <Dropdown.Item
                // onClick={() => setShowEditNew(true)}
                onClick={() => {
                  setEditProductModalShow(true);
                }}
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                // onClick={() => setShowDelete(true)}
                onClick={() => {
                  setDeleteModalShow(true);
                }}
              >
                Delete
              </Dropdown.Item>
            </DropdownButton>
          </div>
        );
      },
    },
  ];

  const rows = [];

  if(allProduct.length !== 0){
    const {data} = allProduct;
    data.forEach((item) => {
      // add category row
      rows.push({
        id: item._id,
        image: item.attachments[0]?.path,
        productname: item.title,
        productno: item._id.slice(0,15),
        price: item.price,
        discountprice: item.discounted_price,
        status: item.quantity > 0 ? <InStock /> : <OutStock/>,
        price: item.price,
      });
    })

  }
  

  return (
    <>
      <div className="main-title-heading">Product Management</div>
      <div className="order-table-container">
        <div className="d-block d-md-flex justify-content-between">
          <div className="table-search-box">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <NavLink to="/search">
                  <SearchIconTopBar />
                </NavLink>
              </InputGroup.Text>
              <Form.Control
                placeholder="Search Products"
                aria-label="Search"
                aria-describedby="basic-addon1"
              />
            </InputGroup>
          </div>
          <div className="d-block d-sm-flex">
          <div className="order-dropdown">
              <Select
                className=" dropdown-custom-btn normal w-100"
                classNamePrefix="afrorierre"
                isMenuOpen={true}
                options={[
                  { value: "1", label: "Parent" },
                  { value: "2", label: "Sub-Parent" },
                  { value: "3", label: "Sub-Parent" },
                  { value: "4", label: "Parent" },
                  { value: "5", label: "Sub-Parent" },
                ]}
                placeholder="All"
                name="reactSelectName"
                // menuIsOpen={true}
                components={{
                  IndicatorSeparator: () => null,
                }}
                styles={colourStyles}
              />
            </div>

            <div className="text-left text-sm-center m-0 ms-sm-2"><NavLink to="/products/list"><Button onClick={() => setAddProductModalShow(true)} className="add-product-btn mt-2 mt-sm-0"><span><AddProductBtn/></span> Add Product</Button></NavLink></div>
          </div>
        </div>
        <div style={{ height: 560, width: "100%" }}>
          <DataGrid
            className="datagrid-table"
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[7]}
            checkboxSelection
          />
        </div>
        <div className="pagination-border d-flex justify-content-end">
          <div className="seller-paginations d-flex">
            <div className="page-box"><BackPage/></div>
            <div className="page-box active">1</div>
            <div className="page-box"><HiddenPagesNo/></div>
            <div className="page-box"><NextPage/></div>
            <div className="page-box"><NextestPage/></div>
          </div>
        </div>
      </div>

      <DeleteModal
        heading="Delete Product"
        type="delete this product"
        btntext="Delete"
        link="/products/product-details"
        display="onClick={()=>{props.onHide}}"
        show={deletemodalShow}
        onHide={() => setDeleteModalShow(false)}
      />
      <EditProductModal
      title="Edit Product"
        heading="delete"
        type="frfrfrfrfr"
        show={editproductmodalshow}
        onHide={() => setEditProductModalShow(false)}
      />
            <EditProductModal
      title="Add Product"
        heading="delete"
        type="frfrfrfrfr"
        show={Addproductmodalshow}
        onHide={() => setAddProductModalShow(false)}
      />
    </>
  );
};

export default ProductManagement;
