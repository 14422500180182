import React from "react";
import { NavLink } from "react-router-dom";
import { BackArrow, ChangePassword, ContactUs, DashboardIcon, DeleteAccount, PrivactPolicy, SettingsNotification, TermsandCondition } from "../../constant/svg";
import { Row, Col } from "react-bootstrap";

const Settings = (props) => {
  return (
    <>
      <div className="d-flex align-items-center">
        <NavLink to="/dashboard">
          <BackArrow />
        </NavLink>
        <div className="main-subheading">Settings</div>
      </div>
      <Row>
        <Col md={3}>
          <div className="settings-sidebar">
            <ul>
              <li>
                <NavLink to="/settings/notifications">
                  <div className="nav-bg">
                    <span>
                      <SettingsNotification />
                    </span>
                    <span className="ps-3">Push Notification</span>
                  </div>
                </NavLink>
                <div>
                    <hr/>
                </div>
              </li>
              <li>
                <NavLink to="/settings/change-password">
                  <div className="nav-bg">
                    <span>
                      <ChangePassword />
                    </span>
                    <span className="ps-3">Change Password</span>
                  </div>
                </NavLink>
                <div>
                    <hr/>
                </div>
              </li>
              <li>
                <NavLink to="/settings/terms-and-conditions">
                  <div className="nav-bg">
                    <span>
                      <TermsandCondition />
                    </span>
                    <span className="ps-3">Terms & Conditions</span>
                  </div>
                </NavLink>
                <div>
                    <hr/>
                </div>
              </li>
              <li>
                <NavLink to="/settings/privacy-policy">
                  <div className="nav-bg">
                    <span>
                      <PrivactPolicy />
                    </span>
                    <span className="ps-3">Privacy Policy</span>
                  </div>
                </NavLink>
                <div>
                    <hr/>
                </div>
              </li>
              <li>
                <NavLink to="/settings/contact-us">
                  <div className="nav-bg">
                    <span>
                      <ContactUs />
                    </span>
                    <span className="ps-3">Contact Us</span>
                  </div>
                </NavLink>
                <div>
                    <hr/>
                </div>
              </li>
              <li>
                <NavLink to="/settings/delete-account">
                  <div className="nav-bg">
                    <span>
                      <DeleteAccount />
                    </span>
                    <span className="ps-3">Delete Account</span>
                  </div>
                </NavLink>
              </li>
            </ul>
          </div>
        </Col>
        <Col md={9}>
          <div className="settings-main-section">
            <div className="settings-heading">{props.heading}</div>
            {props.children}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Settings;
