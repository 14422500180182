import React from "react";
import { Assets } from "../../constant/images";
import { NavLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Col, Container, Nav, Navbar, Row, Form, Button } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Footer from "../Footer/Footer";
import NavbarHeader from "../Navbar/Navbar";
import {
  VALIDATIONS,
  VALIDATIONS_TEXT,
  EXCLUDE_NUMBER_INPUT,
} from "../../constant/app-constants";
const ReportCenterForm = () => {
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
      } = useForm({
        mode: "onChange",
      });
      const navigate = useNavigate();
      const onSubmit = (data) => {
        navigate("/buyer-product-details");
      };
  return (
    <>
      <NavbarHeader />
      <section className="main-report-center main-section">
        <Container>
          <Row>
            <Col xs={12}>
              <h3>Report Center</h3>
              <h4>Complete the details below</h4>
            </Col>
            <Col xs={5}>
              <div className="main-report-type-div">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col md={12}>
                      <FloatingLabel controlId="floatingInput" label="Report Type">
                        <Form.Control
                          placeholder="Report Type"
                          type="text"
                          onKeyDown={(e) =>
                            EXCLUDE_NUMBER_INPUT.includes(e.key) &&
                            e.preventDefault()
                          }
                          maxLength={VALIDATIONS.NAME}
                          {...register("report_type", {
                            maxLength: {
                              value: VALIDATIONS.REPORT_MAX,
                              message: VALIDATIONS_TEXT.REPORT_TYPE_VALUE,
                            },
                            pattern: {
                              value: /^[a-zA-Z][a-zA-Z\s]*$/,
                              message:
                                VALIDATIONS_TEXT.REPORT_TYPE_VALID,
                            },
                            required: {
                              value: true,
                              message: VALIDATIONS_TEXT.REPORT_TYPE_REQUIRED,
                            },
                          })}
                        />
                        {errors.report_type && (
                          <small className="text-red">
                            {errors.report_type.message}
                          </small>
                        )}
                      </FloatingLabel>
                    </Col>
                    <Col md={12}>
                      <FloatingLabel controlId="floatingInput" label="Product URL">
                        <Form.Control
                          placeholder="Product URL"
                          type="url"
                        //   onKeyDown={(e) =>
                        //     EXCLUDE_NUMBER_INPUT.includes(e.key) &&
                        //     e.preventDefault()
                        //   }
                          maxLength={VALIDATIONS.URL}
                          {...register("url", {
                            maxLength: {
                              value: VALIDATIONS.URL_MAX,
                              message: VALIDATIONS_TEXT.URL_MAX_VALUE,
                            },
                            pattern: {
                              value: /^(http(s)?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/,
                              message:
                                VALIDATIONS_TEXT.VALID_URL_TEXT,
                            },
                            required: {
                              value: true,
                              message: VALIDATIONS_TEXT.URL_REQUIRED,
                            },
                          })}
                        />
                        {errors.url && (
                          <small className="text-red">
                            {errors.url.message}
                          </small>
                        )}
                      </FloatingLabel>
                    </Col>
                    <Col md={12}>
                      <FloatingLabel controlId="floatingInput" label="Reason">
                        <Form.Control
                          placeholder="Reason"
                          type="text"
                          onKeyDown={(e) =>
                            EXCLUDE_NUMBER_INPUT.includes(e.key) &&
                            e.preventDefault()
                          }
                          maxLength={VALIDATIONS.REPORT_REASON_MAX}
                          {...register("report_reason", {
                            maxLength: {
                              value: VALIDATIONS.REPORT_REASON_MAX,
                              message: VALIDATIONS_TEXT.REPORT_REASON_TEXT,
                            },
                            // pattern: {
                            //   value: /^[a-zA-Z\s]*$/,
                            //   message:
                            //     VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS,
                            // },
                            required: {
                              value: true,
                              message: VALIDATIONS_TEXT.REPORT_REASON_REQUIRED,
                            },
                          })}
                        />
                        {errors.report_reason && (
                          <small className="text-red">
                            {errors.report_reason.message}
                          </small>
                        )}
                      </FloatingLabel>
                    </Col>
                  </Row>

                  <div className="button-div">
                    <Button type="submit">Submit</Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default ReportCenterForm;
