import React, { useState } from "react";
import { Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { NavLink } from "react-router-dom";
import { PasswordEye } from "../../constant/svg";
import { useForm } from "react-hook-form";
import Select from "react-select";



const DeleteAccount = () => {
  const colourStyles = {
    control: (styles, state) => ({
      ...styles,
      backgroundColor: "white",
      borderRadius: "20px",
      textAlign: "left",
      boxShadow: "none",
      fontFamily: "Ageo",
      fontSize: "14px",
      border: state.isFocused ? '1px solid #dbbb51' : '1px solid #ccc',
      "&:hover": {
        borderColor: state.isFocused ? "#dbbb51" : "#dbbb51",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        cursor: "pointer",
        backgroundColor: isDisabled ? "red" : "white",
        fontFamily: "Ageo",
        fontSize: "14px",
        color: "#989898",
        boxShadow: "none",
        textAlign: "left",
        ":hover": {
          backgroundColor: "#F4F4F4",
          color: "#dbbb51",
        },
      };
    },
  };

  return (
    <div className="delete-account">
      <Row>
        <Col md={6}>
          <div className="reason-for-deleting">
            Reason for deleting the account
          </div>
          <div className="delete-form">
            <Form>
              {/* <Form.Select aria-label="Default select example">
                <option value="1">App doesn’t work properly</option>
                <option value="2">I have some privacy concerns</option>
                <option value="3">I’m not getting any orders</option>
                <option>Other</option>
              </Form.Select> */}
                          <div className="delete-acc-dropdown">
              <Select
                className=" dropdown-custom-btn normal w-100"
                classNamePrefix="afrorierre"
                isMenuOpen={true}
                options={[
                  { value: "1", label: "App doesn’t work properly" },
                  { value: "2", label: "I have some privacy concerns" },
                  { value: "3", label: "I’m not getting any orders" },
                  { value: "4", label: "Other" },
                ]}
                placeholder="App doesn’t work properly"
                name="reactSelectName"
                // menuIsOpen={true}
                components={{
                  IndicatorSeparator: () => null,
                }}
                styles={colourStyles}
              />
            </div>

              <Form.Group className="" controlId="exampleForm.ControlTextarea1">
                <Form.Control
                  placeholder="Write your reason here."
                  as="textarea"
                  rows={3}
                />
              </Form.Group>

              <div className="d-flex justify-content-end">
                <NavLink to="/settings/delete-account/confirm-password"><Button className="submit-btn">Submit</Button></NavLink>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DeleteAccount;
